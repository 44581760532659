import AldiButton, { AldiButtonProps } from "../button/AldiButton";
import "./AldiReloadButton.scss";

export default function AldiReloadButton(props: AldiButtonProps) {
    return (<AldiButton className="aldi-reload-button" {...props}>
        <span className="ri-restart-line"></span>
        <span>{"Neu Laden"}</span>
        {props.children}
    </AldiButton>)
}
