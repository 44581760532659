import { styled } from "@mui/material";
import { selectUnstyledClasses } from '@mui/base/SelectUnstyled';
import PopperUnstyled from '@mui/base/PopperUnstyled';

const blue = {
  100: '#DAECFF',
  200: '#99CCF3',
  400: '#3399FF',
  500: '#007FFF',
  600: '#0072E5',
  900: '#003A75',
};

const grey = {
  50: '#F3F6F9',
  100: '#E7EBF0',
  200: '#E0E3E7',
  300: '#CDD2D7',
  400: '#B2BAC2',
  500: '#A0AAB4',
  600: '#6F7E8C',
  700: '#3E5060',
  800: '#2D3843',
  900: '#1A2027',
};

const StyledButton = styled('button')(
    ({ theme }) => `
  position: relative;
  font-size: 0.75rem;
  box-sizing: border-box;
  min-width: 10rem;
  height: 40px;
  background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
  border: none;
  border-bottom: 2px solid #EAEFF3;
  padding: 0 1rem;
  text-align: left;
  width: 100%;
  line-height: 1.5;
  color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
  cursor: pointer;

  &:hover {
    background: ${theme.palette.mode === 'dark' ? '' : grey[50]};
    border-color: ${theme.palette.mode === 'dark' ? grey[700] : grey[400]};
  }

  &.${selectUnstyledClasses.focusVisible} {
    outline: 3px solid ${theme.palette.mode === 'dark' ? blue[600] : blue[100]};
  }

  &.${selectUnstyledClasses.expanded} {
    &::after {
      content: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8 5L12.7631 11.75H3.23686L8 5Z' fill='%233E434A'/%3E%3C/svg%3E");
    }
  }

  &::after {
    content: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8 11L3.23686 4.25L12.7631 4.25L8 11Z' fill='%233E434A'/%3E%3C/svg%3E");
    position: absolute;
    right: 12px;
    top: 12px;
    margin-left: 8px;
  }
  `,
);

const StyledListbox = styled('ul')(
  ({ theme }) => `
  font-size: 0.875rem;
  box-sizing: border-box;
  padding: 0;
  min-width: 16rem;
  max-height: 32rem;
  margin: 0;
  border-radius: 0;
  color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
  overflow: auto;
  outline: 0px;
  z-index: 100;
  border-color: ${theme.palette.mode === 'dark' ? grey[700] : grey[400]};
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.17);
  background: #fff;
  `,
);

const StyledPopper = styled(PopperUnstyled)`
  z-index: 1000;
`;

export { StyledButton, StyledListbox, StyledPopper };