import './SidebarLayout.scss';
import { Link, useNavigate, useLocation } from "react-router-dom";
import { ReactComponent as IconClickAndCollect } from '../../../assets/images/custom-icons/click-and-collect.svg';
import { ReactComponent as IconHomeDelivery } from '../../../assets/images/custom-icons/home-delivery.svg';
import { ReactComponent as IconStorage } from '../../../assets/images/custom-icons/storage.svg';
import { ReactComponent as IconConfig } from '../../../assets/images/custom-icons/config.svg';
import { ReactComponent as IconFulfillment } from '../../../assets/images/custom-icons/fulfillment.svg';
import { ReactComponent as Logo } from './logo.svg';


const SidebarLayout = (props: {
    user?: any;
    onChange?: (value: string) => void;
}) => {

    const navigate = useNavigate();
    const location = useLocation();

    return (
        <div className="sidebar">
            <div className="logo-box" onClick={() => navigate('/dashboard')}>

                <Logo className="logo" />

            </div>

            <div className="sidebar-group">
                <div className="sidebar-group-header"><IconFulfillment className="icon" />
                    {"Fulfillment"}
                </div>
                <a className={`sidebar-item ${location.pathname === '/fulfillment/activities' && 'selected-item'}`} onClick={() => navigate('/fulfillment/activities')}>
                    {"Tages-Übersicht"}
                </a>
                <a className={`sidebar-item ${location.pathname === '/fulfillment/cluster-overview' && 'selected-item'}`} onClick={() => navigate('/fulfillment/cluster-overview')}>
                    {"Cluster-Übersicht"}
                </a>
                <a className={`sidebar-item ${location.pathname === '/fulfillment/tasks' && 'selected-item'}`} onClick={() => navigate('/fulfillment/tasks')}>
                    {"Aufgaben-Übersicht"}
                </a>
                <a className={`sidebar-item ${location.pathname === '/fulfillment/orders' && 'selected-item'}`} onClick={() => navigate('/fulfillment/orders')}>
                    {"Kundenbestellungen"}
                </a>
            </div>

            <div className="sidebar-group">
                <div className="sidebar-group-header"><IconClickAndCollect className="icon" />
                    {"Click & Collect"}
                </div>
                <a className={`sidebar-item ${location.pathname === '/click-and-collect/cleverons' && 'selected-item'}`} onClick={() => navigate('/click-and-collect/cleverons')}>
                    {"Cleveron-Übersicht"}
                </a>
                <a className={`sidebar-item ${location.pathname === '/click-and-collect/timetable-cleverons' && 'selected-item'}`} onClick={() => navigate('/click-and-collect/timetable-cleverons')}>
                    {"Abholzeitfenster"}
                </a>
            </div>

            <div className="sidebar-group">
                <div className="sidebar-group-header"><IconHomeDelivery className="icon" />
                    {"Home Delivery"}
                </div>
                <a className={`sidebar-item ${location.pathname === '/home-delivery/tour-planning' && 'selected-item'}`} onClick={() => navigate('/home-delivery/tour-planning')}>
                    {"Touren-Übersicht"}
                </a>
                <a className={`sidebar-item ${location.pathname === '/home-delivery/timetable-home-delivery' && 'selected-item'}`} onClick={() => navigate('/home-delivery/timetable-home-delivery')}>
                    {"Auslieferzeitfenster"}
                </a>
            </div>

            <div className="sidebar-group">
                <div className="sidebar-group-header"><IconStorage className="icon" />
                    {"Lagerplätze"}
                </div>
                <a className={`sidebar-item ${location.pathname === '/storage-places/locations' && 'selected-item'}`} onClick={() => navigate('/storage-places/locations')}>
                    {"Übersicht"}
                </a>
            </div>

            <div className="sidebar-group configuration">
                <div className="sidebar-group-header"><IconConfig className="icon" />
                    {"Konfiguration"}
                </div>
                <a className={`sidebar-item ${location.pathname === '/configuration/replenishment' && 'selected-item'}`} onClick={() => navigate('/configuration/replenishment')}>
                    {"Aufstockung"}
                </a>
                <a className={`sidebar-item ${location.pathname === '/configuration/inventory-tasks' && 'selected-item'}`} onClick={() => navigate('/configuration/inventory-tasks')}>
                    {"Inventar-Aufgaben"}
                </a>
                <a className={`sidebar-item ${location.pathname === '/configuration/emergency-picking' && 'selected-item'}`} onClick={() => navigate('/configuration/emergency-picking')}>
                    {"Notfall-Picking"}
                </a>
            </div>

        </div>
    )
}

export default SidebarLayout;
