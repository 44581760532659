import './TourPlanningPage.scss';
import { Tour } from '@kehrwasser/aldi-sued-dtm-openapi';
import { useEffect, useState } from 'react';
import { toursProvider } from '../../../../shared/tours.service';
import CreateTourModal from './modal/CreateTourModal';
import AldiButton from '../../../elementsAldi/button/AldiButton';
import AldiGeneralModal from '../../../elementsAldi/generalmodal/AldiGeneralModal';
import AldiCheckbox from '../../../elementsAldi/checkbox/AldiCheckbox';
import { notify } from '../../../../shared/helpers';
import { useModal } from '../../../../hooks/useModal';
import usePromise, { PromiseStatus } from '../../../../hooks/usePromise';
import AldiReloadButton from '../../../elementsAldi/reloadbutton/AldiReloadButton';


export default function TourPlanningPage() {
    /* State Definitions */
    const [tours, setTours] = useState<Tour[]>([])
    const [allTours, setAllTours] = useState<Tour[]>([])
    const promiseAllTours = usePromise();
    const [count, setCount] = useState(0);
    const [hideInactiveTours, setHideInactiveTours] = useState<boolean>(false);

    const createTourModal = useModal();

    const [selectedTour, setSelectedTour] = useState<null | Tour>();
    const changeActiveModal = useModal();
    const promiseToggleActive = usePromise();

    /* Communication */
    useEffect(() => {
        loadTours();
    }, []);

    useEffect(() => {
        if (hideInactiveTours) {
            setTours(allTours.filter(tour => tour.active));
        } else {
            setTours(allTours);
        }
    }, [allTours, hideInactiveTours]);

    useEffect(() => {
        createTourModal.setModal(<CreateTourModal
            modal={createTourModal}
            allTours={allTours}
            setAllTours={setAllTours} />)
    }, [allTours])

    useEffect(() => {
        if (selectedTour) {
            const buttonText = selectedTour.active
                ? "Deaktivieren"
                : "Aktivieren";
            const text = selectedTour.active
                ? "Soll die Tour wirklich deaktiviert werden?"
                : "Soll die Tour wirklich aktiviert werden?";
            const submitKind = selectedTour.active ? "danger" : "primary";

            changeActiveModal.setModal(<AldiGeneralModal
                onCancel={() => {
                    changeActiveModal.hide();
                    setSelectedTour(null);
                }}
                textCancelButton="Abbrechen"
                onConfirm={() => {
                    promiseToggleActive.setPromise(toggleTourActivity(selectedTour)
                        .then(() => {
                            changeActiveModal.hide();
                            setSelectedTour(null);
                        })
                        .catch(error => {
                            notify("Es ist ein Fehler aufgetreten.");
                            console.debug(error);
                        }))
                }}
                confirming={promiseToggleActive.isLoading}
                disabled={promiseToggleActive.isLoading}
                submitKind={submitKind}
                textConfirmButton={buttonText}>
                {text}
            </AldiGeneralModal>);
        } else {
            changeActiveModal.setModal(null);
        }
    }, [selectedTour, promiseToggleActive.isLoading])


    /* Helpers */
    function loadTours() {
        promiseAllTours.setPromise(toursProvider.get()
            .then(response => {
                setAllTours(response.data);
                setCount(parseInt(response.headers['x-total-count']));
            })
            .catch(error => {
                console.debug(error);
                return Promise.reject(error);
            }));
    }

    const toggleTourActivity = async (tour: Tour) => {
        const newTour = { ...tour, active: !tour.active };
        return toursProvider.update(newTour).then(response => {
            setAllTours(tours => tours.map(tour => newTour.id === tour.id ? newTour : tour));
        })
    }
    /* Handlers */
    const handleChangeHideInactiveTours = () => {
        setHideInactiveTours(!hideInactiveTours);
    };

    const handleChangeActive = (tour: Tour) => {
        setSelectedTour(tour);
        changeActiveModal.show();
    };

    /* Renders */
    const renderTour = (tour: any) => {
        return (
            <div className={`tour-card ${!tour.active && "tour-card-inactive"}`} key={tour.id}>
                <div className="tour-title-wrapper tour-row"><p className="tour-title">{tour.name}</p></div>
                <div className="cluster tour-row">
                    <i className="ri-map-pin-2-fill icon"></i>
                    <p className="tour-text">
                        {`Cluster ${tour.cluster}`}
                    </p>
                </div>
                <div className="capacity tour-row">
                    <i className="ri-shopping-cart-fill icon"></i>
                    <p className="tour-text">
                        {`Kapazität ${tour.capacity}`}
                    </p>
                </div>
                <AldiButton onClick={() => handleChangeActive(tour)} className="button">
                    <div className="button-title">
                        {tour.active ? <i className="ri-pause-fill icon-button icon-bold"></i> : <i className="ri-share-forward-fill icon-button"></i>}
                        {tour.active ? "Deaktivieren" : "Aktivieren"}
                    </div>
                </AldiButton>
            </div>
        );
    }

    return (

        <div className='tour-overview'>
            <div className="container-header">
                <div className="header-title">
                    {"Touren-Übersicht"}
                </div>
                <div className="placeholder"></div>
                <div className="container-item">
                    <AldiCheckbox id="inactive-tours-checkbox" onChange={handleChangeHideInactiveTours} checked={hideInactiveTours} />
                    <label htmlFor="inactive-tours-checkbox" className="checkbox-label">
                        {"Inaktive Touren ausblenden"}
                    </label>
                </div>
                <div className="container-item">
                    <AldiButton onClick={createTourModal.show} kind="tertiary" data-testid="open-modal" className="add-tour-button">
                        {" + Neue Tour erstellen"}
                    </AldiButton>
                </div>
            </div>

            {promiseAllTours.status === PromiseStatus.Failed && <AldiReloadButton onClick={loadTours}/>}
            <div className="tour-table">
                {tours.map(tour => renderTour(tour))}
            </div>
        </div>
    )
}
