import { title } from 'process';
import './FormMessage.scss';

const FormMessage = (props: any) => {
    const {title, message, ...rest} = props
    return (
        <div {...rest} className="form-message">
            <div className="form-message-title">{props.title}</div>
            <div className="form-message-message">{props.message}</div>
        </div>
    )
}

export default FormMessage;