import './TableHead.scss';

const TableHead = (props: {
    children?: React.ReactNode,
    className?: string,
    loading?: boolean
}) => {
    return (
        <thead className={`${props.className} aldi-thead`}>
            {props.children}
            {props.loading && <tr className='loader' data-testid="activity-indicator" role="activity-indicator" aria-busy="true"></tr>}
        </thead>
    )
}

export default TableHead;