import './FulfillmentOrderPage.scss';
import { Article, ArticleStatus } from '@kehrwasser/aldi-sued-dtm-openapi';
import { ChangeEvent, useEffect, useState } from 'react';
import Table from '../../../../elementsAldi/table/Table';
import TableHead from '../../../../elementsAldi/table/tableHead/TableHead';
import TableBody from '../../../../elementsAldi/table/tableBody/TableBody';
import TableRow from '../../../../elementsAldi/table/tableRow/TableRow';
import TableCell from '../../../../elementsAldi/table/tableCell/TableCell';
import TableFoot from '../../../../elementsAldi/table/tableFoot/TableFoot';
import KWOverflowMenu from '../../../../elements/overflowmenu/KWOverflowMenu';
import KWMenuItem from '../../../../elements/menuitem/KWMenuItem';
import { ArticleFilter, articlesProvider } from '../../../../../shared/articles.service';
import { ordersProvider } from '../../../../../shared/orders.service';
import { configPaginationProvider } from '../../../../../shared/configPagination.service';
import Pagination from '../../../../elementsAldi/pagination/Pagination';
import AldiButton from '../../../../elementsAldi/button/AldiButton';
import AldiGeneralModal from '../../../../elementsAldi/generalmodal/AldiGeneralModal';
import { notify } from '../../../../../shared/helpers';
import useDebounce from '../../../../../hooks/useDebounce';
import { AldiChipInput } from '../../../../elementsAldi/chipinput/AldiChipInput';
import AldiCheckbox from '../../../../elementsAldi/checkbox/AldiCheckbox';

/* Icons */
import { ReactComponent as IconNotStarted } from '../../../../../assets/images/status-icons/not-started.svg';
import { ReactComponent as IconDone } from '../../../../../assets/images/status-icons/left.svg';
import { ReactComponent as IconFailure } from '../../../../../assets/images/status-icons/status-indicator-failure.svg';
import { useModal } from '../../../../../hooks/useModal';
import usePromise, { PromiseStatus } from '../../../../../hooks/usePromise';
import AldiReloadButton from '../../../../elementsAldi/reloadbutton/AldiReloadButton';


export default function FulfillmentOrderPage() {

    /* State Definitions */
    const [articles, setArticles] = useState<Article[]>([]);
    const promiseArticles = usePromise();
    const [selected, setSelected] = useState<Article["id"][]>([]);
    const [count, setCount] = useState(configPaginationProvider.count);
    const [page, setPage] = useState(configPaginationProvider.page);
    const [rowsPerPage, setRowsPerPage] = useState(configPaginationProvider.rowsPerPage);
    const [searchQuery, setSearchQuery] = useState('');
    const [cancellationTarget, setCancellationTarget] = useState<null | Article>(null);

    const promiseCancelSelected = usePromise();
    const cancelSelectedModal = useModal();

    const promiseCancelArticle = usePromise();
    const cancelArticleModal = useModal();

    const promiseCancelOrder = usePromise();
    const cancelOrderModal = useModal();


    const filter: ArticleFilter = {
        offset: page * rowsPerPage,
        limit: rowsPerPage,
        q: searchQuery
    };

    const debouncedFilter = useDebounce(filter, 600, Object.values(filter));

    /* Effect */
    useEffect(() => {
        loadArticles();
    }, Object.values(debouncedFilter));

    useEffect(() => {
        setSelected(selected => articles.map(article => article.id).filter(id => selected.includes(id)));
    }, [articles])

    useEffect(() => {
        cancelSelectedModal.setModal(<AldiGeneralModal
            disabled={promiseCancelSelected.isLoading}
            confirming={promiseCancelSelected.isLoading}
            textCancelButton="Abbrechen"
            onCancel={cancelSelectedModal.hide}
            textConfirmButton="Stornieren"
            submitKind="danger"
            onConfirm={() => {
                const canceledArticles: Article[] = getCanceledArticles(articles, selected);
                promiseCancelSelected.setPromise(
                    Promise.all(canceledArticles.map(
                        canceledArticle => {
                            articlesProvider.update(canceledArticle.id, canceledArticle);
                        }))
                        .then(() => {
                            const canceledArticlesById = new Map(canceledArticles.map(article => [article.id, article]));
                            setArticles(articles => articles.map(article => canceledArticlesById.get(article.id) ?? article));
                            notify(`${canceledArticles.length} Artikel ${canceledArticles.length === 1 ? "wurde" : "wurden"} erfolgreich storniert.`);
                            cancelSelectedModal.hide();
                            setSelected([]);
                        })
                        .catch(error => {
                            console.debug(error);
                            notify("Es gab Fehler bei der Löschung der Artikel.");
                        })
                )
            }}>
            {"Wollen Sie die ausgewählten Artikel wirklich stornieren?"}
        </AldiGeneralModal>)
    }, [selected, promiseCancelSelected.isLoading])

    useEffect(() => {
        if (cancellationTarget) {
            cancelArticleModal.setModal(<AldiGeneralModal
                disabled={promiseCancelArticle.isLoading}
                confirming={promiseCancelArticle.isLoading}
                textCancelButton="Abbrechen"
                onCancel={cancelArticleModal.hide}
                textConfirmButton="Stornieren"
                submitKind="danger"
                onConfirm={() => {
                    const article = cancellationTarget;
                    promiseCancelArticle.setPromise(
                        articlesProvider.update(article.id, article)
                            .then(response => {
                                const newArticles = articles.map(el => {
                                    if (el.id === article.id) {
                                        return response.data
                                    }
                                    return el
                                })
                                setArticles(newArticles);
                                notify(`Der Artikel wurde erfolgreich storniert.`);
                                cancelArticleModal.hide();
                            })
                            .catch(error => {
                                console.debug(error);
                                notify("Es gab Fehler bei der Stornierung.")
                            })
                    );
                }}>
                {`Wollen Sie den Artikel ${cancellationTarget.id} (${cancellationTarget.name}) wirklich stornieren?`}
            </AldiGeneralModal>);
        } else {
            cancelOrderModal.setModal(null);
        }
    }, [cancellationTarget, promiseCancelArticle.isLoading])

    useEffect(() => {
        if (cancellationTarget) {
            cancelOrderModal.setModal(<AldiGeneralModal
                disabled={promiseCancelOrder.isLoading}
                confirming={promiseCancelOrder.isLoading}
                textCancelButton="Abbrechen"
                onCancel={cancelOrderModal.hide}
                textConfirmButton="Stornieren"
                submitKind="danger"
                onConfirm={() => {
                    const article = cancellationTarget;

                    promiseCancelOrder.setPromise(ordersProvider.update(article.orderId, article.status)
                        .then(response => {
                            const newArticles = articles.map(article => {
                                if (article.id === response.data.id) {
                                    return response.data
                                }
                                else {
                                    return article
                                }
                            })
                            setArticles(newArticles);
                            notify(`Die Bestellung wurde erfolgreich storniert.`);
                            cancelOrderModal.hide();
                        })
                        .catch(error => {
                            console.debug(error);
                            notify("Es gab Fehler bei der Löschung der Bestellung.")
                        })
                    );
                }}>
                {`Wollen Sie die Bestellung ${cancellationTarget.orderId} wirklich stornieren?`}
            </AldiGeneralModal>)
        } else {
            cancelOrderModal.setModal(null);
        }
    }, [cancellationTarget, promiseCancelOrder.isLoading])


    /* Helper */

    function loadArticles() {
        promiseArticles.setPromise(articlesProvider.get(filter).then(response => {
            setArticles(response.data);
            setCount(parseInt(response.headers['x-total-count']));
        }).catch(error => {
            console.debug(error);
            return Promise.reject(error);
        }));
    }

    /* Handlers */
    const handleChangePage = (value: number) => {
        setPage(value);
    };

    const handleChangeRowsPerPage = (value: number) => {
        setRowsPerPage(value);
        setPage(0);
    };

    const handleChangeSearchQuery = (value: string) => {
        setSearchQuery(value);
    };


    const handleChangeCheckbox = (article: Article, checked: boolean) => {
        if (checked) {
            setSelected(selected => [...selected, article.id]);
        } else {
            setSelected(selected => selected.filter(id => id !== article.id));
        }
    };

    const handleCancelArticle = (article: Article) => {
        setCancellationTarget(article);
        cancelArticleModal.show();
    };

    const handleCancelOrder = (article: Article) => {
        setCancellationTarget(article);
        cancelOrderModal.show();
    };

    const handleCancelSelected = () => {
        if (selected.length === 0) {
            notify("Es wurden keine Artikel ausgewählt.");
        } else {
            cancelSelectedModal.show();
        }
    };

    return (
        <div className='order-overview'>
            <FilterBar
                query={searchQuery}
                onChangeQuery={handleChangeSearchQuery}
                onCancelSelected={handleCancelSelected}
                numSelected={selected.length}
            />
            <OrderTable
                count={count}
                page={page}
                rowsPerPage={rowsPerPage}
                articles={articles}
                selected={selected}
                loading={promiseArticles.status}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                onCancelArticle={handleCancelArticle}
                onCancelOrder={handleCancelOrder}
                onChangeCheckbox={handleChangeCheckbox}
                onReloadClicked={loadArticles}
            />
        </div>
    )
}


type FilterBarProps = {
    query?: string,
    onCancelSelected?: () => void,
    onChangeQuery?: (query: string) => void,
    numSelected: number,
}

function FilterBar(props: FilterBarProps) {

    const { query = "", onCancelSelected, onChangeQuery, numSelected } = props;

    const kind = numSelected > 0 ? "danger" : "secondary";

    return (
        <div className="filters">
            <div className="header-title">
                {"Kundenbestellungen"}
            </div>
            <div className="placeholder"></div>
            <div className="aldi-box filter">
                <AldiButton kind={kind} disabled={numSelected === 0} onClick={onCancelSelected}>
                    {"Ausgewählte Artikel stornieren"}
                </AldiButton>
            </div>

            <div className="medium-variable-gap" />

            <div className="aldi-box filter">
                <AldiChipInput
                    placeholder="Artikel-Nr., Bestell-Nr. und Artikelname"
                    onChange={(value: string[]) => onChangeQuery && onChangeQuery(value.join(" "))}
                    resettable
                    value={query.split(" ").filter(x => x !== "")} />
            </div>
        </div>
    )
}


type OrderTableProps = {
    loading: PromiseStatus,
    count: number,
    page: number,
    rowsPerPage: number,
    onPageChange?: (page: number) => void,
    onRowsPerPageChange?: (rowsPerPage: number) => void,
    onReloadClicked: () => void,
    articles: Article[],
    selected: Article["id"][],
} & OrderTableRowEventHandlers;

function OrderTable(props: OrderTableProps) {
    const { loading = false, count, page, onPageChange, rowsPerPage, onRowsPerPageChange, onReloadClicked, articles, selected, onCancelOrder, onCancelArticle, onChangeCheckbox } = props;

    return (
        <div className="aldi-box aldi-general-table-wrapper">
            <Table>
                <TableHead loading={loading === PromiseStatus.Pending}>
                    <TableRow className="single-row-thead">
                        <TableCell />
                        <TableCell>{"Artikel-Nr."}</TableCell>
                        <TableCell>{"Bestell-Nr."}</TableCell>
                        <TableCell>{"Artikelname"}</TableCell>
                        <TableCell>{"Anzahl"}</TableCell>
                        <TableCell>{"Pick Location"}</TableCell>
                        <TableCell colSpan={2}>{"Status"}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {loading === PromiseStatus.Failed &&
                        <TableRow>
                            <TableCell colSpan={7}><AldiReloadButton onClick={onReloadClicked}/></TableCell>
                        </TableRow>}
                    {articles.map((article) =>
                        <OrderTableRow
                            key={article.id}
                            article={article}
                            selected={selected}
                            onCancelArticle={onCancelArticle}
                            onCancelOrder={onCancelOrder}
                            onChangeCheckbox={onChangeCheckbox} />)}
                </TableBody>
                <TableFoot>
                    <TableRow>
                        <Pagination
                            colSpan={8}
                            count={count}
                            page={page}
                            rowsPerPage={rowsPerPage}
                            onPageChange={onPageChange}
                            onRowsPerPageChange={onRowsPerPageChange}
                        />
                    </TableRow>
                </TableFoot>
            </Table>
        </div>
    )
}


type OrderTableRowProps = {
    article: Article,
    selected: Article["id"][],
} & OrderTableRowEventHandlers;

type OrderTableRowEventHandlers = {
    onCancelArticle?: (article: Article) => void,
    onCancelOrder?: (article: Article) => void,
    onChangeCheckbox?: (article: Article, value: boolean) => void,
}

function OrderTableRow(props: OrderTableRowProps) {

    const { article, selected, onCancelOrder, onCancelArticle, onChangeCheckbox } = props;

    const statusIcon = (status: ArticleStatus): JSX.Element => {
        let icon: JSX.Element;
        const className = "status-icon";

        switch (status) {
            case ArticleStatus.Picked:
                icon = <IconDone className={className} />;
                break;
            case ArticleStatus.NotPicked:
                icon = <IconNotStarted className={className} />;
                break;
            case ArticleStatus.Canceled:
                icon = <IconFailure className={className} />;
                break;
            default:
                return <span />;
        }

        return icon;
    };

    const isCancelable = (status: ArticleStatus): boolean => {
        return (status !== ArticleStatus.NotPicked);
    };



    return (
        <TableRow key={article.id}>
            <TableCell>
                <AldiCheckbox
                    disableRipple
                    size='medium'
                    checked={selected.includes(article.id)}
                    onChange={(event: ChangeEvent<HTMLInputElement>) => onChangeCheckbox && onChangeCheckbox(article, event.target.checked)} />
            </TableCell>
            <TableCell className='title-column-cell'>{article.id}</TableCell>
            <TableCell>{article.orderId}</TableCell>
            <TableCell>{article.name}</TableCell>
            <TableCell>{article.quantity}</TableCell>
            <TableCell data-testid="location">{article.location}</TableCell>
            <TableCell className="status">
                <div className='wrapper'>
                    <div className='status-wrapper'>
                        {statusIcon(article.status)}
                        <p className="status-text">
                            {articleStatusLabel(article.status)}
                        </p>
                    </div>
                </div>
            </TableCell>
            <TableCell className='last-cell'>
                <KWOverflowMenu className="menu">
                    <KWMenuItem
                        data-testid="custom-element"
                        onClick={() => onCancelArticle && onCancelArticle(article)}
                        disabled={isCancelable(article.status)}>
                        {"Artikel stornieren"}
                    </KWMenuItem>
                    <KWMenuItem
                        onClick={() => onCancelOrder && onCancelOrder(article)}
                        disabled={isCancelable(article.status)}>
                        {"Bestellung stornieren"}
                    </KWMenuItem>
                </KWOverflowMenu>
            </TableCell>
        </TableRow>
    )
}

function articleStatusLabel(status: ArticleStatus): string {
    switch (status) {
        case ArticleStatus.Canceled: return "Storniert";
        case ArticleStatus.NotPicked: return "Nicht gepickt";
        case ArticleStatus.Picked: return "Gepickt";
    }
};

export function getCanceledArticles(articles: Article[], selected: string[]): Article[] {
    return articles
        .filter(article => selected.includes(article.id) && article.status === ArticleStatus.NotPicked)
        .map(article => ({...article, status: ArticleStatus.Canceled}))
}

