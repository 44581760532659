import { useEffect, useState } from 'react'

function useDebounce<T>(value: T, delay?: number, updateCondition?: any[]): T {
    updateCondition = updateCondition ?? [value];
    delay = delay ?? 300;
    const [debouncedValue, setDebouncedValue] = useState<T>(value)

    useEffect(() => {
        const handle = setTimeout(() => setDebouncedValue(value), delay)

        return () => clearTimeout(handle);
    }, [delay, ...updateCondition])

    return debouncedValue
}

export default useDebounce
