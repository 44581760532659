import './ResetButton.scss';

function ResetButton(props: any) {

  return (
    <div className="aldi-kwmc-reset-button-wrapper">
      <div {...props} className="aldi-kwmc-reset-button" role="button">
        <i className="ri-close-circle-line icon-reset-week"></i>
      </div>
    </div>
  );
};

export default ResetButton;

