import { Location, LocationStatus, LocationType, LocationArea, ArticlesForLocation } from "@kehrwasser/aldi-sued-dtm-openapi";
import { AxiosResponse } from "axios";
import { apiService } from "./apiService";


export interface LocationFilter {
    status?: LocationStatus[];
    type?: LocationType[];
    area?: LocationArea[];
    kind?: string[];
    hallwayAndNumber?: [string, string][];
    offset?: number;
    limit?: number;
    q?: string
}

export const locationsProvider = {

    api: apiService.location,

    get(input: string | LocationFilter): Promise<AxiosResponse<Location[], any>> {

        let locationFilter: LocationFilter;

        if (typeof input === 'string') {
            locationFilter = { id: input } as LocationFilter;
        } else {
            locationFilter = input;
        }

        return this.api.getLocations(
            locationFilter.q,
            locationFilter.status?.join(','),
            locationFilter.type?.join(','),
            locationFilter.area?.join(','),
            locationFilter.kind?.join(','),
            locationFilter.hallwayAndNumber?.map(([hallway, number]) => hallway + number).join(','),
            locationFilter.offset,
            locationFilter.limit);

    },

    update(id: string, location: Location): Promise<AxiosResponse<Location, any>> {
        return this.api.updateLocation(id, location);
    },

    getArticles(id: string, q: string): Promise<AxiosResponse<ArticlesForLocation[], any>> {

        return this.api.getArticlesForLocation(id, q);

    },

};
