import './KWBadge.scss';

function KWBadge(props: any) {
    return (
        <div className={"badge " + props.type} >
            <p className="badge-text">
            {props.children}
            </p>
        </div>
    )
}

export default KWBadge;