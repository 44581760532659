import { AxiosResponse } from "axios";
import { DeliveryTimeframe, DeliveryApi, Week } from "@kehrwasser/aldi-sued-dtm-openapi";
import { WeekNumbers } from "./datetime/DateTime";
import { apiService } from "./apiService";

const deliveryTimeframesProvider = {

    api: apiService.timeframe,

    get(): Promise<AxiosResponse<DeliveryTimeframe[], any>> {

        return this.api.getDeliveryTimeframes();

    },

    create(deliveryTimeframe: DeliveryTimeframe): Promise<AxiosResponse<DeliveryTimeframe, any>> {

        return this.api.createDeliveryTimeframe(
            deliveryTimeframe
        );
    },

    update(deliveryTimeframe: DeliveryTimeframe): Promise<AxiosResponse<DeliveryTimeframe, any>> {

        return this.api.updateDeliveryTimeframe(
            deliveryTimeframe.name,
            deliveryTimeframe
        );
    },

    delete(deliveryTimeframe: DeliveryTimeframe): Promise<AxiosResponse<DeliveryTimeframe, any>> {

        return this.api.deleteDeliveryTimeframe(
            deliveryTimeframe.name
        );
    },

    getHolidays(week: number) {
        return this.api.getDeliveryHolidays(week);
    }

};

const deliveryProvider = {

    api: apiService.delivery,

    getDeliveryWeek(weekYear: number, weekNumber: WeekNumbers): Promise<AxiosResponse<Week, any>> {

        return this.api.getDeliveryWeek(weekYear, weekNumber);

    },

    updateDeliveryWeek(weekYear: number, weekNumber: WeekNumbers, sourceWeekYear: number, sourceWeekNumber: WeekNumbers): Promise<AxiosResponse<Week, any>> {

        return this.api.patchDeliveryWeek(weekYear, weekNumber, sourceWeekYear, sourceWeekNumber);
    }

};

export { deliveryTimeframesProvider, deliveryProvider };
