import './TimetableModal.scss';
import { useEffect, useState } from 'react';
import { cloneForm, Form, FormValidation, validateForm, updateFormValidation } from './form';
import AldiInput from '../../../../elementsAldi/input/AldiInput';
import AldiSelect from '../../../../elementsAldi/select/AldiSelect';
import AldiMenuItem from '../../../../elementsAldi/menuitem/AldiMenuItem';
import AldiModal from '../../../../elementsAldi/modal/Modal';
import AldiTimePicker from '../../../../elementsAldi/timepicker/AldiTimePicker';

interface TimetableModalProps {
    form: Form,
    onFormChange?: (form: Form) => void,
    onDelete?: () => void,
    onSubmit?: () => void,
    onClose?: () => void,
    isLoading?: boolean,
    disabled?: boolean,
}

export default function TimetableModal({
    form,
    onFormChange,
    onDelete,
    onSubmit,
    onClose,
    isLoading = false,
    disabled = false,
}: TimetableModalProps) {
    /* State Definitions */
    const [validation, setValidation] = useState<FormValidation>(validateForm(form));

    useEffect(() => {
        setValidation(updateFormValidation(validation, form));
    }, [form]);

    useEffect(() => {
        setValidation(validateForm(form));
    }, [form.day]);

    /* Helpers */
    const getValidationsStartTime = (index: number) => {
        if (validation.fields[index]) {
            let { start, hideStart } = validation.fields[index];
            return (start || hideStart) ? "" : "Bitte gebe eine passende Startzeit im Format HH:MM an.";
        } else {
            console.warn(`validation has no fields at index ${index}`, validation);
            return ""
        }
    }

    const getValidationsEndTime = (index: number) => {
        if (validation.fields[index]) {
            let { end, hideEnd } = validation.fields[index];
            return (end || hideEnd) ? "" : "Bitte gebe eine passende Endzeit im Format HH:MM an.";
        } else {
            console.warn(`validation has no fields at index ${index}`, validation);
            return ""
        }
    }

    const getValidationsEndTimeAfterStartTime = (index: number) => {
        if (validation.fields[index]) {
            let { endAfterStart, hideEndAfterStart } = validation.fields[index];
            return (endAfterStart || hideEndAfterStart) ? "" : "Die Endzeit muss nach der Startzeit liegen.";
        } else {
            console.warn(`validation has no fields at index ${index}`, validation);
            return ""
        }
    }

    /* Handlers */
    const handleChangeStartTime = (value: string, index: number) => {
        let newForm = cloneForm(form);
        newForm.fields[index].start = value;
        onFormChange && onFormChange(newForm);
    };

    const handleChangeEndTime = (value: string, index: number) => {
        let newForm = cloneForm(form);
        newForm.fields[index].end = value;
        onFormChange && onFormChange(newForm);
    };

    const handleChangeNextDay = (value: boolean, index: number) => {
        let newForm = cloneForm(form);
        newForm.fields[index].nextDay = value;
        onFormChange && onFormChange(newForm);
    };

    function dayLabel(value: boolean | null): string {
        switch (value) {
            case true:
                return "Nächster Tag";
            default:
                return "Dieser Tag";
        }
    }

    let oneErrorAlreadyShown = false;
    return (
        <div className="collect-timetable-modal">
            <AldiModal
                textCancelButton="Abbrechen"
                onCancel={onClose}
                enableDeleteButton={form.isEdit}
                textDeleteButton="Löschen"
                onDelete={onDelete}
                title={form.day.toLocaleDateString()}
                subtitle="Lege die Abholzeitfenster für den ausgewählten Tag fest."
                textSubmitButton="Speichern"
                onSubmit={() => {
                    setValidation(updateFormValidation(validation, form, { showAllErrors: true }))
                    onSubmit && onSubmit()
                }}
                loading={isLoading}
                disabled={disabled}
                width="442px">

                {form.fields.map(({ cleveron, start, nextDay, end }, index: number) => {
                    let validationErrors = [
                        getValidationsStartTime(index),
                        getValidationsEndTime(index),
                        getValidationsEndTimeAfterStartTime(index),
                    ].filter(message => message !== "");

                    if (oneErrorAlreadyShown) {
                        validationErrors = [];
                    } else if (validationErrors.length > 0) {
                        oneErrorAlreadyShown = true;
                    }

                    return (
                        <div key={cleveron.id}>
                            <p className="heading">{`Cleveron ${cleveron.id}`}</p>
                            <div className="input-row">
                                <div className='input-wrapper'>
                                    <AldiTimePicker
                                        value={start}
                                        placeholder="Beginn"
                                        onChange={value => handleChangeStartTime(value, index)}
                                        disabled={isLoading}
                                        fullWidth>
                                    </AldiTimePicker>
                                </div>
                                <div className='input-wrapper'>
                                    <AldiTimePicker
                                        value={end}
                                        placeholder="Ende"
                                        onChange={value => handleChangeEndTime(value, index)}
                                        disabled={isLoading}
                                        fullWidth>
                                    </AldiTimePicker>
                                </div>
                                <div className='select'>
                                    <AldiSelect
                                        value={nextDay}
                                        label="Tag"
                                        onChange={(value: boolean) => handleChangeNextDay(value || false, index)}
                                        renderValue={() => dayLabel(nextDay)}
                                        disabled={isLoading}
                                        componentsProps={{ root: { className: 'select' } }}>
                                        {[false, true].map(value => (
                                            <AldiMenuItem key={value} value={value}>{dayLabel(value)}</AldiMenuItem>
                                        ))}
                                    </AldiSelect>
                                </div>
                            </div>
                            {validationErrors[0] ? <p className="validation">{validationErrors[0]}</p> : <></>}
                        </div>
                    )
                })}
            </AldiModal>
        </div>
    )
}
