import { AxiosResponse } from "axios";
import { ClusterStatus, Cluster } from "@kehrwasser/aldi-sued-dtm-openapi";
import { apiService } from "./apiService";

export interface ClusterFilter {
    departureMin?: string;
    departureMax?: string;
    status?: ClusterStatus[];
    offset?: number;
    limit?: number;
    q?: string
}

export const clusterProvider = {

    api: apiService.cluster,

    get(input: string | ClusterFilter): Promise<AxiosResponse<Cluster[], any>> {

        let clusterFilter: ClusterFilter;

        if (typeof input === 'string') {
            clusterFilter = { id: input } as ClusterFilter;
        } else {
            clusterFilter = input;
        }

        return this.api.getClusters(
            clusterFilter.q,
            clusterFilter.departureMin,
            clusterFilter.departureMax,
            clusterFilter.status?.join(','),
            clusterFilter.offset,
            clusterFilter.limit);

    },

    update(clusterId: string, cluster: Cluster): Promise<AxiosResponse<Cluster, any>> {
        return this.api.patchCluster(
            clusterId,
            cluster);
    }

};
