import { useEffect, useState } from "react";
import AldiInput, { AldiInputProps } from "../input/AldiInput";
import "./AldiTimePicker.scss";

const DIGITS = "0123456789".split("");
const PARTIAL_TIME = /^(\d{1,2})$/;
const FULL_TIME = /^(\d{1,2}):(\d{0,2})$/;

export default function AldiTimePicker(props: {
    value: string,
} & AldiInputProps) {
    const {onChange, placeholder, value, ...inputProps} = props;

    const [editValue, setEditValue] = useState<string>(value);

    useEffect(() => {
        if (onChange) {
            onChange(normalize(editValue));
        }
    }, [editValue])

    function normalize(editValue: string): string {
        const tryPartial = editValue.match(PARTIAL_TIME);
        const tryFull = editValue.match(FULL_TIME);
        if (tryPartial) {
            const hours = Math.min(23, parseInt(tryPartial[1]));
            return `${hours.toString().padStart(2,'0')}:00`
        } else if (tryFull) {
            const hours = Math.min(23, parseInt(tryFull[1]));
            const minutes = Math.min(59, parseInt(tryFull[2]));
            return `${hours.toString().padStart(2,'0')}:${minutes.toString().padEnd(2,'0')}`;
        } else {
            return "";
        }
    }

    function handleChange(value: string) {
        const parts = value.split(":");
        const hoursString = Array.from(parts[0]).filter(isDigit).join("");
        const minutesString = parts.slice(1).join("");
        if (hoursString === "") {
            setEditValue("");
        } else if (minutesString === "") {
            switch (hoursString.length) {
                case 0:
                    setEditValue("");
                    break;
                case 1:
                    let digit1 = parseInt(hoursString[0]);
                    let rest1 = hoursString.slice(1);
                    let colon1 = rest1 === "" && parts.length === 1 ? "" : ":";
                    if (digit1 > 2) {
                        setEditValue(`0${digit1}${colon1}${rest1}`);
                    } else {
                        setEditValue(`${hoursString.slice(0,2)}${colon1}${rest1}`);
                    }
                    break;
                default:
                    let hours2 = hoursString.slice(0,2);
                    hours2 = parseInt(hours2) < 24 ? hours2 : "23";
                    let rest2 = hoursString.slice(2);
                    let colon2 = rest2 === "" && parts.length === 1 ? "" : ":";
                    setEditValue(`${hours2}${colon2}${rest2}`);
                    break;
            }
        } else {
            const hours = parseInt(hoursString);
            if (hours < 24) {
                if (hours < 10) {
                    setEditValue(`0${hours}:${minutesString.slice(0,2)}`);
                } else {
                    setEditValue(`${hours}:${minutesString.slice(0,2)}`);
                }
            } else {
                setEditValue(`23:${minutesString.slice(0,2)}`);
            }
        }
    }

    function handleBlur() {
        setEditValue(normalize);
    }

    return <div className="aldi-timepicker">
        <AldiInput {...inputProps} label={placeholder} value={editValue} onChange={handleChange} onBlur={handleBlur} placeholder={"HH:MM"} />
    </div>;
}

function isDigit(char: string): boolean {
    return DIGITS.includes(char);
}
