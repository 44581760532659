import './DeliveryCleveronsPage.scss';
import { Cleveron } from '@kehrwasser/aldi-sued-dtm-openapi';
import { useEffect, useState } from 'react';
import Table from '../../../elementsAldi/table/Table';
import TableHead from '../../../elementsAldi/table/tableHead/TableHead';
import TableBody from '../../../elementsAldi/table/tableBody/TableBody';
import TableRow from '../../../elementsAldi/table/tableRow/TableRow';
import TableCell from '../../../elementsAldi/table/tableCell/TableCell';
import { CleveronFilter, cleveronsProvider } from '../../../../shared/cleverons.service';
import CleveronModal from './modal/CleveronModal';
import TableFoot from '../../../elementsAldi/table/tableFoot/TableFoot';
import { configPaginationProvider } from '../../../../shared/configPagination.service';
import { notify } from '../../../../shared/helpers';
import Pagination from '../../../elementsAldi/pagination/Pagination';
import AldiButton from '../../../elementsAldi/button/AldiButton';
import usePromise, { PromiseStatus } from '../../../../hooks/usePromise';
import { useModal } from '../../../../hooks/useModal';
import AldiGeneralModal from '../../../elementsAldi/generalmodal/AldiGeneralModal';
import AldiReloadButton from '../../../elementsAldi/reloadbutton/AldiReloadButton';


function DeliveryCleveronsPage() {

    /* State Definitions */
    const [cleverons, setCleverons] = useState<Cleveron[]>([]);
    const [cleveronToEdit, setCleveronToEdit] = useState<null | { cleveron: Cleveron, index: number }>(null);
    const [count, setCount] = useState(configPaginationProvider.count);
    const [page, setPage] = useState(configPaginationProvider.page);
    const [rowsPerPage, setRowsPerPage] = useState(configPaginationProvider.rowsPerPage);
    const promiseCleverons = usePromise();
    const promiseDeleteCleveron = usePromise();
    const cleveronModal = useModal();
    const confirmationModal = useModal();

    /* Communication */
    useEffect(() => {
        loadCleverons();
    }, [page, rowsPerPage]);

    useEffect(() => {
        cleveronModal.setModal(<div className="cleveron-modal-wrapper">
            <CleveronModal
                modal={cleveronModal}
                cleverons={cleverons}
                setCleverons={setCleverons}
                onDelete={() => {
                    if (cleveronToEdit) {
                        confirmationModal.show();
                    }
                }}
                cleveron={cleveronToEdit} />
        </div>);
    }, [cleverons, cleveronToEdit])

    useEffect(() => {
        if (cleveronToEdit) {
            const title = "Löschung des Cleverons";
            const message = `Wollen Sie den Cleveron ${cleveronToEdit?.cleveron?.id} wirklich löschen?`;
            confirmationModal.setModal(<AldiGeneralModal
                key={confirmationModal.id}
                disabled={promiseDeleteCleveron.isLoading}
                confirming={promiseDeleteCleveron.isLoading}
                onConfirm={() => {
                    if (cleveronToEdit) {
                        promiseDeleteCleveron.setPromise(cleveronsProvider.delete(cleveronToEdit.cleveron.id)
                            .then(response => {
                                setCleverons(cleverons.filter((original: Cleveron) => original.id !== cleveronToEdit.cleveron.id))
                                notify(`Der Cleveron wurde erfolgreich gelöscht.`);
                                confirmationModal.hide();
                                cleveronModal.hide();
                            }).catch(error => {
                                console.debug(error);
                                notify(`Beim Löschen des Cleverons ist ein Fehler aufgetreten.`);
                            }));
                    } else {
                        confirmationModal.hide();
                    }
                }}
                textConfirmButton={"Löschen"}
                onCancel={confirmationModal.hide}
                textCancelButton={"Abbrechen"}>
                <h2>{title}</h2>
                <p>{message}</p>
            </AldiGeneralModal>);
        } else {
            confirmationModal.setModal(null);
        }
    }, [cleveronToEdit, promiseDeleteCleveron.isLoading]);

    /* Helpers */

    function loadCleverons() {
        const filter: CleveronFilter = {
            offset: page * rowsPerPage,
            limit: rowsPerPage
        };

        promiseCleverons.setPromise(
            cleveronsProvider.get(filter).then(response => {
                setCleverons(response.data);
                setCount(parseInt(response.headers['x-total-count']));
            }).catch(error => {
                console.debug(error);
                setCleverons([]);
                return Promise.reject(error);
            }));
    }

    /* Handlers */

    const handleAddCleveron = () => {
        setCleveronToEdit(null);
        cleveronModal.show();
    };

    const handleEditCleveron = (cleveron: Cleveron, index: number) => {
        setCleveronToEdit({ cleveron: cleveron, index: index });
        cleveronModal.show();
    };

    /* Renders */

    const renderInfoRow = (info: string | JSX.Element) => {
        return (<TableRow key="empty-state">
            <TableCell colSpan={3}></TableCell>
            <TableCell>{info}</TableCell>
            <TableCell colSpan={3}>
            </TableCell>
        </TableRow>)
    };

    const getEmptyState = () => {
        switch (promiseCleverons.status) {
            case PromiseStatus.Pending: return 'Lädt...';
            case PromiseStatus.Failed: return (<AldiReloadButton onClick={loadCleverons}/>);
            case PromiseStatus.Done: return 'Derzeit keine Daten vorhanden.'
        }
    };

    const renderRow = (cleveron: Cleveron, index: number) => {
        return (
            <TableRow key={cleveron.id}>
                <TableCell>{cleveron.id}</TableCell>
                <TableCell className="address-cell">
                    <div>
                        {cleveron.street} {cleveron.streetNo}<br />
                        <span className="subtext">{cleveron.zip} {cleveron.city}</span>
                    </div>
                </TableCell>
                <TableCell>
                    <div>
                        {cleveron.capacityAmbient}<br />
                        <span className="subtext">
                            {"Boxen"}
                        </span>
                    </div>
                </TableCell>
                <TableCell>
                    <div>
                        {cleveron.bufferAmbient}<br />
                        <span className="subtext">
                            {"Boxen"}
                        </span>
                    </div>
                </TableCell>
                <TableCell>
                    <div>
                        {cleveron.capacityFrozen}<br />
                        <span className="subtext">
                            {"Boxen"}
                        </span>
                    </div>
                </TableCell>
                <TableCell>
                    <div>
                        {cleveron.bufferFrozen}<br />
                        <span className="subtext">
                            {"Boxen"}
                        </span>
                    </div>
                </TableCell>
                <TableCell>
                    <div className='action-icons'>
                        <AldiButton className="edit-button" onClick={() => handleEditCleveron(cleveron, index)}><i className="ri-edit-line edit-icon"></i></AldiButton>
                    </div>
                </TableCell>
            </TableRow>
        );
    }

    return (

        <div className='cleverons-overview'>

            <div className="container-header">
                <div className="header-title">
                    Cleveron-Übersicht
                </div>
                <div className="placeholder"></div>
                <AldiButton onClick={handleAddCleveron} data-testid="open-modal" kind="tertiary" className="add-cleveron-button"><i className="ri-add-line plus-icon"></i><span className="add-cleveron-button-text">Cleveron hinzufügen</span></AldiButton>
            </div>

            <div className="aldi-box aldi-general-table-wrapper">
                <Table>
                    <TableHead loading={promiseCleverons.isLoading}>
                        <TableRow className="primary-header-row">
                            <TableCell>
                                {"ID"}
                            </TableCell>
                            <TableCell>
                                {"Adresse"}
                            </TableCell>
                            <TableCell subtext="Ambient/Chilled">
                                {"Kapazität"}
                            </TableCell>
                            <TableCell subtext="Ambient/Chilled">
                                {"Puffer Boxen"}
                            </TableCell>
                            <TableCell subtext="Frozen">
                                {"Kapazität"}
                            </TableCell>
                            <TableCell subtext="Frozen">
                                {"Puffer Boxen"}
                            </TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {cleverons.length == 0 && renderInfoRow(getEmptyState())}
                        {cleverons.map((cleveron, index) => renderRow(cleveron, index))}
                    </TableBody>
                    <TableFoot>
                        <TableRow>
                            <Pagination
                                colSpan={7}
                                count={count}
                                page={page}
                                onPageChange={setPage}
                                rowsPerPage={rowsPerPage}
                                onRowsPerPageChange={setRowsPerPage}
                            />
                        </TableRow>
                    </TableFoot>
                </Table>
            </div>
        </div>
    )
}

export default DeliveryCleveronsPage;
