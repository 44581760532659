import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { fakeAuthProvider } from "../../shared/auth";
import { parseCookies } from "../../shared/cookies";

class Credentials { }

interface AuthContextType {
  session: string | null;
  signin: (credentials: Credentials, callback: VoidFunction) => void;
  signout: (callback: VoidFunction) => void;
}

let AuthContext = React.createContext<AuthContextType>(null!);

function readSessionFromCookies(): string | null {
  let session_string = parseCookies().get("session");
  if (typeof session_string === "string" && session_string !== null) {
    return session_string;
  } else {
    return null;
  }
}

function AuthProvider({ children }: { children: React.ReactNode }) {
  let initialSession = readSessionFromCookies();
  let [session, setSession] = React.useState<string | null>(initialSession);

  let signin = (credentials: Credentials, callback: VoidFunction) => {
    return fakeAuthProvider.signin(credentials, () => {
      setSession(readSessionFromCookies());
      callback();
    });
  };

  let signout = (callback: VoidFunction) => {
    return fakeAuthProvider.signout(() => {
      setSession(null);
      callback();
    });
  };

  let value = { session, signin, signout };

  return <AuthContext.Provider value={value}> {children} </AuthContext.Provider>;
}

function useAuth() {
  return React.useContext(AuthContext);
}

function RequireAuth({ children }: { children: JSX.Element }) {
  let auth = useAuth();
  let location = useLocation();

  const session = auth.session;

  if (session === null)
    return <Navigate to="/login" state={{ from: location }
    } replace />;

  return children;
}

function RequireNoAuth({ children }: { children: JSX.Element }) {
  let auth = useAuth();

  const session = auth.session;

  if (session !== null)
    return <Navigate to="/" replace />;

  return children;
}

export { useAuth, Credentials, AuthProvider, RequireAuth, RequireNoAuth, AuthContext, type AuthContextType };
