import './KWRadioButton.scss';

function KWRadioButton(props: any) {
    const { children, handleChange, checked, value, disabled } = props;

    return (
        <div className="radio">
            <label className="radio-label">
                <input
                    className="radio-button"
                    type="radio"
                    value={value}
                    checked={checked}
                    onChange={handleChange}
                    disabled={disabled}
                />
                {children}
            </label>
        </div>
    );
};

export default KWRadioButton;

