import { Box, styled, Tab, Tabs } from '@mui/material';


interface AldiTabsProps {
    children?: React.ReactNode;
    value: number;
    onChange: (event: React.SyntheticEvent, newValue: number) => void;
}

export function AldiTabs(props: AldiTabsProps) {

    const StyledTabs = styled((props: AldiTabsProps) => (
        <Tabs
            {...props}
            TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
            />
    ))({
        '& .MuiTabs-indicator': {
            display: 'flex',
            backgroundColor: 'transparent',
        },
        '& .MuiTabs-indicatorSpan': {
            width: '100%',
            backgroundColor: '#3fc0f0',
        },
    });

    return (
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <StyledTabs {...props}  aria-label="tabs" />
        </Box>
    )
}


export interface AldiTabProps {
    label: string;
}


export function AldiTab(props: AldiTabProps) {

    const StyledTab = styled((props: AldiTabProps) => (
        <Tab disableRipple {...props} />
    ))(({ theme }) => ({
        marginRight: theme.spacing(1),
        '&.Mui-selected': {
            color: '#3fc0f0',
        }
    }));

    return <StyledTab {...props} />
}
