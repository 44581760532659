import { Popover } from '@mui/material';
import { ReactComponent as IconSettingsAdjust } from '../../../assets/images/custom-icons/settings--adjust.svg';
import React from 'react';
import AldiButton from '../button/AldiButton';
import './AldiFilterMenu.scss';

function AldiFilterMenu(props: {
    selected?: boolean,
    loading?: boolean,
    type?: string,
    className?: string,
    classNamePopover?: string,
    children?: React.ReactNode,
    onClick?: () => void,
}) {
    const { selected = false, loading = false, onClick, type = "button", className = '', classNamePopover, ...rest } = props;

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const classes = ["aldi-filter-menu", className ?? ""];
    const buttonClasses = [selected ? "selected" : "", anchorEl ? "active" : ""];

    return (
        <div {...rest} className={classes.join(" ")}>
            <AldiButton className={buttonClasses.join(" ")} role="menu" variant="contained" kind="tertiary" onClick={handleClick}>
                <IconSettingsAdjust className='filter-icon' />
            </AldiButton>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <div className={`${classNamePopover} aldi-filter-popover`}>
                    {props.children}
                </div>
            </Popover>
        </div>
    )
}

export default AldiFilterMenu;
