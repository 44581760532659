import './Modal.scss';
import AldiButton from '../button/AldiButton';

type AldiModalProps = {
    textSubmitButton?: string,
    onSubmit?: () => void,
    textCancelButton?: string,
    onCancel?: () => void,
    textDeleteButton?: string,
    enableDeleteButton?: boolean,
    onDelete?: () => void,
    loading?: boolean,
    title: string,
    subtitle?: string,
    children?: any,
    width?: any,
    disabled?: boolean,
    disabledSubmit?: boolean,
};


function AldiModal(props: AldiModalProps) {
    const {
        onDelete,
        onCancel,
        loading,
        title,
        subtitle,
        onSubmit,
        children,
        width,
        textDeleteButton = "Löschen",
        textCancelButton = "Abbrechen",
        textSubmitButton = "Ok",
        enableDeleteButton = false,
        disabled = false,
        disabledSubmit = false
    } = props;

    function handleSubmit(event: any) {
        event.preventDefault();
        onSubmit && onSubmit();
    }

    return (
        <div className="aldi-modal-background">
            <div className="modal-wrapper" style={width && { width: width }}>
                <h1 className="title">{title}</h1>
                {subtitle && <p className="subtitle">{subtitle}</p>}
                <form onSubmit={handleSubmit} noValidate>
                    <div className="content-wrapper">
                        {children}
                    </div>
                    <div className="button-row">
                        {enableDeleteButton && (
                            <div className="button-wrapper">
                                <AldiButton
                                    kind="danger"
                                    className="button-row-button"
                                    disabled={disabled}
                                    onClick={(event: React.MouseEvent) => {
                                        event.preventDefault();
                                        onDelete && onDelete();
                                    }}>{textDeleteButton}
                                </AldiButton>
                            </div>
                        )}
                        <div className="button-wrapper">
                            <AldiButton
                                kind="secondary"
                                onClick={(event: React.MouseEvent) => {
                                    event.preventDefault();
                                    onCancel && onCancel();
                                }}
                                className="button-row-button"
                                disabled={disabled}
                                data-testid="close-modal">{textCancelButton}
                            </AldiButton>
                        </div>
                        <div className="button-wrapper">
                            <AldiButton
                                kind="primary"
                                type="submit"
                                className={`${loading ? "loadingIndicator" : ""} button-row-button`}
                                loading={loading}
                                disabled={disabled || disabledSubmit}
                                data-testid={"submit-modal"}>{textSubmitButton}
                            </AldiButton>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default AldiModal;
