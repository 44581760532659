import './CreateTaskModal.scss';
import { useState, useId, useMemo } from 'react';
import { DeliveryType, Task, Temperature } from '@kehrwasser/aldi-sued-dtm-openapi';
import FormMessage from '../../../../elements/formmessage/FormMessage';
import AldiModal from '../../../../elementsAldi/modal/Modal';
import AldiCheckbox from '../../../../elementsAldi/checkbox/AldiCheckbox';
import { tasksProvider } from '../../../../../shared/tasks.service';
import { notify } from '../../../../../shared/helpers';
import usePromise from '../../../../../hooks/usePromise';
import AldiButton from '../../../../elementsAldi/button/AldiButton';

type Combination = [DeliveryType, Temperature];

export default function CreateTaskModal(props: {
    modal: any,
    tasks: Task[],
    onSuccess: () => void,
}) {

    const { modal, tasks, onSuccess } = props;

    const possibleCombinations: Combination[] = [
        ['home-delivery', 'am'],
        ['home-delivery', 'ch'],
        ['home-delivery', 'fr'],
        ['click-and-collect', 'am'],
        ['click-and-collect', 'fr'],
    ]

    /* State Definitions */
    const [values, setValues] = useState<[string, string][]>([])
    const [invalidForm, setInvalidForm] = useState(false)
    const [emptyForm, setEmptyForm] = useState(false)
    const promiseSubmit = usePromise();
    const componentId = useId();

    const choosableCombinations = useMemo(() => {
        function isNotRepresented([deliveryType, temperature]: Combination): boolean {
            return tasks.find(task => task.deliveryType === deliveryType && task.temperature === temperature) === undefined
        }
        return possibleCombinations.filter(isNotRepresented);
    }, [tasks])

    /* Communication */

    /* Helpers */
    const close = () => {
        modal.hide();
        setEmptyForm(false);
    }

    const makeCombinationLabel = ([deliveryType, temperature]: Combination): string => {
        if (deliveryType === DeliveryType.ClickAndCollect && temperature === Temperature.Am) {
            return `CC Ambient/Chilled`
        }
        if (deliveryType === DeliveryType.ClickAndCollect && temperature === Temperature.Fr) {
            return `CC Frozen`
        }
        if (deliveryType === DeliveryType.HomeDelivery && temperature === Temperature.Am) {
            return `HD Ambient`
        }
        if (deliveryType === DeliveryType.HomeDelivery && temperature === Temperature.Ch) {
            return `HD Chilled`
        }
        if (deliveryType === DeliveryType.HomeDelivery && temperature === Temperature.Fr) {
            return `HD Frozen`
        }
        console.debug(`invalid combination: ${deliveryType} - ${temperature}`);
        return "";
    };

    /* Handlers */
    const handleSubmit = () => {
        if (values.length === 0) {
            setEmptyForm(true);
        } else {
            promiseSubmit.setPromise(Promise.all(values.map(combination => tasksProvider.create(combination[1] as Temperature, combination[0])))
                .then(() => {
                    onSuccess();
                    close();
                })
                .catch(error => {
                    notify('Fehler bei der Erstellung neuer Picking-Aufgaben.');
                    console.debug(error);
                }))
        }
    };

    const handleChangeValues = (combination: [string, string]) => {
        setValues(values => {
            if (undefined === values.find(value => value[0] === combination[0] && value[1] === combination[1])) {
                return [...values, combination];
            } else {
                return values.filter(value => value[0] !== combination[0] || value[1] !== combination[1])
            }
        })
    };

    const handleSelectAll = () => {
        setValues(choosableCombinations);
    }

    const handleDeselectAll = () => {
        setValues([]);
    }

    /* Renderers */

    return (
        <AldiModal
            onCancel={close}
            title="Picking-Aufgaben generieren"
            subtitle="Bitte wählen Sie den entsprechenden Service/Temperaturbereich aus."
            onSubmit={handleSubmit}
            textCancelButton="Abbrechen"
            textSubmitButton="Generieren"
            disabledSubmit={choosableCombinations.length == 0}
            disabled={promiseSubmit.isLoading}
            loading={promiseSubmit.isLoading}>

            <p className="heading">{"Service/Temperaturbereich"}</p>

            {possibleCombinations.map((combination: Combination) => {
                const disabled = choosableCombinations.find(value => value[0] === combination[0] && value[1] === combination[1]) == undefined
                    || promiseSubmit.isLoading;
                const classes = disabled
                    ? ["checkbox-label", "disabled"]
                    : ["checkbox-label"]
                const key = combination.join('-');
                const checkboxId = componentId + key;
                return (
                    <div>
                        <AldiCheckbox
                            id={checkboxId}
                            key={key}
                            checked={values.find(value => value[0] === combination[0] && value[1] === combination[1]) !== undefined}
                            className="checkbox-wrapper"
                            onChange={() => handleChangeValues(combination)}
                            disabled={disabled} />
                        <label className={classes.join(" ")} htmlFor={checkboxId}>{makeCombinationLabel(combination)}</label>
                    </div>
                )
            })}
            <AldiButton kind="secondary" disabled={values.length === choosableCombinations.length} onClick={handleSelectAll}>{"+ Alle auswählen"}</AldiButton>
            <AldiButton kind="secondary" disabled={values.length === 0} onClick={handleDeselectAll}>{"- Alle abwählen"}</AldiButton>

            {choosableCombinations.length == 0 && <FormMessage title="Tasks bereits angelegt" message="Heute können keine weiteren Tasks angelegt werden." />}
            {emptyForm && <FormMessage title="Nichts ausgewählt" message="Bitte wähle mindestens einen Temperaturbereich aus, um zu speichern." />}
        </AldiModal >
    )
}
