import './CleveronModal.scss';
import { useState, useEffect, ChangeEvent } from 'react';
import { cleveronsProvider } from '../../../../../shared/cleverons.service';
import { Cleveron } from '@kehrwasser/aldi-sued-dtm-openapi';
import Validator from '../../../../../shared/validation';
import FormMessage from '../../../../elements/formmessage/FormMessage';
import AldiModal from '../../../../elementsAldi/modal/Modal';
import AldiInput from '../../../../elementsAldi/input/AldiInput';
import usePromise from '../../../../../hooks/usePromise';

export type CleveronModalProps = {
    modal: any,
    cleveron: { cleveron: Cleveron, index: number } | null,
    cleverons: Cleveron[],
    setCleverons?: (value: Cleveron[]) => void,
    onDelete?: () => void,
}

export default function CleveronModal(props: CleveronModalProps) {

    const {
        modal,
        cleverons = [],
        setCleverons,
        cleveron,
        onDelete,
    } = props

    const initialValues = {
        id: cleverons?.length,
        street: '',
        streetNo: '',
        zip: '',
        city: '',
        capacityAmbient: '',
        bufferAmbient: '',
        capacityFrozen: '',
        bufferFrozen: '',
        groups: [''],
        active: true
    }

    const validator = new Validator()

    /* State Definitions */
    const [values, setValues] = useState<Cleveron>(initialValues)
    const [invalidForm, setInvalidForm] = useState(false)
    const [formErrors, setFormErrors] = useState({} as any)
    const promiseCleverons = usePromise();


    /* Helper */
    const allValuesValid = () => {
        let property: keyof typeof values
        for (property in values)
            if (values[property] === '')
                return false
        return true
    }

    const close = () => {
        setInvalidForm(false);
        setFormErrors({});
        setValues(initialValues);
        modal.hide();
    }

    const errorProps = (name: string, defaultText?: string) => {
        const message = formErrors[name] ?? defaultText
        return {
            helperText: message ?? '',
            error: message ? true : false
        }
    }

    /* Communication */
    useEffect(() => {
        if (cleveron) {
            setValues(cleveron.cleveron);
        } else {
            setValues({ ...initialValues, id: cleverons?.length || 0 })
        }
    }, [cleverons, cleveron]);


    /* Handlers */
    const handleChange = (value: string, event?: ChangeEvent<HTMLInputElement>) => {
        if (event) {
            setFormErrors(validator.test(event.target))
            setValues({ ...values, [event.target.name]: value })
        }
    };

    const handleChangeGroup = (value: string, index: number) => {
        const newGroups = values.groups
        newGroups.splice(index, 1, value)
        setValues({ ...values, groups: newGroups });
    };

    const handleSubmit = () => {
        if (allValuesValid()) {
            if (cleveron) {
                promiseCleverons.setPromise(
                    cleveronsProvider.update(values).then(response => {
                        let newCleverons = cleverons;
                        newCleverons.splice(cleveron.index, 1, response.data);
                        setCleverons && setCleverons(newCleverons);
                        setValues(initialValues);
                        close()
                    }))
            } else {
                promiseCleverons.setPromise(cleveronsProvider.create(values).then(response => {
                    setCleverons && setCleverons([...cleverons, response.data]);
                    setValues(initialValues);
                    close()
                }))
            }
        } else {
            setInvalidForm(true)
        }
    };

    const handleDelete = () => {
        onDelete && onDelete();
    }

    const defaultInputProps = {
        sx: {
            width: "100%",
            '& .MuiInputBase-sizeSmall': {
                height: "40px"
            },
            '& .MuiInputBase-inputSizeSmall': {
                fontSize: "14px",
            },
        },
        size: "small" as "small",
        variant: "outlined" as "outlined",
        onChange: handleChange,
        disabled: promiseCleverons.isLoading
    }

    /* Renders */
    return (
        <AldiModal
            onCancel={close}
            loading={promiseCleverons.isLoading}
            disabled={promiseCleverons.isLoading}
            enableDeleteButton={cleveron !== null}
            title={cleveron ? "Cleveron bearbeiten" : "Neuen Cleveron hinzufügen"}
            textSubmitButton={cleveron ? "Speichern" : "Erstellen"}
            textCancelButton="Abbrechen"
            textDeleteButton="Löschen"
            subtitle="Geben Sie alle notwendigen Informationen an."
            onDelete={handleDelete}
            onSubmit={handleSubmit}
            width="fit-content">

            <p className="heading">Adresse</p>

            <div className="create-cleveron-input-row">
                <div className="create-cleveron-input-wrapper">
                    <AldiInput
                        id="street"
                        name="street"
                        placeholder="Straße"
                        value={values.street}
                        type="text"
                        required
                        fullWidth
                        {...defaultInputProps}
                        {...errorProps('street')}
                    />
                </div>
                <div className="small-input-wrapper">
                    <AldiInput
                        name="streetNo"
                        id="streetNo"
                        placeholder="Nr."
                        value={values.streetNo}
                        type="text"
                        required
                        fullWidth
                        {...defaultInputProps}
                        {...errorProps('streetNo')}
                    />
                </div>
            </div>
            <div className="create-cleveron-input-row">
                <div className="small-input-wrapper">
                    <AldiInput
                        name="zip"
                        id="zip"
                        placeholder="PLZ"
                        value={values.zip}
                        type="text"
                        required
                        fullWidth
                        {...defaultInputProps}
                        {...errorProps('zip')}
                    />
                </div>
                <div className="create-cleveron-input-wrapper">
                    <AldiInput
                        name="city"
                        id="city"
                        placeholder="Stadt"
                        value={values.city}
                        type="text"
                        required
                        fullWidth
                        {...defaultInputProps}
                        {...errorProps('city')}
                    />
                </div>
            </div>
            <p className="heading">Ambient/Chilled</p>
            <div className="create-cleveron-input-row">
                <p className="input-label">Kapazität</p>
                <div className="create-cleveron-input-wrapper">
                    <AldiInput
                        name="capacityAmbient"
                        id="capacityAmbient"
                        placeholder="Anzahl von Boxen"
                        value={values.capacityAmbient}
                        type="number"
                        required
                        inputProps={{ min: 0 }}
                        fullWidth
                        {...defaultInputProps}
                        {...errorProps('capacityAmbient')}
                    />
                </div>
            </div>
            <div className="create-cleveron-input-row">
                <p className="input-label">
                    {"Puffer"}
                </p>
                <div className="create-cleveron-input-wrapper">
                    <AldiInput
                        name="bufferAmbient"
                        id="bufferAmbient"
                        placeholder="Anzahl von Boxen"
                        value={values.bufferAmbient}
                        type="number"
                        required
                        inputProps={{ min: 0 }}
                        fullWidth
                        {...defaultInputProps}
                        {...errorProps('bufferAmbient')}
                    />
                </div>
            </div>
            <p className="heading">Frozen</p>
            <div className="create-cleveron-input-row">
                <p className="input-label">Kapazität</p>
                <div className="create-cleveron-input-wrapper">
                    <AldiInput
                        name="capacityFrozen"
                        id="capacityFrozen"
                        placeholder="Anzahl von Boxen"
                        value={values.capacityFrozen}
                        type="number"
                        required
                        inputProps={{ min: 0 }}
                        fullWidth
                        {...defaultInputProps}
                        {...errorProps('capacityFrozen')}
                    />
                </div>
            </div>
            <div className="create-cleveron-input-row">
                <p className="input-label">
                    {"Puffer"}
                </p>
                <div className="create-cleveron-input-wrapper">
                    <AldiInput
                        name="bufferFrozen"
                        id="bufferFrozen"
                        placeholder="Anzahl von Boxen"
                        value={values.bufferFrozen}
                        type="number"
                        required
                        fullWidth
                        inputProps={{ min: 0 }}
                        {...defaultInputProps}
                        {...errorProps('bufferFrozen')}
                    />
                </div>
            </div>

            {invalidForm && <FormMessage title="Formular unvollständig" message="Bitte prüfen und korrigieren Sie alle Felder." />}

        </AldiModal>
    )
}
