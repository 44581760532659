import KWWeekRangePicker, { KWWeekRangePickerProps } from "../../elements/weekrangepicker/KWWeekRangePicker";
import "./WeekRangePicker.scss";

function WeekRangePicker(props: KWWeekRangePickerProps) {
    var className;
    if (props.className) {
        className = props.className + " aldi-week-range-picker";
    } else {
        className = "aldi-week-range-picker";
    }
    return (<KWWeekRangePicker {...props} className={className} />)
}

export default WeekRangePicker;
