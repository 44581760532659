import { InputAdornment, InputProps, TextField, TextFieldProps } from '@mui/material';
import { ChangeEvent } from 'react';
import AldiButton from '../button/AldiButton';
import './AldiInput.scss';

export type AldiInputProps = {
    helperText?: string | null,
    error?: boolean,
    resettable?: boolean,
    onChange?: (value: string, event?: ChangeEvent<HTMLInputElement>) => void,
} & Omit<TextFieldProps, "onChange">;

export default function AldiInput({
    helperText = null,
    error = false,
    resettable = false,
    onChange,
    type = "text",
    size = "small",
    InputProps,
    ...textFieldProps
}: AldiInputProps) {
    const classes = error
        ? [ "aldi-kwmc-textinput", "kwmc-error-type" ]
        : [ "aldi-kwmc-textinput" ];

    const handleChange = onChange
        ? (event: ChangeEvent<HTMLInputElement>) => onChange(event.target.value, event)
        : undefined;

    return (
        <div className={classes.join(" ")}>
            <TextField
                {...textFieldProps}
                onChange={handleChange}
                type={type}
                size={size}
                InputProps={{
                    endAdornment: resettable && (
                        <InputAdornment position="end">
                            <AldiButton className="reset-button" onClick={() => onChange && onChange("")}>
                                <i className="ri-close-circle-line icon-reset-week"></i>
                            </AldiButton>
                        </InputAdornment>
                    ),
                    ...InputProps,
                }}
            />
            {helperText && <div className="kwmc-textinput-helper-text">{helperText}</div>}
        </div>
    )
}


export const SearchAdornment = () =>
    (<InputAdornment position="start">
        <i className="ri-search-line search-icon"></i>
    </InputAdornment>);
