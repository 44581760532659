import { AxiosResponse } from "axios";
import { Cleveron } from "@kehrwasser/aldi-sued-dtm-openapi";
import { apiService } from "./apiService";

export interface CleveronFilter {
    offset?: number;
    limit?: number;
}

export const cleveronsProvider = {

    api: apiService.cleveron,

    get({ offset, limit }: CleveronFilter): Promise<AxiosResponse<Cleveron[], any>> {

        return this.api.getCleverons();

    },

    create(cleveron: Cleveron): Promise<AxiosResponse<Cleveron, any>> {

        return this.api.createCleveron(
            cleveron
        );
    },

    update(cleveron: Cleveron): Promise<AxiosResponse<Cleveron, any>> {

        return this.api.patchCleveron(
            cleveron.id,
            cleveron
        );
    },

    delete(cleveronId: number): Promise<AxiosResponse<Cleveron, any>> {

        return this.api.deleteCleveron(
            cleveronId
        );
    },
};
