import './FulfillmentClusterPage.scss';
import { clusterProvider, ClusterFilter } from '../../../../shared/clusters.service';
import { Cluster, ClusterStatus } from '@kehrwasser/aldi-sued-dtm-openapi';
import { useEffect, useState } from 'react';
import { configPaginationProvider } from '../../../../shared/configPagination.service';
import AldiSelect from '../../../elementsAldi/select/AldiSelect';
import { MenuItem } from '@mui/material';

import Table from '../../../elementsAldi/table/Table';
import TableHead from '../../../elementsAldi/table/tableHead/TableHead';
import TableBody from '../../../elementsAldi/table/tableBody/TableBody';
import TableRow from '../../../elementsAldi/table/tableRow/TableRow';
import TableCell from '../../../elementsAldi/table/tableCell/TableCell';
import TableFoot from '../../../elementsAldi/table/tableFoot/TableFoot';
import KWOverflowMenu from '../../../elements/overflowmenu/KWOverflowMenu';
import KWMenuItem from '../../../elements/menuitem/KWMenuItem';

/* Icons */
import { ReactComponent as IconDone } from '../../../../assets/images/status-icons/done.svg';
import { ReactComponent as IconLeft } from '../../../../assets/images/status-icons/left.svg';
import { ReactComponent as IconNotStarted } from '../../../../assets/images/status-icons/not-started.svg';
import { ReactComponent as IconInProgress } from '../../../../assets/images/status-icons/in-progress.svg';
import { ReactComponent as IconReadyForDelivery } from '../../../../assets/images/status-icons/ready-for-delivery.svg';
import AldiMenuItem from '../../../elementsAldi/menuitem/AldiMenuItem';
import AldiMultiSelect from '../../../elementsAldi/multiselect/AldiMultiSelect';
import Pagination, { PaginationProps } from '../../../elementsAldi/pagination/Pagination';
import AldiGeneralModal from '../../../elementsAldi/generalmodal/AldiGeneralModal';
import { notify } from '../../../../shared/helpers';
import useDebounce from '../../../../hooks/useDebounce';
import AldiFilterMenu from '../../../elementsAldi/filtermenu/AldiFilterMenu';
import { AldiChipInput } from '../../../elementsAldi/chipinput/AldiChipInput';
import AldiButton from '../../../elementsAldi/button/AldiButton';
import { useModal } from '../../../../hooks/useModal';
import usePromise, { PromiseStatus } from '../../../../hooks/usePromise';
import AldiReloadButton from '../../../elementsAldi/reloadbutton/AldiReloadButton';


export default function FulfillmentClusterPage() {

    /* State Definitions */
    const [clusters, setClusters] = useState<Cluster[]>([])
    const promiseClusters = usePromise();
    const [count, setCount] = useState(configPaginationProvider.count);
    const [page, setPage] = useState(configPaginationProvider.page);
    const [rowsPerPage, setRowsPerPage] = useState(configPaginationProvider.rowsPerPage);
    const [query, setQuery] = useState('');
    const [startTime, setStartTime] = useState<Date | null>(null);
    const [endTime, setEndTime] = useState<Date | null>(null);
    const [statusFilter, setStatusFilter] = useState<ClusterStatus[]>([]);

    const filter = {
        offset: page * rowsPerPage,
        limit: rowsPerPage,
        q: query,
        departureMin: startTime ? startTime : undefined,
        departureMax: endTime ? endTime : undefined,
        status: statusFilter
    } as ClusterFilter;

    const debouncedFilter = useDebounce(filter, 600, Object.values(filter));


    /* Effects */
    useEffect(() => {
        loadClusters();
    }, Object.values(debouncedFilter))

    /* Helpers */

    function loadClusters() {
        promiseClusters.setPromise(clusterProvider.get(filter)
            .then(response => {
                setClusters(response.data);
                setCount(parseInt(response.headers['x-total-count']));
            }).catch(error => {
                console.debug(error);
                return Promise.reject(error);
            }));
    }


    return (
        <div className='cluster-overview'>

            <FilterBar query={query} startTime={startTime} endTime={endTime} statusFilter={statusFilter}
                onQueryChange={setQuery}
                onStartTimeChange={setStartTime}
                onEndTimeChange={setEndTime}
                onStatusFilterChange={setStatusFilter} />
            <ClusterTable clusters={clusters}
                loading={promiseClusters.status}
                startTime={startTime} endTime={endTime}
                onQueryChange={setQuery}
                onClustersChange={setClusters}
                onReloadClicked={loadClusters}
                count={count}
                page={page}
                rowsPerPage={rowsPerPage}
                onPageChange={setPage}
                onRowsPerPageChange={setRowsPerPage}
            />

        </div>
    )
}

type FilterBarProps = {
    query: string,
    startTime: Date | null,
    endTime: Date | null,
    statusFilter: ClusterStatus[],
    onQueryChange: (query: string) => void,
    onStartTimeChange: (date: Date | null) => void,
    onEndTimeChange: (date: Date | null) => void,
    onStatusFilterChange: (value: ClusterStatus[]) => void,
}

function FilterBar(props: FilterBarProps) {


    const { query, startTime, endTime, statusFilter, onQueryChange, onStartTimeChange, onEndTimeChange, onStatusFilterChange } = props;

    /* Helper */

    const generateTimeBounds = (): Date[] => {
        const today = new Date();

        let indices: number[] = [];
        for (let i = 6; i <= 18; i++)
            indices.push(i);

        const timeBounds = indices.map(i => {
            today.setHours(i, 0, 0, 0);
            return new Date(today);
        })

        return timeBounds;
    };

    const isDefaultValues = () => query === "" && startTime === null && endTime === null && statusFilter.length === 0;

    /* Handler */

    const handleResetAll = () => {
        onQueryChange("");
        onStartTimeChange(null);
        onEndTimeChange(null);
        onStatusFilterChange([]);
    }

    /* Render */

    const renderTimeOptions = (date: Date, disabled: boolean) => {
        return (
            <AldiMenuItem key={date.getTime()} value={date.getTime()} disabled={disabled}>
                {date.toLocaleTimeString('de-DE')}
            </AldiMenuItem>
        )
    };

    const renderStartTimeOptions = () => {
        return generateTimeBounds().map(date => {
            let disabled = false;
            if (endTime && date >= endTime) {
                disabled = true;
            }
            return renderTimeOptions(date, disabled)
        });
    }

    const renderEndTimeOptions = () => {
        return generateTimeBounds().map(date => {
            let disabled = false;
            if (startTime && date <= startTime) {
                disabled = true;
            }
            return renderTimeOptions(date, disabled)
        });
    }

    return (<div className="filters">
        <div className="header-title">
            {"Cluster-Übersicht"}
        </div>
        <div className="placeholder"></div>

        <AldiFilterMenu selected={!isDefaultValues()} classNamePopover="filter-menu">
            <div className="aldi-box filter">
                <AldiSelect
                    resettable
                    value={startTime?.getTime() ?? ''}
                    onChange={(time: number | "") => onStartTimeChange(time === "" ? null : new Date(time))}
                    onReset={() => onStartTimeChange(null)}
                    label="Startzeit"
                    renderValue={() => startTime && startTime.toLocaleTimeString("en-US")}>
                    {renderStartTimeOptions()}
                </AldiSelect>
            </div>
            <div className="aldi-box filter">
                <AldiSelect
                    resettable
                    value={endTime?.getTime() ?? ''}
                    onChange={(time: number | "") => onEndTimeChange(time === "" ? null : new Date(time))}
                    onReset={() => onEndTimeChange(null)}
                    label="Endzeit"
                    renderValue={() => endTime && endTime.toLocaleTimeString("en-US")}>
                    {renderEndTimeOptions()}
                </AldiSelect>
            </div>
            <div className="aldi-box filter" data-testid="status-filter">
                <AldiMultiSelect
                    resettable
                    value={statusFilter}
                    onChange={onStatusFilterChange}
                    onReset={() => onStatusFilterChange([])}
                    label="Status">

                    {(Object.values(ClusterStatus) as Array<ClusterStatus>).map(status => (
                        <MenuItem key={status} value={status}>
                            {clusterStatusLabel(status)}
                        </MenuItem>
                    ))}
                </AldiMultiSelect>
            </div>
            <div className="aldi-box filter">
                <AldiButton kind="tertiary" disabled={isDefaultValues()} onClick={handleResetAll}>
                    {"Filter zurücksetzen"}
                </AldiButton>
            </div>
        </AldiFilterMenu>

        <div className="small-fixed-gap" />

        <div className="aldi-box filter">
            <AldiChipInput
                placeholder="Cluster ID"
                onChange={values => onQueryChange(values.join(" "))}
                value={query.split(" ").filter(term => term !== "")}
                resettable></AldiChipInput>
        </div>
    </div>
    )
}

function clusterStatusLabel(status: ClusterStatus): string {
    switch (status) {
        case ClusterStatus.NotStarted: return "Nicht gestartet";
        case ClusterStatus.InProgress: return "In Bearbeitung";
        case ClusterStatus.Done: return "Vorbereitungen abgeschlossen";
        case ClusterStatus.ReadyForDelivery: return "Bereit zur Auslieferung";
        case ClusterStatus.Left: return "In Auslieferung";
    }
}

function clusterStatusIcon(status: ClusterStatus): JSX.Element {
    const className = "status-icon";
    switch (status) {
        case ClusterStatus.Done:
            return <IconDone className={className} />;
        case ClusterStatus.Left:
            return <IconLeft className={className} />;
        case ClusterStatus.NotStarted:
            return <IconNotStarted className={className} />;
        case ClusterStatus.InProgress:
            return <IconInProgress className={className} />;
        case ClusterStatus.ReadyForDelivery:
            return <IconReadyForDelivery className={className} />;
    }
};



type ClusterTableProps = {
    clusters: Cluster[],
    loading: PromiseStatus,
    startTime: Date | null,
    endTime: Date | null,
    onQueryChange: (query: string) => void,
    onClustersChange: (clusters: Cluster[]) => void,
    onReloadClicked: () => void,
} & PaginationProps;

function ClusterTable(props: ClusterTableProps) {
    const { clusters, loading, startTime, endTime, onQueryChange, onClustersChange, onReloadClicked, ...paginationProps } = props;

    const startDeliveryModal = useModal();
    const [selectedCluster, setSelectedCluster] = useState<null | Cluster>(null);
    const promiseClusterUpdate = usePromise();


    /* Effects */

    useEffect(() => {
        startDeliveryModal.setModal(<AldiGeneralModal
            confirming={promiseClusterUpdate.isLoading}
            disabled={promiseClusterUpdate.isLoading}
            textCancelButton="Abbrechen"
            onCancel={startDeliveryModal.hide}
            textConfirmButton="Starten"
            onConfirm={() => {
                if (selectedCluster) {
                    selectedCluster.status = ClusterStatus.ReadyForDelivery;
                    promiseClusterUpdate.setPromise(clusterProvider.update(selectedCluster.id, selectedCluster)
                        .then(response => {
                            const newClusters = clusters.map(el => {
                                if (el.id === selectedCluster.id) {
                                    return response.data
                                }
                                return el;
                            })
                            onClustersChange(newClusters);
                            startDeliveryModal.hide();
                        }).catch(error => {
                            console.debug(error);
                            notify("Es ist ein Fehler aufgetreten.");
                        }))
                }
            }}>
            {"Soll die Auslieferung gestartet werden?"}
        </AldiGeneralModal>);
    }, [selectedCluster, promiseClusterUpdate.isLoading]);

    /* Helper */
    const isOtherThanDone = (status: ClusterStatus): boolean => {
        return (status !== 'done');
    }


    /* Handlers */


    /* Renders */
    const renderRow = (cluster: Cluster) => {

        let rowStyle = "";
        if (cluster.status === ClusterStatus.Done || cluster.status === ClusterStatus.Left || cluster.status === ClusterStatus.ReadyForDelivery) {
            rowStyle = "table-row-complete";
        }

        return (
            <TableRow key={cluster.id} data-testid='table-row' className={rowStyle}>
                <TableCell className='first-cell title-column-cell'>{cluster.id}</TableCell>
                <TableCell>{new Date(cluster.departure).toLocaleTimeString('de-DE')}</TableCell>
                <TableCell className='progress-cell cell-group-start'>{cluster.progressAMPicking}%</TableCell>
                <TableCell className='progress-cell'>{cluster.progressAMCrossdock}%</TableCell>
                <TableCell className='progress-cell cell-group-start'>{cluster.progressCHPicking}%</TableCell>
                <TableCell className='progress-cell'>{cluster.progressCHCooling}%</TableCell>
                <TableCell className='progress-cell'>{cluster.progressCHCrossdock}%</TableCell>
                <TableCell className='progress-cell cell-group-start'>{cluster.progressFRPicking}%</TableCell>
                <TableCell className='progress-cell'>{cluster.progressFRCooling}%</TableCell>
                <TableCell className='progress-cell cell-group-end'>{cluster.progressFRCrossdock}%</TableCell>
                <TableCell className='status'>
                    <span className='wrapper'>
                        {clusterStatusIcon(cluster.status)}
                        {clusterStatusLabel(cluster.status)}
                    </span>
                </TableCell>
                <TableCell className='last-cell status-column'>
                    <KWOverflowMenu className="menu" disabled>
                        <KWMenuItem
                            onClick={() => {
                                setSelectedCluster(cluster);
                                startDeliveryModal.show();
                            }}
                            disabled={isOtherThanDone(cluster.status)}>
                            {clusterStatusLabel(ClusterStatus.ReadyForDelivery)}
                        </KWMenuItem>
                    </KWOverflowMenu>
                </TableCell>
            </TableRow>
        );
    }


    return (

        <div className="aldi-box aldi-general-table-wrapper">
            <Table>
                <TableHead loading={loading === PromiseStatus.Pending}>
                    <TableRow>
                        <TableCell>{"Cluster ID"}</TableCell>
                        <TableCell>{"Abfahrt"}</TableCell>
                        <TableCell subtext="Picking">Ambient</TableCell>
                        <TableCell subtext="Crossdock" />
                        <TableCell subtext="Picking">Chilled</TableCell>
                        <TableCell subtext="Cooling" />
                        <TableCell subtext="Crossdock" />
                        <TableCell subtext="Picking">Frozen</TableCell>
                        <TableCell subtext="Cooling" />
                        <TableCell subtext="Crossdock" />
                        <TableCell colSpan={2} className='status-column'>{"Status"}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {loading === PromiseStatus.Failed && <TableRow>
                        <TableCell colSpan={11}><AldiReloadButton onClick={onReloadClicked}/></TableCell>
                    </TableRow>}
                    {clusters.map(cluster => renderRow(cluster))}
                </TableBody>
                <TableFoot>
                    <TableRow>
                        <Pagination
                            {...paginationProps}
                        />
                    </TableRow>
                </TableFoot>
            </Table>
        </div>
    )
}
