import './TimetableHomeDeliveryPage.scss';
import { useEffect, useState } from 'react';
import Table from '../../../elementsAldi/table/Table';
import TableHead from '../../../elementsAldi/table/tableHead/TableHead';
import TableBody from '../../../elementsAldi/table/tableBody/TableBody';
import TableRow from '../../../elementsAldi/table/tableRow/TableRow';
import TableCell from '../../../elementsAldi/table/tableCell/TableCell';
import TableFoot from '../../../elementsAldi/table/tableFoot/TableFoot';
import { deliveryTimeframesProvider, deliveryProvider } from '../../../../shared/homeDelivery.service';
import TimetableModal from './mainModal/TimetableModal';
import { getTimeStringFromDateTime, skipSunday, notify, filterUndefined } from '../../../../shared/helpers';
import { config } from '../../../../shared/configuration';
import { AxiosResponse } from 'axios';
import { WeekRange } from '../../../elements/weekrangepicker/types';
import { DeliveryTimeframe, Period, Week as WeekApi } from '@kehrwasser/aldi-sued-dtm-openapi';
import { DateTime, WeekdayNumbers } from '../../../../shared/datetime/DateTime';
import AldiButton from '../../../elementsAldi/button/AldiButton';
import { Interval } from '../../../../shared/datetime/Interval';
import AldiGeneralModal from '../../../elementsAldi/generalmodal/AldiGeneralModal';
import usePromise, { PromiseStatus } from '../../../../hooks/usePromise';
import WeekRangeInput, { getDefaultWeekRange } from '../../../elementsAldi/weekrangeinput/WeekRangeInput';
import { weekdaysFor } from '../../clickAndCollect/timetableCleverons/timetable';
import { useModal } from '../../../../hooks/useModal';
import AldiReloadButton from '../../../elementsAldi/reloadbutton/AldiReloadButton';


export const DEFAULT_START_HOUR = 12;
export const DEFAULT_END_HOUR = 22;
const BUFFER_TIME = { minutes: 30 };
const MINIMUM_GAP = config.MINIMUM_GAP_FOR_DELIVERY_TIMEFRAME_MINUTES;


type TimetableHomeDeliveryPageProps = {
    currentTime?: DateTime,
}

export default function TimetableHomeDeliveryPage({
    currentTime = DateTime.now(),
}: TimetableHomeDeliveryPageProps) {

    /* Helpers */
    const rowHeight = 70; // 3rem + 1px tablerow-height

    const weekDays = (day: DateTime): DateTime[] => {
        let startOfWeek = day.startOf("week");
        let endOfWeek = day.endOf("week");
        let week: Interval = Interval.fromDateTimes(startOfWeek, endOfWeek);
        let days: DateTime[] = week.splitBy({ days: 1 }).map(dayInterval => dayInterval.start);
        return days;
    }

    const times = ["12:00", "13:00", "14:00", "15:00", "16:00", "17:00", "18:00", "19:00", "20:00", "21:00"];

    const getStartDate = (date: DateTime): DateTime => {
        return skipSunday(date).startOf("week");
    };

    const getCurrentWeekNumber = () => {
        const currentDate = new Date();
        const startDate = new Date(currentDate.getFullYear(), 0, 1);
        const timeBetween = currentDate.getTime() - startDate.getTime();
        const days = Math.floor(timeBetween / (24 * 60 * 60 * 1000));

        return Math.ceil(days / 7);
    }

    const getTileHeight = (startTime: DateTime, endTime: DateTime) => {
        const difference = endTime.toMillis() - startTime.toMillis();  // duration in milliseconds
        if (difference > 0) {
            return difference / (60 * 60 * 1000) * rowHeight;
        }
        return 0;
    }

    const getBackgroundColorTimeframe = (index: number) => {
        switch (index) {
            case 0:
                return "#92D050";
            case 1:
                return "#FAB400";
            case 2:
                return "#222C78";
            default:
                return "#222C78";
        }
    }

    const isHoliday = (index: number) => {
        if (holidaysThisWeek.includes(weekDays(startDate)[index].weekday)) {
            return true;
        }
        return false;
    };

    const isButtonDisabled = (dayIndex: number) => {
        if (!config.TIMETABLE_EDIT_AVOIDANCE_ENABLED)
            return false

        const date = days ? days[dayIndex] : currentTime;
        return date <= currentTime.startOf("day").plus({ days: config.DELAY_DAYS_TO_EDIT_TIMETABLE })

    };

    /* State Definitions */
    const [deliveryTimeframes, setDeliveryTimeframes] = useState<DeliveryTimeframe[][]>(Array.from({ length: 7 }, () => []));
    const promiseDeliveryTimeframes = usePromise();
    const [day, setDay] = useState<DateTime>(getStartDate(currentTime));
    /* weekrange picker state */
    const [weekRange, setWeekRange] = useState<WeekRange>(getDefaultWeekRange(currentTime));
    const [weekRangePickerFocus, setWeekRangePickerFocus] = useState<boolean>(false);
    // start date of currently selected week
    const startDate: DateTime = weekRange.startWeek
        ? DateTime.fromObject(weekRange.startWeek).startOf("week")
        : DateTime.fromObject(getDefaultWeekRange(currentTime).startWeek).startOf("week");
    // end date of currently selected week
    const endDate: DateTime = startDate.endOf("week");
    const days = weekdaysFor(startDate);
    /* modal state */
    const mainModal = useModal();
    const [holidaysThisWeek, setHolidaysThisWeek] = useState<WeekdayNumbers[]>([]);
    const [editIndex, setEditIndex] = useState<number>(0);
    const [editMode, setEditMode] = useState<"edit" | "create">("create");
    const [editMinTime, setEditMinTime] = useState<DateTime>(DateTime.now());
    const [editMaxTime, setEditMaxTime] = useState<DateTime>(DateTime.now());
    const promiseFormSubmission = usePromise();

    const applyWeekModal = useModal();
    const promiseApplyingWeek = usePromise();

    /* Communication */
    useEffect(() => {
        loadDeliveryTimeframes();
    }, [weekRange.startWeek]);

    useEffect(() => {
        mainModal.setModal(
            <TimetableModal
            modal={mainModal}
            deliveryTimeframes={deliveryTimeframes[day.weekday - 1]}
            onChange={handleDeliveryTimeframesChange}
            onCancel={mainModal.hide}
            onDelete={() => {
                setDeliveryTimeframes(deliveryTimeframes.map((original, index) => {
                    let dayIndex = day.weekday - 1;
                    if (index === dayIndex) {
                        return [...original.slice(0, editIndex!), ...original.slice(editIndex! + 1)]
                    } else {
                        return original;
                    }
                }));
            }}
            isLoading={promiseFormSubmission.isLoading}
            editIndex={editIndex}
            editMode={editMode}
            editMinTime={editMinTime}
            editMaxTime={editMaxTime}
            />
        );
    }, [day, editIndex]);

    useEffect(() => {
        if (weekRange.startWeek && weekRange.endWeek) {
            applyWeekModal.setModal(<AldiGeneralModal
                disabled={promiseApplyingWeek.isLoading}
                confirming={promiseApplyingWeek.isLoading}
                textCancelButton="Abbrechen"
                onCancel={applyWeekModal.hide}
                textConfirmButton="Anwenden"
                onConfirm={() => {
                    if (weekRange.startWeek && weekRange.endWeek) {
                        const sourceWeekYear = weekRange.startWeek.weekYear;
                        const sourceWeekNumber = weekRange.startWeek.weekNumber;
                        const promises: Promise<AxiosResponse<WeekApi, any>>[] = Interval.fromDateTimes(
                            DateTime.fromObject(weekRange.startWeek).plus({weeks: 1}),
                            DateTime.fromObject(weekRange.endWeek).endOf("week"),
                        ).splitBy({ weeks: 1 }).map(interval => interval.start).map(week =>
                            deliveryProvider.updateDeliveryWeek(week.weekYear, week.weekNumber, sourceWeekYear, sourceWeekNumber));

                        promiseApplyingWeek.setPromise(Promise.all(promises)
                            .then((response: any) => {
                                notify("Alle Lieferzeitfenster wurden erfolgreich angepasst.");
                                applyWeekModal.hide();
                            })
                            .catch(error => {
                                console.debug(error);
                                notify(`Ein Fehler ist aufgetreten.`);
                            }))
                    }
                }}>
                <h2>{`Lieferzeiträume auf weitere Wochen anwenden`}</h2>
                <p>{`Die Inhalte der angezeigten Woche werden auf die Kalenderwochen ${weekRange.startWeek.weekNumber} bis ${weekRange.endWeek.weekNumber} übertragen. Vorhandene Wochenkonfigurationen werden dabei überschrieben.`}</p>
                <p>{`Sollen die Änderungen angewendet werden?`}</p>
            </AldiGeneralModal>)
        } else {
            applyWeekModal.setModal(null);
        }
    }, [weekRange, promiseApplyingWeek.isLoading]);

    /* Helpers */
    function loadDeliveryTimeframes() {
        promiseDeliveryTimeframes.setPromise(deliveryTimeframesProvider.get()
            .then(async response => {
                // get holidays
                await deliveryTimeframesProvider.getHolidays(getCurrentWeekNumber()).then(response => {
                    const holidays = response.data;

                    let holidayWeekdays: WeekdayNumbers[] = holidays.flatMap(holiday => Interval.fromDateTimes(
                        DateTime.fromISO(holiday.start).startOf("day"),
                        DateTime.fromISO(holiday.end).endOf("day"),
                    ).splitBy({ days: 1 }).map(dayInterval => dayInterval.start.weekday));
                    holidayWeekdays = Array.from(new Set(holidayWeekdays).values()).sort();
                    setHolidaysThisWeek(holidayWeekdays);
                });
                const weekInterval = Interval.fromDateTimes(startDate, endDate);
                const deliveryTimeframesThisWeek = response.data.filter(timeframe => (
                    weekInterval.contains(DateTime.fromISO(timeframe.periods[0].start))))
                const deliveryTimeframesPerDayArray = days.map((day: DateTime) =>
                    deliveryTimeframesThisWeek.filter((timeframe: DeliveryTimeframe) => DateTime.fromISO(timeframe.periods[0].start).weekday === day.weekday));

                setDeliveryTimeframes(deliveryTimeframesPerDayArray);
            }).catch(error => {
                console.debug(error);
                return Promise.reject(error);
            }));
    }

    /* Handlers */
    const handleDisabledDayClick = () => {
        notify(`Dieser Tag kann nicht mehr bearbeitet werden.`);
    };

    const handleAddButtonClick = (dayIndex: number, editIndex: number) => {
        const newDay = startDate.plus({ days: dayIndex });
        const timeframes = deliveryTimeframes[dayIndex];
        const defaultMinTime = newDay.plus({ hours: DEFAULT_START_HOUR });
        const defaultMaxTime = newDay.plus({ hours: DEFAULT_END_HOUR });
        setDay(newDay);
        setEditMode("create");
        setEditIndex(editIndex);
        if (editIndex === 0) {
            setEditMinTime(defaultMinTime)
        } else {
            setEditMinTime(deliveryTimeframeInterval(timeframes[editIndex - 1])?.end.plus(BUFFER_TIME) ?? defaultMinTime);
        }
        if (editIndex === timeframes.length) {
            setEditMaxTime(defaultMaxTime)
        } else {
            setEditMaxTime(deliveryTimeframeInterval(timeframes[editIndex])?.start.minus(BUFFER_TIME) ?? defaultMaxTime);
        }
        mainModal.show();
    };

    const handleEditButtonClick = (dayIndex: number, editIndex: number) => {
        const newDay = startDate.plus({ days: dayIndex });
        const timeframes = deliveryTimeframes[dayIndex];
        const defaultMinTime = newDay.plus({ hours: DEFAULT_START_HOUR });
        const defaultMaxTime = newDay.plus({ hours: DEFAULT_END_HOUR });
        setDay(startDate.plus({ days: dayIndex }));
        setEditMode("edit");
        setEditIndex(editIndex);
        if (editIndex === 0) {
            setEditMinTime(defaultMinTime);
        } else {
            setEditMinTime(deliveryTimeframeInterval(timeframes[editIndex - 1])?.end.plus(BUFFER_TIME) ?? defaultMinTime);
        }
        if (editIndex + 1 === timeframes.length) {
            setEditMaxTime(defaultMaxTime)
        } else {
            setEditMaxTime(deliveryTimeframeInterval(timeframes[editIndex + 1])?.start.minus(BUFFER_TIME) ?? defaultMaxTime);
        }
        mainModal.show();
    };

    const handleClickApplyTimeframes = () => {
        if (weekRange.startWeek && weekRange.endWeek) {
            applyWeekModal.show();
        } else {
            notify(`Es wurden keine Werte geändert.`);
        }
    };

    const handleChangeOfWeekRangePicker = (value: WeekRange) => {
        setWeekRange(value)
        if (value.startWeek && value.endWeek) {
            setWeekRangePickerFocus(false)
        }
    }

    const handleDeliveryTimeframesChange = (newDeliveryTimeframe: DeliveryTimeframe, editMode: "create" | "edit", editIndex: number) => {
        const dayIndex = day.weekday - 1;
        if (editMode === "create") {
            // TOTO revise
            promiseFormSubmission.setPromise(deliveryTimeframesProvider.create(newDeliveryTimeframe)
                .then(response => {
                    const old = deliveryTimeframes[dayIndex];
                    const newDeliveryTimeframeDay = [...old.slice(0, editIndex), newDeliveryTimeframe, ...old.slice(editIndex)];
                    const newDeliveryTimeframes = deliveryTimeframes;
                    newDeliveryTimeframes.splice(dayIndex, 1, newDeliveryTimeframeDay)
                    setDeliveryTimeframes(newDeliveryTimeframes);
                    mainModal.hide();
                }).catch(error => {
                    notify(`Das Lieferzeitfenster konnte nicht angelegt werden.`);
                    console.debug(error);
                }));
        } else {
            promiseFormSubmission.setPromise(deliveryTimeframesProvider.update(newDeliveryTimeframe)
                .then(response => {
                    let newDeliveryTimeframeDay = deliveryTimeframes[dayIndex].map((original: DeliveryTimeframe, index: number) => {
                        if (index === editIndex) {
                            return newDeliveryTimeframe;
                        } else {
                            return original
                        }
                    });
                    const newDeliveryTimeframes = deliveryTimeframes;
                    newDeliveryTimeframes.splice(dayIndex, 1, newDeliveryTimeframeDay)
                    setDeliveryTimeframes(newDeliveryTimeframes);
                    mainModal.hide();
                }).catch(error => {
                    notify(`Das Lieferzeitfenster konnte nicht angelegt werden.`);
                    console.debug(error);
                }));
        }
    }

    /* Renders */
    const renderRow = (time: string) => {
        return (
            <TableRow key={time}>
                <TableCell className="time-entry">{time}</TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
            </TableRow>
        );
    }

    function TimeFrameEditButton(props: { index: number, timeframe: DeliveryTimeframe, onClick: () => void}) {
        const name = props.timeframe.name;
        const startTime: DateTime = DateTime.fromISO(props.timeframe.periods[0].start);
        const startTimeToDisplay = getTimeStringFromDateTime(startTime);
        const endTime: DateTime = DateTime.fromISO(props.timeframe.periods[props.timeframe.periods.length - 1].end);
        const endTimeToDisplay = getTimeStringFromDateTime(endTime);
        return (<div className="timeframe"
            onClick={() => props.onClick()}
            style={{
                backgroundColor: getBackgroundColorTimeframe(props.index),
            }}>
            <p className="timeframe-name">{name}</p>
            <p className="timeframe-time">{startTimeToDisplay} - {endTimeToDisplay}</p>
        </div>)
    }

    function TimeFrameAddButton(props: { onClick: () => void }) {
        return (<AldiButton className="timeframe-add-button" onClick={() => props.onClick()}>
            <p className="timeframe-add-button-text">
                {"Auslieferzeitfenster hinzufügen"}
            </p>
        </AldiButton>)
    }

    function TimeFrameHolidayButton(props: { onClick: () => void }) {
        return (
            <AldiButton className="disabled-button" onClick={() => props.onClick()}>
                <p className="disabled-button-text">
                    {"Feiertag -"}
                </p>
                <p className="disabled-button-text-small">
                    {"Keine Auslieferung"}
                </p>
                <i className="ri-forbid-line no-access-icon"></i>
            </AldiButton>
        )
    }

    function TimeFrameDisabledButton(props: { onClick: () => void }) {
        return (
            <AldiButton className="disabled-button" onClick={() => props.onClick()}>
                <p className="disabled-button-text">
                    {"Bearbeitung nicht mehr möglich"}
                </p>
                <i className="ri-forbid-line no-access-icon"></i>
            </AldiButton>
        )
    }

    function TimeFrameBox(props: {minTime: DateTime, maxTime: DateTime, children: React.ReactNode}) {
        const height = getTileHeight(props.minTime, props.maxTime);
        const top = getTileHeight(props.minTime.startOf("day").plus({ hours: DEFAULT_START_HOUR }), props.minTime);

        return <div className="timeframe-day-box" style={{ height: `${height}px`, top: `${top}px` }}>
            {props.children}
        </div>
    }

    function BufferZone(_props: {}) {
        return (<div className="buffer">
            <p className="buffer-text">{"mindestens 30 Minuten Puffer"}</p>
        </div>)
    }

    function DayColumn(props: {dayIndex: number, timeframesPerDay: DeliveryTimeframe[]}) {
        
        const reservedTimes = filterUndefined(props.timeframesPerDay.map(tf => deliveryTimeframeInterval(tf)));

        const minTime = days[props.dayIndex].startOf("day").plus({ hours: DEFAULT_START_HOUR });
        const maxTime = days[props.dayIndex].startOf("day").plus({ hours: DEFAULT_END_HOUR });

        const column = makeTimeColumn(reservedTimes, minTime, maxTime);

        if (isHoliday(props.dayIndex)) {
            return (
                <div className="timeframe-day-wrapper">
                    <TimeFrameBox minTime={minTime} maxTime={maxTime}>
                        <TimeFrameHolidayButton onClick={() => handleDisabledDayClick()}/>
                    </TimeFrameBox>
                </div>
            )
        }

        if (isButtonDisabled(props.dayIndex)) {
            return (
                <div className="timeframe-day-wrapper">
                    <TimeFrameBox minTime={minTime} maxTime={maxTime}>
                        <TimeFrameDisabledButton onClick={() => handleDisabledDayClick()}/>
                    </TimeFrameBox>
                </div>
            )
        }

        if (props.dayIndex === 6) {
            return <div className="timeframe-day-wrapper"></div>;
        }

        return (<div className="timeframe-day-wrapper">
            {column.map(column => {
                switch (column.type) {
                    case "buffer":
                        return <TimeFrameBox minTime={column.interval.start} maxTime={column.interval.end}>
                            <BufferZone />
                        </TimeFrameBox>;
                    case "gap":
                        return <TimeFrameBox minTime={column.interval.start} maxTime={column.interval.end}>
                            <TimeFrameAddButton onClick={() => handleAddButtonClick(props.dayIndex, column.index)} />
                        </TimeFrameBox>;
                    case "timeframe":
                        if (props.dayIndex !== 6) {
                            const timeframe = props.timeframesPerDay[column.index];
                            return <TimeFrameBox minTime={column.interval.start} maxTime={column.interval.end}>
                                <TimeFrameEditButton index={column.index} timeframe={timeframe}
                                    onClick={() => handleEditButtonClick(props.dayIndex, column.index)} />
                            </TimeFrameBox>;
                        } else {
                            return <TimeFrameBox minTime={column.interval.start} maxTime={column.interval.end}>
                            </TimeFrameBox>;
                        }
                }
            })}
        </div>)
    }

    return (

        <div className='timeframe-overview'>

            <div className="container-header-week-range-picker">
                <div className="header-row">
                    <div className="header-title">
                        {"Auslieferzeitfenster"}
                    </div>
                    <div className="placeholder"></div>
                    <div className="weekrange-row">
                        <WeekRangeInput
                            currentTime={currentTime}
                            value={weekRange}
                            focus={weekRangePickerFocus}
                            onChange={handleChangeOfWeekRangePicker}
                            onFocusChange={focus => setWeekRangePickerFocus(focus)} />
                        <AldiButton onClick={handleClickApplyTimeframes} kind="primary" className="set-weeks-button">
                            {"Für diesen Zeitraum anwenden"}
                        </AldiButton>
                    </div>
                </div>
            </div>

            { promiseDeliveryTimeframes.status === PromiseStatus.Failed
            ? <AldiReloadButton onClick={loadDeliveryTimeframes} />
            :
            <div className="timeframe-table aldi-box aldi-general-table-wrapper">
                <Table className="table centered-entries">
                    <TableHead>
                        <TableRow>
                            <TableCell>Uhrzeit</TableCell>
                            <TableCell subtext={days?.[0].toJSDate().toLocaleDateString('de-DE', { year: 'numeric', month: '2-digit', day: '2-digit' }) || ""}>Montag</TableCell>
                            <TableCell subtext={days?.[1].toJSDate().toLocaleDateString('de-DE', { year: 'numeric', month: '2-digit', day: '2-digit' }) || ""}>Dienstag</TableCell>
                            <TableCell subtext={days?.[2].toJSDate().toLocaleDateString('de-DE', { year: 'numeric', month: '2-digit', day: '2-digit' }) || ""}>Mittwoch</TableCell>
                            <TableCell subtext={days?.[3].toJSDate().toLocaleDateString('de-DE', { year: 'numeric', month: '2-digit', day: '2-digit' }) || ""}>Donnerstag</TableCell>
                            <TableCell subtext={days?.[4].toJSDate().toLocaleDateString('de-DE', { year: 'numeric', month: '2-digit', day: '2-digit' }) || ""}>Freitag</TableCell>
                            <TableCell subtext={days?.[5].toJSDate().toLocaleDateString('de-DE', { year: 'numeric', month: '2-digit', day: '2-digit' }) || ""}>Samstag</TableCell>
                            <TableCell subtext={days?.[6].toJSDate().toLocaleDateString('de-DE', { year: 'numeric', month: '2-digit', day: '2-digit' }) || ""}>Sonntag</TableCell>
                        </TableRow>
                    </TableHead>
                    {/* <div className="table-head-spacer"></div> */}
                    <TableBody>
                        {times.map((time) => renderRow(time))}
                    </TableBody>
                    <TableFoot>

                    </TableFoot>
                </Table>
                <div className="timeframe-week-wrapper">
                        {deliveryTimeframes?.map((timeframesPerDay: DeliveryTimeframe[], dayIndex: number) => {
                            return (
                                <DayColumn key={dayIndex} dayIndex={dayIndex} timeframesPerDay={timeframesPerDay} />
                            )
                        })}
                </div>
            </div>}

        </div>
    )
}


type TimeTableCell = { interval: TimeInterval, type: "buffer" | "gap" | "timeframe", index: number }


type TimeInterval = { start: DateTime, end: DateTime}

function makeTimeInterval(start: DateTime, end: DateTime): TimeInterval | undefined {
    if (start < end) {
        return { start, end };
    }
}

function timeIntervalShrinkBefore(interval: TimeInterval, amount: { minutes: number}): TimeInterval | undefined {
    return makeTimeInterval(interval.start.plus(amount), interval.end);
}

function timeIntervalShrinkAfter(interval: TimeInterval, amount: { minutes: number}): TimeInterval | undefined {
    return makeTimeInterval(interval.start, interval.end.minus(amount));
}

function timeIntervalShrink(interval: TimeInterval, amount: { minutes: number }): TimeInterval | undefined {
    return makeTimeInterval(interval.start.plus(amount), interval.end.minus(amount));
}

function timeIntervalMinutes(interval: TimeInterval): number {
    return (interval.end.toMillis() - interval.start.toMillis()) / 1000 / 60;
}

function makeBufferAfter(interval: TimeInterval, bufferTime: { minutes: number }): TimeTableCell {
    return {
        type: "buffer",
        interval: {
            start: interval.end,
            end: interval.end.plus(bufferTime),
        },
        index: -1,
    }
}

function deliveryTimeframeInterval(timeframe: DeliveryTimeframe): TimeInterval | undefined {
    if (timeframe.periods.length === 0) {
        return undefined;
    }
    let start: Date = new Date(timeframe.periods[0].start);
    let end: Date = new Date(timeframe.periods[0].end);
    timeframe.periods.forEach((p: Period) => {
        let periodStart = new Date(p.start);
        let periodEnd = new Date(p.end);
        start = periodStart < start ? periodStart : start;
        end = periodEnd > end ? periodEnd : end;
    });
    return {
        start: DateTime.fromJSDate(start),
        end: DateTime.fromJSDate(end),
    }
}

export function makeTimeColumn(reservedTimes: TimeInterval[], minTime: DateTime, maxTime: DateTime): TimeTableCell[] {
    if (reservedTimes.length === 0) {
        const interval = {start: minTime, end: maxTime};
        return [{ type: "gap", interval, index: 0 }];
    } else {
        let gaps: { interval: TimeInterval, type: "buffer" | "gap" | "timeframe", index: number }[] = [];

        const preInterval = makeTimeInterval(minTime, reservedTimes[0].start);
        const preIntervalNoBuffer = preInterval ? timeIntervalShrinkAfter(preInterval, BUFFER_TIME) : undefined; 
        preInterval && preIntervalNoBuffer && timeIntervalMinutes(preInterval) >= MINIMUM_GAP
            && gaps.push({ type: "gap", interval: preIntervalNoBuffer, index: 0 });

        gaps.push({ type: "timeframe", interval: reservedTimes[0], index: 0 });

        const buffer0 = makeBufferAfter(reservedTimes[0], BUFFER_TIME);
        buffer0.interval.end < maxTime && gaps.push(buffer0);

        for (let i = 1; i < reservedTimes.length; ++i) {
            const interval = makeTimeInterval(reservedTimes[i-1].end, reservedTimes[i].start);
            const intervalNoBuffer = interval ? timeIntervalShrink(interval, BUFFER_TIME) : undefined;
            interval && intervalNoBuffer && timeIntervalMinutes(interval) >= MINIMUM_GAP
                && gaps.push({ type: "gap", interval: intervalNoBuffer, index: i });
            gaps.push({ type: "timeframe", interval:  reservedTimes[i], index: i });

            const buffer = makeBufferAfter(reservedTimes[i], BUFFER_TIME);
            buffer.interval.end < maxTime && gaps.push(buffer);
        }

        const postInterval = makeTimeInterval(reservedTimes[reservedTimes.length - 1].end, maxTime);
        const postIntervalNoBuffer = postInterval ? timeIntervalShrinkBefore(postInterval, BUFFER_TIME) : undefined; 
        postInterval && postIntervalNoBuffer && timeIntervalMinutes(postInterval) >= MINIMUM_GAP
            && gaps.push({ type: "gap", interval: postIntervalNoBuffer, index: reservedTimes.length });

        return gaps;
    }
}
