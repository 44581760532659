import './SlotModal.scss';
import { useState, useEffect } from 'react';
import { toursProvider } from '../../../../../shared/tours.service';
import { vehiclesProvider } from '../../../../../shared/vehicles.service';
import { Vehicle, Tour, Slot } from '@kehrwasser/aldi-sued-dtm-openapi';
import AldiMenuItem from '../../../../elementsAldi/menuitem/AldiMenuItem';
import AldiSelect from '../../../../elementsAldi/select/AldiSelect';
import AldiModal from '../../../../elementsAldi/modal/Modal';
import usePromise from '../../../../../hooks/usePromise';
import { UnvalidatedDeliveryTimeframe, UnvalidatedPeriod } from '../mainModal/TimetableModal';

export type SlotModalProp = {
    state: SlotModalState,
    deliveryTimeframe: UnvalidatedDeliveryTimeframe,
    onChange: (state: SlotModalState) => void,
    onSubmit?: () => void,
    onDelete?: () => void,
    onCancel?: () => void,
}

export type SlotModalState = {
    isEdit: boolean,
    rowIndex: number,
    slotId: number,
    tour?: Tour,
    invalidTour: boolean,
    vehicle?: Vehicle,
    invalidVehicle: boolean,
};

export function defaultSlotModalState(rowIndex: number, slotId: number): SlotModalState {
    return {
        isEdit: false,
        rowIndex,
        slotId,
        invalidTour: false,
        invalidVehicle: false,
    }
}

export default function SlotModal(props: SlotModalProp) {

    const { state, deliveryTimeframe, onSubmit, onDelete, onCancel, onChange } = props;

    /* State Definitions */
    const [allTours, setAllTours] = useState<Tour[]>([])
    const [allVehicles, setAllVehicles] = useState<Vehicle[]>([])
    const [tours, setTours] = useState<Tour[]>([])
    const [vehicles, setVehicles] = useState<Vehicle[]>([])
    const promiseFormSubmission = usePromise();
    const promiseToursAndVehicles = usePromise();


    /* Effects */
    useEffect(() => {
        promiseToursAndVehicles.setPromise(Promise.all([
            toursProvider.get().then(response => {
                let activeTours = response.data.filter(tour => tour.active);
                setAllTours(activeTours);
            }),
            vehiclesProvider.get().then(response => {
                let vehicles = response.data;
                setAllVehicles(vehicles);
            }),
        ]))
    }, [deliveryTimeframe]);

    useEffect(() => {
        const thisPeriod = deliveryTimeframe.periods.find((period: UnvalidatedPeriod) => period.id === state.rowIndex);
        const alreadyChosenToursThisPeriod = thisPeriod?.slots.map((slot: Slot) => slot.tour.id) || [];
        if (alreadyChosenToursThisPeriod.length > 0) {
            // filter tours that have already been chosen this period
            setTours(allTours.filter((tour: Tour) => !alreadyChosenToursThisPeriod.some((elId: Tour["id"]) => elId === tour.id)));
        } else {
            setTours(allTours);
        }
    }, [allTours, deliveryTimeframe])

    useEffect(() => {
        const alreadyChosenVehicles: Set<Vehicle["id"]> = new Set(deliveryTimeframe.periods.flatMap(period => period.slots.map(slot => slot.vehicle.id)));
        // filter out vehicles that have already been chosen for some tour,
        // except for the vehicle that is assigned for the slot that is currently being edited
        setVehicles(allVehicles.filter(vehicle => !alreadyChosenVehicles.has(vehicle.id) || state.vehicle?.id === vehicle.id));
    }, [allVehicles, deliveryTimeframe])

    /* Handlers */
    const handleChangeTour = (value: number | null) => {
        const tour = allTours.find(tour => tour.id === value);
        onChange && onChange({...state, tour});
        const tourCapacity = tour?.capacity || null;
        setVehicles(tourCapacity ? allVehicles.filter(vehicle => vehicle.capacity === tourCapacity) : allVehicles)
    };

    const handleChangeVehicle = (value: string | null) => {
        const vehicle = allVehicles.find(vehicle => vehicle.id === value);
        onChange && onChange({...state, vehicle});
        const vehicleCapacity = vehicle?.capacity || null;
        setTours(vehicleCapacity ? allTours.filter(tour => tour.capacity === vehicleCapacity) : allTours);
    };

    const performValidation = (): boolean => {
        let invalidVehicle = (state.vehicle === undefined);
        let invalidTour = (state.tour === undefined);
        let isInvalid = invalidVehicle || invalidTour;
        if (onChange && isInvalid) {
            onChange({...state, invalidTour: invalidTour, invalidVehicle: invalidVehicle});
        }
        return !isInvalid;
    }

    const handleSubmit = () => {
        if (performValidation()) {
            onSubmit && onSubmit()
        }
    }

    return (
        <div className="slot-modal">
            <AldiModal
                title="Tour und Fahrzeug festlegen"
                subtitle="Bitte suche eine Tour und ein Fahrzeug aus."
                textCancelButton="Verwerfen"
                onCancel={onCancel}
                textSubmitButton="Speichern"
                onSubmit={handleSubmit}
                textDeleteButton="Löschen"
                onDelete={onDelete}
                enableDeleteButton={state.isEdit}
                loading={promiseToursAndVehicles.isLoading || promiseFormSubmission.isLoading}>

                <p className="heading">Tour</p>
                <AldiSelect
                    resettable
                    value={state.tour?.id ?? null}
                    onChange={handleChangeTour}
                    renderValue={() => state.tour ? `${state.tour.name} (${state.tour.capacity})` : "Tour auswählen"}
                    onReset={() => handleChangeTour(null)}
                    disabled={promiseToursAndVehicles.isLoading || promiseFormSubmission.isLoading}
                    label="Tour auswählen">
                    {tours.map(someTour =>
                        <AldiMenuItem key={someTour.id} value={someTour.id}>
                            {someTour.name} ({someTour.capacity})
                        </AldiMenuItem>
                    )}
                </AldiSelect>
                {state.invalidTour && <p className="validation">Bitte wähle eine Tour</p>}
                <p className="heading">Fahrzeug</p>
                <AldiSelect
                    resettable
                    value={state.vehicle?.id ?? null}
                    onChange={handleChangeVehicle}
                    renderValue={() => state.vehicle ? `Fahrzeug ${state.vehicle.id} (${state.vehicle.capacity})` : "Fahrzeug auswählen"}
                    onReset={() => handleChangeVehicle(null)}
                    disabled={promiseToursAndVehicles.isLoading || promiseFormSubmission.isLoading}
                    label="Fahrzeug auswählen">
                    {vehicles.map(someVehicle =>
                        <AldiMenuItem key={someVehicle.id} value={someVehicle.id}>
                            {someVehicle.id} ({someVehicle.capacity})
                        </AldiMenuItem>
                    )}
                </AldiSelect>
                {state.invalidVehicle && <p className="validation">Bitte wähle ein Fahrzeug</p>}
            </AldiModal>
        </div>
    )
}
