import { AxiosResponse } from "axios";
import { useEffect, useState } from "react";

export enum PromiseStatus {
    Done = "done",
    Failed = "failed",
    Pending = "pending"
};

export default function usePromise<ValueType>(): {
    result?: ValueType,
    setPromise: (promise: Promise<ValueType> | null) => void,
    status: PromiseStatus,
    isLoading: boolean,
} {
    const [promise, setPromise] = useState<Promise<ValueType> | null>(null);
    const [isLoading, setLoading] = useState<boolean>(false);
    const [hasFailed, setFailed] = useState<boolean>(false);
    const [result, setResult] = useState<ValueType | undefined>();

    let status: PromiseStatus;
    if (isLoading) {
        status = PromiseStatus.Pending;
    } else if (hasFailed) {
        status = PromiseStatus.Failed;
    } else {
        status = PromiseStatus.Done;
    }

    useEffect(() => {
        if (promise !== null) {
            setLoading(true);
            setFailed(false);
            promise
                .then(value => {
                    setResult(value);
                })
                .catch(_error => {
                    setFailed(true);
                })
                .finally(() => {
                    setPromise(null);
                    setLoading(false);
                })
        }
    }, [promise])

    return {
        result,
        setPromise,
        status,
        isLoading,
    };
}


export function useAxios<ResponseType>(): {
    result?: ResponseType,
    setRequest: (promise: Promise<AxiosResponse<ResponseType, any>> | null) => void,
    status: PromiseStatus,
    isLoading: boolean,
} {
    let promise = usePromise<AxiosResponse<ResponseType, any>>();
    return {
        ...promise,
        result: promise.result?.data,
        setRequest: promise.setPromise,
    }
}
