import './CreateTourModal.scss';
import { useState, useEffect } from 'react';
import { toursProvider } from '../../../../../shared/tours.service';
import { Capacity, Tour } from '@kehrwasser/aldi-sued-dtm-openapi';
import KWRadioButton from '../../../../elements/radiobutton/KWRadioButton';
import FormMessage from '../../../../elements/formmessage/FormMessage';
import AldiModal from '../../../../elementsAldi/modal/Modal';
import AldiMenuItem from '../../../../elementsAldi/menuitem/AldiMenuItem';
import AldiSelect from '../../../../elementsAldi/select/AldiSelect';
import AldiInput from '../../../../elementsAldi/input/AldiInput';
import { useTourName } from './hooks';
import usePromise from '../../../../../hooks/usePromise';
import { notify } from '../../../../../shared/helpers';

export default function CreateTourModal(props: {
    modal: { hide: () => void },
    allTours: Tour[],
    setAllTours?: (value: Tour[]) => void,
}) {

    const { modal, allTours, setAllTours } = props;

    const initialValues = {
        id: allTours.length,
        name: "",
        cluster: "",
        capacity: Capacity._36,
        active: true
    }

    /* State Definitions */
    const [values, setValues] = useState<Tour>(initialValues)
    const [invalidForm, setInvalidForm] = useState(false)
    const tourName = useTourName("");
    const promiseFormSubmission = usePromise();

    /* Communication */
    useEffect(() => {
        setValues(values => { return { ...values, id: allTours.length } });
    }, [allTours]);

    useEffect(() => {
        setValues(initialValues)
    }, []);

    const currentTourName = tourName.get();
    useEffect(() => {
        setValues(values => { return { ...values, name: currentTourName } })
    }, [currentTourName]);

    /* Helpers */
    const close = () => {
        setInvalidForm(false);
        setValues(initialValues);
        tourName.reset();
        modal.hide();
        promiseFormSubmission.setPromise(null);
    }

    const allValuesValid = () => {
        tourName.showErrors();
        return tourName.isValid;
    }

    /* Handlers */
    const handleNameChange = (name: string) => {
        tourName.set(name);
        setValues({ ...values, name: name })
    }

    const handleChangeGroup = (value: string) => {
        setValues({ ...values, cluster: value });
    };

    const handleSubmit = () => {
        if (allValuesValid()) {
            promiseFormSubmission.setPromise(
                toursProvider.create({ ...values })
                    .then(response => {
                        setAllTours && setAllTours([...allTours, response.data]);
                        setValues(initialValues);
                        close();
                    }).catch(error => {
                        notify("Ein Fehler ist aufgetreten.");
                        console.debug(error);
                    }))
        } else {
            setInvalidForm(true)
        }
    };

    const handleChangeCapacity = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValues({ ...values, capacity: event.target.value as Capacity });
    };

    /* Renderers */
    return (
        <AldiModal
            onCancel={close}
            title="Neue Tour planen"
            subtitle="Bitte gib alle notwendigen Informationen an."
            onSubmit={handleSubmit}
            textCancelButton="Verwerfen"
            textSubmitButton="Speichern"
            loading={promiseFormSubmission.isLoading}
            disabled={promiseFormSubmission.isLoading}>
            <p className="heading">
                {"Name der Tour"}
            </p>
            <AldiInput
                fullWidth
                name="name"
                id="name"
                placeholder="Name"
                required
                onChange={handleNameChange}
                disabled={promiseFormSubmission.isLoading}
                {...tourName.inputProps}
            />
            <p className="heading">
                {"Geografisches Cluster"}
            </p>
            <AldiSelect
                value={values.cluster}
                label="Cluster auswählen"
                disabled={promiseFormSubmission.isLoading}
                onChange={handleChangeGroup}>
                <AldiMenuItem value="10">10</AldiMenuItem>
                <AldiMenuItem value="20">20</AldiMenuItem>
                <AldiMenuItem value="30">30</AldiMenuItem>
                <AldiMenuItem value="40">40</AldiMenuItem>
                <AldiMenuItem value="50">50</AldiMenuItem>
            </AldiSelect>
            <p className="heading">
                {"Kapazität"}
            </p>
            <div className="radio-group">
                {(Object.keys(Capacity) as Array<keyof typeof Capacity>).map(key => {
                    return (
                        <KWRadioButton
                            key={key}
                            handleChange={handleChangeCapacity}
                            value={Capacity[key]}
                            disabled={promiseFormSubmission.isLoading}
                            checked={values.capacity === Capacity[key]}>
                            <span className="radio-value-label">
                                {Capacity[key]} <span className="radio-value-label--small">
                                    {"Boxen"}
                                </span>
                            </span>
                        </KWRadioButton>)
                })}
            </div>

            {invalidForm && <FormMessage title="Unvollständige Angaben" message="Bitte korrigieren Sie die Angaben und versuche es erneut." />}

        </AldiModal >
    )
}
