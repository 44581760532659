import './AldiMultiSelect.scss';
import ResetButton from '../helpers/resetbutton/ResetButton';
import { FormControl, InputAdornment, InputLabel, Select, SelectChangeEvent, SelectProps } from '@mui/material';

type MultiSelectProps<ValueType> = {
    value?: ValueType[],
    onReset?: () => void,
    resettable?: boolean,
    placeholder?: number,
    onChange?: (values: ValueType[]) => void,
} & Omit<SelectProps<ValueType[]>, "multiple" | "onChange">;

export default function AldiMultiSelect<ValueType>(props: MultiSelectProps<ValueType>) {

  const { value=[], resettable=false, onReset, children, onChange, ...selectProps } = props;

    const classes = ["aldi-kwmc-multi-select", resettable ? "resettable" : ""];

  const renderValue = (selected: ValueType[]): React.ReactNode => {
        if (selected.length === 0) {
            return (<span>{selectProps.label}</span>);
        } else {
            return (<div className="value-wrapper">
                <p className="placeholder-text">
                    {selectProps.label}
                </p>
                <div className="number-badge">
                    {selected.length}
                </div>
            </div>);
        }
  }

    function handleChange(event: SelectChangeEvent<ValueType[]>, _child?: React.ReactNode) {
        if (onChange) {
            const value = event.target.value;
            if (typeof value !== "string" && Array.isArray(value)) {
                onChange(value)
            } else {
                console.debug("[AldiMultiSelect] is not an array: ", value);
            }
        }
    }

  return (
      <div className={classes.join(" ")}>
          <FormControl fullWidth size="small">
              <InputLabel>{selectProps.label}</InputLabel>
              <Select {...selectProps}
                    value={value}
                    multiple
                    renderValue={renderValue}
                    onChange={handleChange}>
                  {children}
              </Select>
          </FormControl>
          {resettable && <ResetButton onClick={onReset} />}
      </div>
  );
}
