import './ConfigurationPage.scss';
import { configParametersProvider } from '../../../shared/configParameters.service';
import { useState, useEffect } from 'react';
import { useCallbackPrompt } from '../../../hooks/useCallbackPrompt'
import { ConfigParameter as ConfigParameterInterface, ConfigParameterType } from '@kehrwasser/aldi-sued-dtm-openapi';
import AldiInput from '../../elementsAldi/input/AldiInput';
import AldiButton from '../../elementsAldi/button/AldiButton';
import Toggle from '../../elementsAldi/toggle/toggle';
import AldiGeneralModal from '../../elementsAldi/generalmodal/AldiGeneralModal';
import { useModal } from '../../../hooks/useModal';
import usePromise from '../../../hooks/usePromise';

interface ConfigurationPageProps {
    parameterId: string,
    title: string
}

export default function ConfigurationPage({parameterId, title}: ConfigurationPageProps) {

    /* State Definitions */
    const [configParameters, setConfigParameters] = useState<any[]>([]);
    const [defaultConfigParameters, setDefaultConfigParameters] = useState<any[]>([]);
    const [showErrorMessageParameterIds, setShowErrorMessageParameterIds] = useState<string[]>([]);
    const [showDialog, setShowDialog] = useState<boolean>(false)
    const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(showDialog)
    const promiseParameters = usePromise();

    const leavePageModal = useModal();


    /* Helper */
    const filterInt = (value:string) => {
        return /^[0-9]+$/.test(value) ? Number(value) : NaN;
    }

    const validateValues = (parameters: ConfigParameterInterface[], values: string[]) => {
        let idsToAdd: string[] = [];
        let idsToRemove: string[] = [];

        parameters.forEach((parameter, index) => {
            const value = values[index] === undefined ? '' : values[index];
            if(((parameter.type === ConfigParameterType.Integer && isNaN(filterInt(value))) || value.length > parameter.length || value.length === 0) && parameter.enabled === true){
                if(!showErrorMessageParameterIds.includes(parameter.id)){
                    idsToAdd = [...idsToAdd, parameter.id]
                }
            } else {
                idsToRemove = [...idsToRemove, parameter.id]
            }

            // add idsToAdd
            const errorMessageParameterIdsWithParameterIdsToAdd = [ ...showErrorMessageParameterIds, ...idsToAdd]

            // remove idsToRemove
            const newErrorMessageParameterIds = errorMessageParameterIdsWithParameterIdsToAdd.filter(el => idsToRemove.indexOf(el) < 0)

            setShowErrorMessageParameterIds(newErrorMessageParameterIds)

        })

    }

    const reset = () => {
        setConfigParameters(defaultConfigParameters)
        setShowDialog(false)
        setShowErrorMessageParameterIds([])
    }

    const save = () => {
        configParametersProvider.update(parameterId, configParameters).then(response => {
            throw new Error("Not implemented yet");
        })
    }

    const leavePage = () => {
        // @ts-ignore
        confirmNavigation()
        leavePageModal.hide();
    }

    const cancelLeavePage = () => {
        // @ts-ignore
        cancelNavigation()
        leavePageModal.hide();
    }


    /* Communication */
    useEffect(() => {
        promiseParameters.setPromise(configParametersProvider.get(parameterId)
            .then(response => {
                setConfigParameters(response.data)
                console.log('response.data', response.data)
                setDefaultConfigParameters(response.data)
            })
            .catch(error => {
                console.debug(error);
                return Promise.reject(error);
            }));
    }, [parameterId]);

    useEffect(() => {
        validateValues(configParameters, configParameters.map(cp => cp.value))
    }, [configParameters]);

    useEffect(() => {
        if (showPrompt) {
            const text = 'Es liegen ungesicherte Einstellungen vor. Soll die Seite wirklich verlassen werden?';
            leavePageModal.setModal(<AldiGeneralModal
                textCancelButton="Abbrechen"
                onCancel={cancelLeavePage}
                textConfirmButton="Seite verlassen"
                onConfirm={leavePage}>{text}</AldiGeneralModal>)
            leavePageModal.show();
        } else {
            leavePageModal.hide();
            leavePageModal.setModal(null);
        }
    }, [showPrompt]);


    /* Handlers */
    const handleChangeEnabled = (parameterId: string) => {
        const newConfigParameters = configParameters.map(el => {
            if(el.id === parameterId){
                return {...el, enabled: !el.enabled}
            }
            return el
        })
        setConfigParameters(newConfigParameters);
        setShowDialog(true)
    }

    const handleChangeValue = (value: string, parameterId: string) => {
        const parameter = configParameters.find(p => p.id === parameterId)
        validateValues([parameter], [value])
        const newConfigParameters = configParameters.map(el => {
            if(el.id === parameterId){
                return {...el, value: value}
            }
            return el
        })
        setConfigParameters(newConfigParameters);
        setShowDialog(true)
    }


    /* Renders */
    const renderParameter = (parameter: any, index: number) => {
        return (
            <div className='parameter' key={index}>
                <div className='name'>{parameter.name}</div>
                <div className='toggle-wrapper'>
                    <Toggle checked={parameter.enabled} onChange={() => handleChangeEnabled(parameter.id)} />
                </div>
                <div>
                    <AldiInput
                        onChange={value => handleChangeValue(value, parameter.id)}
                        value={parameter.value === undefined ? '' : parameter.value}
                        disabled={!parameter.enabled}
                    />
                    <div className="error-message-wrapper">
                        {showErrorMessageParameterIds.includes(parameter.id) &&
                            <div className="error-message">{parameter.errorMessage}</div>
                        }
                    </div>
                </div>
            </div>
        );
    }


    return (
        <div className='replenishment'>
            <div className="container-header">
                <div className="header-title">
                    {title}
                </div>
            </div>
            <div className="config">
                {configParameters.map((parameter, index) => renderParameter(parameter, index))}
                <div className="button-row">
                    <div className="button-wrapper">
                        <AldiButton kind="tertiary" onClick={reset} className="button-row-button" >Reset</AldiButton>
                    </div>
                    <div className="button-wrapper">
                        <AldiButton kind="primary" onClick={save} className="button-row-button" disabled={showErrorMessageParameterIds.length > 0}>Save</AldiButton>
                    </div>
                </div>
            </div>
        </div>
    )
}
