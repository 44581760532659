import './FulfillmentTasksPage.scss';

/* Icons */
import { ReactComponent as IconNotStarted } from '../../../../assets/images/status-icons/not-started.svg';
import { ReactComponent as IconInProgress } from '../../../../assets/images/status-icons/in-progress.svg';
import { ReactComponent as IconDone } from '../../../../assets/images/status-icons/left.svg';
import { ReactComponent as IconWarningHex } from '../../../../assets/images/status-icons/warning--hex.svg';

/* Further Imports */
import { Task as TaskInterface, TaskStatus, Temperature, TaskType } from '@kehrwasser/aldi-sued-dtm-openapi';
import { useEffect, useState } from 'react';
import Table from '../../../elementsAldi/table/Table';
import TableHead from '../../../elementsAldi/table/tableHead/TableHead';
import TableBody from '../../../elementsAldi/table/tableBody/TableBody';
import TableRow from '../../../elementsAldi/table/tableRow/TableRow';
import TableCell from '../../../elementsAldi/table/tableCell/TableCell';
import TableFoot from '../../../elementsAldi/table/tableFoot/TableFoot';
import { TaskFilter, tasksProvider } from '../../../../shared/tasks.service';
import { configPaginationProvider } from '../../../../shared/configPagination.service';
import Pagination from '../../../elementsAldi/pagination/Pagination';
import AldiMultiSelect from '../../../elementsAldi/multiselect/AldiMultiSelect';
import AldiMenuItem from '../../../elementsAldi/menuitem/AldiMenuItem';
import AldiButton from '../../../elementsAldi/button/AldiButton';
import CreateTaskModal from './modal/CreateTaskModal';
import useDebounce from '../../../../hooks/useDebounce';
import AldiFilterMenu from '../../../elementsAldi/filtermenu/AldiFilterMenu';
import { AldiChipInput } from '../../../elementsAldi/chipinput/AldiChipInput';
import { useModal } from '../../../../hooks/useModal';
import usePromise, { PromiseStatus } from '../../../../hooks/usePromise';
import AldiReloadButton from '../../../elementsAldi/reloadbutton/AldiReloadButton';


export default function FulfillmentTasksPage() {

    /* State Definitions */
    const [tasks, setTasks] = useState<TaskInterface[]>([])
    const promiseTasks = usePromise();
    const [count, setCount] = useState(configPaginationProvider.count);
    const [page, setPage] = useState(configPaginationProvider.page);
    const [rowsPerPage, setRowsPerPage] = useState(configPaginationProvider.rowsPerPage);
    const [taskID, setTaskID] = useState('');
    const [typeFilter, setTypeFilter] = useState<TaskType[]>([]);
    const [statusFilter, setStatusFilter] = useState<TaskStatus[]>([]);
    const [temperatureFilter, setTemperatureFilter] = useState<Temperature[]>([]);

    const createTaskModal = useModal();

    const filter = {
        offset: page * rowsPerPage,
        limit: rowsPerPage,
        id: taskID,
        type: typeFilter,
        temperature: temperatureFilter,
        status: statusFilter
    } as TaskFilter;

    const debouncedFilter = useDebounce(filter, 600, Object.values(filter));

    /* Helper */
    const statusIcon = (status: TaskStatus): JSX.Element => {
        const className = "status-icon";

        switch (status) {
            case TaskStatus.Done:
                return <IconDone className={className} />;
            case TaskStatus.NotStarted:
                return <IconNotStarted className={className} />;
            case TaskStatus.InProgress:
                return <IconInProgress className={className} />;
            case TaskStatus.Interrupted:
                return <IconWarningHex className={className} />;
        }
    };

    const makeReadableStatus = (status: TaskStatus): string => {
        switch (status) {
            case TaskStatus.InProgress: return 'In Bearbeitung';
            case TaskStatus.Done: return 'Fertig';
            case TaskStatus.NotStarted: return 'Nicht gestartet';
            case TaskStatus.Interrupted: return 'Abgebrochen';
        }
    };


    /* Effects */
    useEffect(() => {
        updateCurrentPage();
    }, Object.values(debouncedFilter));

    useEffect(() => {
        createTaskModal.setModal(<CreateTaskModal
            modal={createTaskModal}
            tasks={tasks}
            onSuccess={updateCurrentPage} />)
    }, [tasks]);

    /* Helper */
    const updateCurrentPage = () => {

        promiseTasks.setPromise(tasksProvider.get(filter).then(response => {
            setTasks(response.data);
            setCount(parseInt(response.headers['x-total-count']));
        }).catch(error => {
            console.debug(error);
            return Promise.reject(error);
        }));

    };

    const isDefaultValues = () =>
        taskID === ""
            && typeFilter.length === 0
            && statusFilter.length === 0
            && temperatureFilter.length === 0;


    /* Handlers */
    const handleResetAll = () => {
        setTaskID('')
        setTemperatureFilter([])
        setStatusFilter([])
        setTypeFilter([])
    }

    /* Renderers */
    const renderRow = (task: TaskInterface) => {
        return (
            <TableRow key={task.id}>
                <TableCell className='title-column-cell' data-testid="task-id">
                    {task.id}
                </TableCell>
                <TableCell data-testid="type">
                    {taskTypeLabel(task.type)}
                </TableCell>
                <TableCell data-testid="temperature" className="temperature">
                    <span className="cell-wrapper">
                        {temperatureLabel(task.temperature)}
                    </span>
                </TableCell>
                <TableCell>
                    {task.volume}
                </TableCell>
                <TableCell data-testid="status" className='status'>
                    <span className='wrapper'>
                        {statusIcon(task.status)}
                        {makeReadableStatus(task.status)}
                    </span>
                </TableCell>
            </TableRow>
        );
    }

    const renderFilters = () => {
        return (
            <div className="filters">
                <div className="header-title">
                    {"Aufgaben-Übersicht"}
                </div>
                <div className="placeholder"></div>

                <div className="aldi-box filter">
                    <AldiButton kind="primary" onClick={createTaskModal.show}>
                        <span className="ri-add-line"></span>
                        {"Picking-Aufgaben generieren"}
                    </AldiButton>
                </div>

                <div className="medium-variable-gap" />

                <div className="aldi-box filter">
                    <AldiFilterMenu selected={!isDefaultValues()} classNamePopover="filter-menu" data-testid="filter-menu">

                        <div className="aldi-box filter">
                            <AldiMultiSelect
                                resettable
                                data-testid="type-filter"
                                value={typeFilter}
                                onChange={setTypeFilter}
                                label="Typ"
                                onReset={() => setTypeFilter([])}>
                                {(Object.values(TaskType) as Array<TaskType>).map(taskType => (
                                    <AldiMenuItem key={taskType} value={taskType} data-testid={taskType}>
                                        {taskTypeLabel(taskType)}
                                    </AldiMenuItem>
                                ))}
                            </AldiMultiSelect>
                        </div>
                        <div className="aldi-box filter">
                            <AldiMultiSelect
                                resettable
                                value={temperatureFilter}
                                data-testid="temperature-filter"
                                onChange={setTemperatureFilter}
                                label="Temperatur"
                                onReset={() => setTemperatureFilter([])}>
                                {(Object.values(Temperature) as Array<Temperature>).map(temperature => (
                                    <AldiMenuItem key={temperature} value={temperature}>{temperatureLabel(temperature)}</AldiMenuItem>
                                ))}
                            </AldiMultiSelect>
                        </div>
                        <div className="aldi-box filter">
                            <AldiMultiSelect
                                resettable
                                value={statusFilter}
                                data-testid="status-filter"
                                onChange={setStatusFilter}
                                label="Status"
                                onReset={() => setStatusFilter([])}>
                                {(Object.values(TaskStatus) as Array<TaskStatus>).map(status => (
                                    <AldiMenuItem key={status} value={status} data-testid={status}>
                                        {taskStatusLabel(status)}
                                    </AldiMenuItem>
                                ))}
                            </AldiMultiSelect>
                        </div>
                        <div className="aldi-box filter">
                            <AldiButton kind="tertiary" disabled={isDefaultValues()} onClick={handleResetAll}>
                                {"Filter zurücksetzen"}
                            </AldiButton>
                        </div>
                    </AldiFilterMenu>
                </div>

                <div className="small-fixed-gap" />

                <div className="aldi-box filter">
                    <AldiChipInput
                        value={taskID.split(" ").filter(term => term !== "")}
                        placeholder="Aufgaben ID"
                        onChange={values => setTaskID(values.join(" "))}
                        resettable />
                </div>
            </div>
        )
    }


    return (
        <div className='task-overview'>
            {renderFilters()}
            <div className="aldi-box aldi-general-table-wrapper">
                <Table>
                    <TableHead loading={promiseTasks.isLoading}>
                        <TableRow className="single-row-thead">
                            <TableCell>{"Aufgaben ID"}</TableCell>
                            <TableCell>{"Aufgaben Typ"}</TableCell>
                            <TableCell>{"Temperatur"}</TableCell>
                            <TableCell>{"Umfang"}</TableCell>
                            <TableCell>{"Status"}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        { promiseTasks.status === PromiseStatus.Failed &&
                            <TableRow>
                                <TableCell  colSpan={5}>
                                    <AldiReloadButton onClick={updateCurrentPage}/> 
                                </TableCell>
                            </TableRow>}
                        { tasks.map(task => renderRow(task)) }
                    </TableBody>
                    <TableFoot>
                        <TableRow>
                            <Pagination
                                colSpan={5}
                                count={count}
                                page={page}
                                onPageChange={setPage}
                                rowsPerPage={rowsPerPage}
                                onRowsPerPageChange={setRowsPerPage}
                            />
                        </TableRow>
                    </TableFoot>
                </Table>
            </div>
        </div>
    )
}

export function temperatureLabel(temperature: Temperature): string {
    switch (temperature) {
        case Temperature.Am: return "Ambient";
        case Temperature.Ch: return "Chilled";
        case Temperature.Fr: return "Frozen";
    }
}

export function taskStatusLabel(status: TaskStatus): string {
    switch (status) {
        case TaskStatus.NotStarted: return "Nicht gestartet";
        case TaskStatus.InProgress: return "In Bearbeitung";
        case TaskStatus.Done: return "Abgeschlossen";
        case TaskStatus.Interrupted: return "Unterbrochen";
    }
}

export function taskTypeLabel(taskType: TaskType): string {
    switch (taskType) {
        case TaskType.Picking: return 'Picking';
        case TaskType.QmPicking: return 'QM-Picking';
        case TaskType.Cooling: return 'Cooling';
        case TaskType.CrossdockAssigning: return 'Crossdock-Assigning';
        case TaskType.Crossdock: return 'Crossdock';
        case TaskType.Inventur: return 'Inventur';
        case TaskType.Mhd: return 'MHD';
        case TaskType.Express: return 'Express';
        case TaskType.Nachschub: return 'Nachschub';
    }
}

