import React, { useCallback, useState } from 'react';
import {
  Routes,
  Route,
  Outlet,
  useNavigate,
  useLocation,
  Navigate
} from "react-router-dom";
import HeaderLayout from './components/layout/header/HeaderLayout';
import DashboardPage from './components/pages/dashboard/DashboardPage';
import FulfillmentClustePage from './components/pages/fulfillment/clusters/FulfillmentClusterPage';
import FulfillmentActivitiesPage from './components/pages/fulfillment/activities/FulfillmentActivitiesPage';
import FulfillmentOrderPage from './components/pages/fulfillment/orders/order/FulfillmentOrderPage';
import FulfillmentTasksPage from './components/pages/fulfillment/tasks/FulfillmentTasksPage';
import ConfigurationReplenishmentPage from './components/pages/configuration/replenishment/ConfigurationReplenishmentPage';
import ConfigurationInventoryTasksPage from './components/pages/configuration/inventoryTasks/ConfigurationInventoryTasksPage';
import ConfigurationEmergencyPickingPage from './components/pages/configuration/emergencyPicking/ConfigurationEmergencyPickingPage';
import DeliveryCleveronsPage from './components/pages/clickAndCollect/cleverons/DeliveryCleveronsPage';
import TimetableCleveronsPage from './components/pages/clickAndCollect/timetableCleverons/TimetableCleveronsPage';
import TourPlanningPage from './components/pages/homeDelivery/tours/TourPlanningPage';
import SidebarLayout from './components/layout/sidebar/SidebarLayout';
import TimetableHomeDeliveryPage from './components/pages/homeDelivery/timetableHomeDelivery/TimetableHomeDeliveryPage';
import LocationPage from './components/pages/storagePlaces/locations/LocationPage';
import { AuthProvider, RequireAuth, RequireNoAuth, useAuth } from './components/context/AuthProvider';
import { useModalContext, ModalContext } from './components/context/ModalProvider';
import Snackbar from '@mui/material/Snackbar';
import { notifications } from './shared/helpers';

function App() {
  return (
    <AuthProvider>
      <Routes>
        <Route element={<Layout />}>
          <Route path="/login" element={
            <RequireNoAuth>
              <LoginPage />
            </RequireNoAuth>
          } />
          <Route path="/logout" element={<LogoutPage />} />
          <Route
            path="/"
            element={<Navigate to="/dashboard" replace />}
          />
          <Route
            path="/dashboard"
            element={
              <RequireAuth>
                <DashboardPage />
              </RequireAuth>
            }
          />
          <Route
            path="/fulfillment/activities"
            element={
              <RequireAuth>
                <FulfillmentActivitiesPage />
              </RequireAuth>
            }
          />
          <Route
            path="/fulfillment/cluster-overview"
            element={
              <RequireAuth>
                <FulfillmentClustePage />
              </RequireAuth>
            }
          />
          <Route
            path="/fulfillment/orders"
            element={
              <RequireAuth>
                <FulfillmentOrderPage />
              </RequireAuth>
            }
          />
          <Route
            path="/fulfillment/tasks"
            element={
              <RequireAuth>
                <FulfillmentTasksPage />
              </RequireAuth>
            }
          />
          <Route
            path="/configuration/replenishment"
            element={
              <RequireAuth>
                <ConfigurationReplenishmentPage />
              </RequireAuth>
            }
          />
          <Route
            path="/configuration/inventory-tasks"
            element={
              <RequireAuth>
                <ConfigurationInventoryTasksPage />
              </RequireAuth>
            }
          />
          <Route
            path="/configuration/emergency-picking"
            element={
              <RequireAuth>
                <ConfigurationEmergencyPickingPage />
              </RequireAuth>
            }
          />
          <Route
            path="/click-and-collect/cleverons"
            element={
              <RequireAuth>
                <DeliveryCleveronsPage />
              </RequireAuth>
            }
          />
          <Route
            path="/click-and-collect/timetable-cleverons"
            element={
              <RequireAuth>
                <TimetableCleveronsPage />
              </RequireAuth>
            }
          />
          <Route
            path="/home-delivery/tour-planning"
            element={
              <RequireAuth>
                <TourPlanningPage />
              </RequireAuth>
            }
          />
          <Route
            path="/home-delivery/timetable-home-delivery"
            element={
              <RequireAuth>
                <TimetableHomeDeliveryPage />
              </RequireAuth>
            }
          />
          <Route
            path="/storage-places/locations"
            element={
              <RequireAuth>
                <LocationPage />
              </RequireAuth>
            }
          />
        </Route>
      </Routes>

    </AuthProvider>
  );
}

function Layout() {

  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState('');

  let auth = useAuth();
  const location = useLocation();
  const modalContext = useModalContext();

  notifications.subscribe(message => {
    setSnackbarMessage(message)
    setSnackbarOpen(true)
  })

  return (
    <div className="frame">
      <ModalContext.Provider value={modalContext}>
      {auth.session && location.pathname != '/dashboard' && (
        <div className="sidebar-wrapper">
          <SidebarLayout />
        </div>
      )}
      <div className="page-content-wrapper">
        <HeaderLayout />

        <div className="content">
          <div className="outlet">
            <Outlet />
          </div>
        </div>

        {modalContext.stack.map(([id, modal]) => {
            return (<div key={id}> {modal} </div>)
        })}

        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          open={snackbarOpen}
          onClose={() => setSnackbarOpen(false)}
          autoHideDuration={2000}
          message={snackbarMessage}
        />

      </div>
      </ModalContext.Provider>
    </div>
  )
}

function LoginPage() {
  let navigate = useNavigate();
  let location = useLocation();
  let auth = useAuth();

  const state = location.state as any
  let from = state?.from?.pathname || "/"

  function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    let formData = new FormData(event.currentTarget)
    let username = formData.get("username") as string

    auth.signin(username, () => {
      navigate(from, { replace: true })
    })
  }

  return (
    <div>
      <p>{"Der folgende Token wird verwendet, um einen Authentifizierungsvorgang zu simulieren:"}</p>

      <form onSubmit={handleSubmit}>
        <label>
          Token (Zufallssequenz): <input name="username" type="text" defaultValue="eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9" />
        </label>{" "}
        <button type="submit">{"Anmelden"}</button>
      </form>
    </div>
  );
}

function LogoutPage() {
  const auth = useAuth();
  let navigate = useNavigate();

  auth.signout(() => navigate("/"))
  return <Navigate to="/" replace />;
}

export default App;
