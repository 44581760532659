import './AldiMenuItem.scss';
import { MenuItem } from '@mui/material';


function AldiMenuItem(props: any) {
  return <MenuItem {...props} />;
}

export default AldiMenuItem;

