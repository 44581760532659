import { useEffect, useState } from "react";
import { Availability } from "@kehrwasser/aldi-sued-dtm-openapi";
import { toursProvider } from "../../../../../shared/tours.service";

export function useTourName(initial: string): { get: () => string, set: (value: string) => void, showErrors: () => void, reset: () => void, isValid: boolean, inputProps: { value: string, error: boolean, helperText: string } } {
    const [value, setValue] = useState<string>(initial);
    const [result, setResult] = useState<Availability | null>(null);
    const [isValid, setValid] = useState<boolean>(false);
    const [helperText, setHelperText] = useState<string>("");
    const [showError, setShowError] = useState<boolean>(false);

    useEffect(() => {
        if (value !== "") {
            toursProvider.isTourNameAvailable(value)
                .then(response => setResult(response.data))
                .catch(() => setResult(null))
        }
    }, [value])

    useEffect(() => {
        if (value === "") {
            setValid(false);
            setHelperText("Bitte geben Sie einen Namen an.")
        } else if (result === null) {
            setValid(false);
            setShowError(true);
            setHelperText("Es konnte keine Verbindung zum Server aufgebaut werden.")
        } else if (!result.isAvailable) {
            setValid(false);
            setShowError(true);
            setHelperText("Dieser Name ist bereits vergeben.");
        } else {
            setValid(true);
            setHelperText("");
            setShowError(false);
        }
    }, [value, result])

    return {
        get: () => value,
        set: setValue,
        showErrors: () => setShowError(true),
        reset: () => {
            setValue("");
            setShowError(false);
            setHelperText("");
        },
        isValid,
        inputProps: { value, error: !isValid && showError, helperText: showError ? helperText : "" },
    }
}

