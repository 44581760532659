import { WeekRange } from '../types';
import { groupBy, toDateTime, Week } from '../../../../shared/helpers';
import './KWWeekPicker.scss';
import { DateTime } from '../../../../shared/datetime/DateTime';
import { Interval } from '../../../../shared/datetime/Interval';

export default KWWeekPicker;
export { type KWWeekPickerProps };

interface KWWeekPickerProps {
    className?: string,
    month: DateTime,
    value: WeekRange,
    showPreviousMonth?: boolean,
    showNextMonth?: boolean,
    onMonthChange?: (value: DateTime) => void,
    onChange?: (value: WeekRange) => void,
}

function KWWeekPicker({
    month,
    className,
    value,
    showPreviousMonth = false,
    showNextMonth = false,
    onMonthChange,
    onChange,
    ...props }: KWWeekPickerProps) {

    /* Handlers */

    const handleWeekClick = (week: Week) => {
        if (onChange) {
            if (value.startWeek) {
                if (value.endWeek) {
                    onChange({ startWeek: week });
                } else if (toDateTime(value.startWeek) <= toDateTime(week)) {
                    onChange({ startWeek: value.startWeek, endWeek: week });
                } else {
                    onChange({ startWeek: week, endWeek: value.startWeek });
                }
            } else {
                onChange({ startWeek: week })
            }
        }
    }

    const showPreviousButtonClass = ["kw-month-show-previous"];
    if (!showPreviousMonth) showPreviousButtonClass.push("hidden")

    const showNextButtonClass = ["kw-month-show-previous"];
    if (!showNextMonth) showNextButtonClass.push("hidden")

    return (
        <div {...props} className={(className ? className + ' ' : '') + 'kw-calendar-month'}>
            <div className='kw-month-header'>
                <div
                    className={showPreviousButtonClass.join(' ')}
                    onClick={() => onMonthChange && onMonthChange(month.minus({ months: 1 }))}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z" /><path d="M10.828 12l4.95 4.95-1.414 1.414L8 12l6.364-6.364 1.414 1.414z" /></svg>
                </div>
                <div className='kw-month-title'>
                    <span style={{ "verticalAlign": "-1em" }}>
                        {`${month.monthLong} ${month.year}`}
                    </span>
                </div>
                <div
                    className={showNextButtonClass.join(' ')}
                    onClick={() => onMonthChange && onMonthChange(month.plus({ months: 1 }))}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z" /><path d="M13.172 12l-4.95-4.95 1.414-1.414L16 12l-6.364 6.364-1.414-1.414z" /></svg>
                </div>
            </div>
            <div className="kw-month-view">
                {renderHeaders()}
                {getDaysByWeek(month).map(week => renderWeek(month, week, value, handleWeekClick))}
            </div>
        </div>
    )
}


/* Helpers */

function getDaysByWeek(month: DateTime): DateTime[][] {
    let startWeek: DateTime = month.startOf('month').startOf('week');
    let endWeek: DateTime = month.endOf('month').endOf('week');

    let numberOfDays = Interval.fromDateTimes(startWeek, endWeek).splitBy({ days: 1 }).length;
    /* we always render 7 weeks */
    if (numberOfDays < 7 * 6) {
        endWeek = endWeek.plus({ weeks: 1 })
    }

    const daysInCalendar: DateTime[] = Interval.fromDateTimes(startWeek, endWeek).splitBy({ days: 1 }).map(interval => interval.start);
    const daysByWeek: DateTime[][] = groupBy(daysInCalendar, day => day.weekNumber);

    return daysByWeek;
}

function isStartWeek(week: DateTime, range: WeekRange): boolean {
    if (range.startWeek) {
        const startWeek = toDateTime(range.startWeek);
        const startWeekInterval = Interval.fromDateTimes(startWeek, startWeek.endOf('week'));
        return startWeekInterval.contains(week);
    } else {
        return false;
    }
}

function isEndWeek(week: DateTime, range: WeekRange): boolean {
    if (range.endWeek) {
        const endWeek = toDateTime(range.endWeek);
        const endWeekInterval = Interval.fromDateTimes(endWeek, endWeek.endOf('week'));
        return endWeekInterval.contains(week);
    } else {
        return false;
    }
}

function isInRange(week: DateTime, range: WeekRange): boolean {
    if (range.startWeek && range.endWeek) {
        const strictlyBetweenInterval = Interval.fromDateTimes(
            toDateTime(range.startWeek),
            toDateTime(range.endWeek).endOf('week'),
        );
        return strictlyBetweenInterval.contains(week)
    } else {
        return false;
    }
}

/* Renderers */

const renderHeaders = () => {
    let genericStartOfWeek: DateTime = DateTime.now().startOf('week');
    const labels: string[] = [0, 1, 2, 3, 4, 5, 6].map(days => genericStartOfWeek.plus({ days }).weekdayShort);

    return (
        <div className='kw-week kw-week-headers'>
            <div className='kw-date-item kw-date-item-calendar-week'>KW</div>
            {labels.map((label, i) => <div key={i} className='kw-date-item'>{label}</div>)}
        </div>
    )
}

const renderDay = (month: DateTime, date: DateTime) => {
    const classes = ['kw-date-item']
    if (date.month !== month.month) classes.push('kw-date-item-not-current')
    return <div key={date.day} className={classes.join(' ')}>{date.day}</div>
}

const renderWeek = (
    month: DateTime,
    week: DateTime[],
    range: WeekRange,
    handleWeekClick: (week: Week) => void) => {
    const startOfWeek: DateTime = week[0];

    const classes = ['kw-week', 'kw-week-days']
    if (isStartWeek(startOfWeek, range)) {
        classes.push('kw-week-selection-start');
    } else if (isEndWeek(startOfWeek, range)) {
        classes.push('kw-week-selection-end');
    } else if (isInRange(startOfWeek, range)) {
        classes.push('kw-week-selection-between');
    }

    return (
        <div
            key={startOfWeek.weekNumber}
            className={classes.join(' ')}
            onClick={() => handleWeekClick({
                weekYear: startOfWeek.weekYear,
                weekNumber: startOfWeek.weekNumber
            })}>
            <div className='kw-date-item kw-date-item-calendar-week'>{startOfWeek.weekNumber}</div>
            {week.map(date => renderDay(month, date))}
        </div>
    )
}
