import { DateTime } from "../../../../shared/datetime/DateTime";
import { Interval } from "../../../../shared/datetime/Interval";

export function weekdaysFor(day: DateTime): DateTime[] {
    let startOfWeek = day.startOf("week");
    let endOfWeek = day.endOf("week");
    let week: Interval = Interval.fromDateTimes(startOfWeek, endOfWeek);
    let days: DateTime[] = week.splitBy({ days: 1 }).map(dayInterval => dayInterval.start);
    return days;
}


export function getBackgroundColorCleveron(index: number): string {
    switch (index) {
        case 0:
            return "#92D050";
        case 1:
            return "#FAB400";
        case 2:
            return "#222C78";
        default:
            return "#222C78";
    }
}
