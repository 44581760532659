import { AxiosResponse } from "axios";
import { Task, TaskStatus, Temperature, TaskType } from "@kehrwasser/aldi-sued-dtm-openapi";
import { apiService } from "./apiService";

export interface TaskFilter {
    id?: string;
    status?: TaskStatus[];
    temperature?: Temperature[];
    type?: TaskType[];
    volume?: number;
    date?: string;
    offset?: number;
    limit?: number;
    q?: string
}

export const tasksProvider = {

    api: apiService.task,

    get(input: string | TaskFilter): Promise<AxiosResponse<Task[], any>> {

        let taskFilter: TaskFilter;

        if (typeof input === 'string') {
            taskFilter = { id: input } as TaskFilter;
        } else {
            taskFilter = input;
        }

        return this.api.getTasks(
            taskFilter.status?.join(','),
            taskFilter.temperature?.join(','),
            taskFilter.type?.join(','),
            taskFilter.date,
            taskFilter.q,
            taskFilter.offset,
            taskFilter.limit,
        );

    },

    delete(taskId: string) {
        throw new Error('Not implemented yet');
    },

    update(task: Task) {
        throw new Error('Not implemented yet');
    },

    create(temperature: Temperature, type: string) {
        return this.api.createTask(temperature, type);
    }

};
