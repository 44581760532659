import { HTMLAttributes, useEffect, useRef, useState } from "react";
import { removeByIndex } from "../../../shared/helpers";
import "./KWChipInput.scss";


type KWChipProps = {
    value: string,
    focus?: boolean,
    onDelete: () => void,
}

type CursorPos = { type: "input" } | { type: "reverse-chip-index", index: number };

export type KWChipInputProps = {
    value: string[],
    onChange?: (value: string[]) => void,
    resettable?: boolean,
    resetText?: React.ReactElement,
} & Omit<HTMLAttributes<HTMLInputElement>, "onChange" | "value">;

function KWChip({ value, onDelete, focus=false }: KWChipProps) {
    const classes = ["kwchipinput-chip", focus ? "focus" : ""];

    return (
        <div
            role="chip"
            className={classes.join(" ")}>
            <div className="kwchipinput-chip-content">
                <div className="kwchipinput-chip-label">
                    {value}
                </div>
                <div
                    className="kwchipinput-chip-close"
                    onClick={onDelete}>
                    <span className='ri-close-line ri-lg'></span>
                </div>
            </div>
        </div>);
}

export default function KWChipInput({
    className,
    value,
    onChange,
    resettable,
    resetText = (<span className='ri-close-line' />),
    ...inputProps
}: KWChipInputProps) {
    const [inputValue, setInputValue] = useState<string>("");
    const [focus, setFocus] = useState<boolean>(false);

    const chipWrapperRef = useRef(null);
    const inputRef = useRef(null);
    const [cursorPos, setCursorPos] = useState<CursorPos>({type: "input"});

    const classes = [className ?? "", "kwchipinput", focus ? "focus" : ""];
    const inputClasses = ["kwchipinput-input", cursorPos.type === "input" ? "focus" : "no-focus"];

    useEffect(() => {
        if (value.length === 0 && cursorPos.type !== "input") {
            setCursorPos({type: "input"});
        } else if (value.length > 0 && cursorPos.type === "reverse-chip-index") {
            setCursorPos({...cursorPos, index: Math.min(cursorPos.index, value.length - 1) });
        }
    }, [value]);

    useEffect(() => {
        if (chipWrapperRef.current) {
            const wrapper: HTMLDivElement = chipWrapperRef.current;
            if (wrapper.children.length > 0) {
                const index = cursorPos.type === "reverse-chip-index" ? cursorPos.index : 0;
                wrapper.children[wrapper.children.length - 1 - index].scrollIntoView({
                    behavior: "smooth",
                    block: "end",
                    inline: "end",
                });
            }
        }
    }, [value, cursorPos])

    useEffect(() => {
        if (!focus) {
            setCursorPos({type: "input"});
        }
    }, [focus])

    return <div className={classes.join(" ")}>
        <div ref={chipWrapperRef} className="kwchipinput-chip-wrapper"> {
            value.map((chip, i) =>
                <KWChip
                    focus={cursorPos.type === "reverse-chip-index" && i === value.length - 1 - cursorPos.index}
                    key={chip}
                    value={chip}
                    onDelete={() => onChange && onChange(removeByIndex(value, i))}
                />)
        } </div>
        <input {...inputProps}
            className={inputClasses.join(" ")}
            ref={inputRef}
            value={inputValue}
            role="textbox"
            onChange={event => setInputValue(event.target.value)}
            onFocus={() => setFocus(true)}
            onBlur={() => setFocus(false)}
            onKeyDown={event => {
                switch (cursorPos.type) {
                    case "input":
                        const start: number | null = event.target.selectionStart;
                        const end: number | null = event.target.selectionEnd;
                        switch (event.key) {
                            case "Enter":
                            case " ":
                            case ",":
                                event.preventDefault();
                                setInputValue("");
                                if (onChange && !value.includes(inputValue)) {
                                    onChange([...value, inputValue]);
                                }
                                break;
                            case "Backspace":
                            case "ArrowLeft":
                                if (start === 0 && end === 0) {
                                    event.preventDefault();
                                    setCursorPos({type: "reverse-chip-index", index: 0});
                                }
                                break;
                            default:
                            setCursorPos({type: "input"});
                        }
                        break;
                    case "reverse-chip-index":
                        const index = cursorPos.index;
                        switch (event.key) {
                            case "Backspace":
                            case "Delete":
                                event.preventDefault();
                                setCursorPos({...cursorPos, index: Math.min(value.length - 2, index)})
                                onChange && onChange(removeByIndex(value, value.length - 1 - index));
                                break;
                            case "ArrowLeft":
                                event.preventDefault();
                                setCursorPos({...cursorPos, index: Math.min(value.length - 1, index + 1)})
                                break;
                            case "ArrowRight":
                                event.preventDefault();
                                if (index === 0) {
                                    setCursorPos({type: "input"});
                                } else {
                                    setCursorPos({...cursorPos, index: index - 1})
                                }
                                break;
                            default:
                            setCursorPos({type: "input"});
                        }
                        break;
                }
            }}
        />
        {
            resettable ? (<div
                className="kwchipinput-reset"
                role="button"
                onClick={() => {
                    setInputValue("");
                    onChange && onChange([]);
                }}>
                {resetText}
            </div>
            ) : (<></>)
        }
        <div className="kwchipinput-outline"></div>
    </div >;
}

function assertUnreachable(): never {
    throw new Error("UnreacheableError");
}
