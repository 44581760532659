import { AxiosResponse } from "axios";
import { Activity, ActivityTimeseries, TaskType, Temperature } from "@kehrwasser/aldi-sued-dtm-openapi";
import { apiService } from "./apiService";

export type ActivityFilter = {
    query?: string,
    temperature?: Temperature,
    taskType?: TaskType,
} & PaginationFilter;

type PaginationFilter = {
    offset?: number,
    limit?: number,
}

export const activityProvider = {
    api: apiService.activity,

    get(filter?: ActivityFilter): Promise<AxiosResponse<Activity[], any>> {
        return this.api.getActivities(filter?.query, filter?.offset, filter?.limit, filter?.temperature, filter?.taskType);
    },

    getTimeseries(category: "am" | "ch" | "fr"): Promise<AxiosResponse<ActivityTimeseries, any>> {
        return this.api.getGraph(category);
    },

};
