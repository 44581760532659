import './TimetableModal.scss';
import { useState, useEffect } from 'react';
import PeriodModal, { defaultPeriodModalState, PeriodModalState } from '../periodModal/PeriodModal';
import SlotModal, { defaultSlotModalState, SlotModalState } from '../slotModal/SlotModal';
import { DeliveryTimeframe, Period, Slot } from '@kehrwasser/aldi-sued-dtm-openapi';
import { dateTimeToISOString, getById, getTimeStringFromDate, notify } from '../../../../../shared/helpers';
import AldiInput from '../../../../elementsAldi/input/AldiInput';
import AldiButton from '../../../../elementsAldi/button/AldiButton';
import { DateTime } from '../../../../../shared/datetime/DateTime';
import { useModal } from '../../../../../hooks/useModal';
import AldiGeneralModal from '../../../../elementsAldi/generalmodal/AldiGeneralModal';
import usePromise from '../../../../../hooks/usePromise';
import { deliveryTimeframesProvider } from '../../../../../shared/homeDelivery.service';

const BUFFER_TIME_MINUTES = 15;

export type UnvalidatedPeriod = {
    id: number,
    start?: string,
    end?: string,
    slots: Slot[],
}

export type UnvalidatedDeliveryTimeframe = {
    id: number,
    name: string,
    periods: UnvalidatedPeriod[],
}

export default function TimetableModal(props: {
    modal: { hide: () => void },
    deliveryTimeframes: DeliveryTimeframe[],
    onChange?: (value: DeliveryTimeframe, mode: "edit" | "create", index: number) => void,
    onDelete?: () => void,
    onCancel?: () => void,
    isLoading?: boolean,
    editIndex: number,
    editMode: "edit" | "create",
    editMinTime: DateTime,
    editMaxTime: DateTime,
}) {

    const { modal, deliveryTimeframes, onChange, onCancel, onDelete, isLoading = false, editIndex, editMode, editMinTime, editMaxTime } = props;

    const slots = [0, 1, 2, 3]

    const initialValues: UnvalidatedDeliveryTimeframe = {
        id: 0,
        name: "",
        periods: []
    }


    /* Helpers */
    const validate = () => {
        let isComplete = true;
        if (deliveryTimeframe.periods.filter(period => period.slots.length === 0).length > 0) {
            setShowPeriodWithoutTourValidation(true);
            isComplete = false;
        } else {
            setShowPeriodWithoutTourValidation(false);
        }
        if (deliveryTimeframe?.name?.length === 0) {
            setShowNameValidation(true);
            isComplete = false;
        } else {
            setShowNameValidation(false);
        }
        const periodsWithTime = deliveryTimeframe.periods.filter(period => period.start !== undefined && period.end !== undefined);
        if (periodsWithTime.length === 0) {
            setShowPeriodValidation(true);
            isComplete = false;
        } else {
            setShowPeriodValidation(false);
        }
        return isComplete;
    }

    /* State Definitions */
    const [deliveryTimeframe, setDeliveryTimeframe] = useState<UnvalidatedDeliveryTimeframe>(initialValues)
    const [rows, setRows] = useState<number[]>([0, 1, 2])
    /* period modal state */
    const [periodModalState, setPeriodModalState] = useState<PeriodModalState|null>(null);
    const periodModal = useModal();
    /* slot modal state */
    const [slotModalState, setSlotModalState] = useState<SlotModalState|null>(null);
    const slotModal = useModal();
    /* deletion */
    const promiseDelete = usePromise();
    const deleteModal = useModal();
    /* validation */
    const [showNameValidation, setShowNameValidation] = useState<boolean>(false)
    const [showPeriodValidation, setShowPeriodValidation] = useState<boolean>(false)
    const [showPeriodWithoutTourValidation, setShowPeriodWithoutTourValidation] = useState<boolean>(false)

    const canAddMoreSubdivisions = rows.length < 4;

    /* Effects */

    useEffect(() => {
        if (editMode === "create") {
            setRows([0, 1, 2])
            setDeliveryTimeframe(initialValues)
        } else {
            let values: UnvalidatedDeliveryTimeframe = deliveryTimeframes[editIndex] as UnvalidatedDeliveryTimeframe;
            if (values) {
                setRows(Array.from({ length: Math.max(values.periods.length, 3) }, (_: any, index: number) => index));
                setDeliveryTimeframe(values);
            } else {
                modal.hide();
            }
        }
    }, [editMode, editIndex, deliveryTimeframes]);

    useEffect(() => {
        if (editMode === "edit") {
            deleteModal.setModal(<AldiGeneralModal
                disabled={promiseDelete.isLoading}
                confirming={promiseDelete.isLoading}
                textCancelButton="Abbrechen"
                onCancel={deleteModal.hide}
                textConfirmButton="Löschen"
                onConfirm={() => {
                    let toBeDeleted = deliveryTimeframes[editIndex];
                    promiseDelete.setPromise(deliveryTimeframesProvider.delete(toBeDeleted)
                        .then(response => {
                            onDelete && onDelete();
                            notify('Das Auslieferzeitfenster wurde erfolgreich gelöscht.');
                            deleteModal.hide();
                            modal.hide();
                        }).catch(error => {
                            notify(`Ein Fehler ist aufgetreten.`);
                            console.debug(error);
                        }))
                }}>
                {`Soll dieses Auslieferzeitfenster gelöscht werden?`}
            </AldiGeneralModal>);
        } else {
            deleteModal.setModal(null);
        }
    }, [editMode, editIndex, deliveryTimeframes, promiseDelete.isLoading]);

    useEffect(() => {
        if (periodModalState) {
            periodModal.setModal(<PeriodModal
                state={periodModalState}
                onSubmit={handleSubmitPeriod}
                onDelete={handleDeletePeriod}
                onChange={(state: PeriodModalState) => setPeriodModalState(state)}
                onCancel={() => {
                    setPeriodModalState(null);
                    periodModal.hide();
                }} />)
        } else {
            periodModal.setModal(null);
        }
    }, [periodModalState]);

    useEffect(() => {
        if (slotModalState) {
            slotModal.setModal(<SlotModal
                state={slotModalState}
                onChange={state => setSlotModalState(state)}
                deliveryTimeframe={deliveryTimeframe}
                onSubmit={handleSubmitSlot}
                onCancel={() => {
                    setSlotModalState(null);
                    slotModal.hide();
                }}
                onDelete={handleDeleteSlot}/>)
        } else {
            slotModal.setModal(null);
        }
    }, [slotModalState]);

    /* Handlers */
    const handleChangeName = (name: string) => {
        setDeliveryTimeframe({ ...deliveryTimeframe, name });
    };

    const handleSubmit = () => {
        const validationSuccess = validate();
        if (validationSuccess && onChange) {
            const periods = deliveryTimeframe.periods
                .filter((period: UnvalidatedPeriod) => period.start && period.end)
                .map((period: UnvalidatedPeriod) => period as Period);
            const sortedPeriods = periods.sort((a: UnvalidatedPeriod, b: UnvalidatedPeriod) => {
                if (a.id < b.id) return -1;
                if (a.id > b.id) return 1;
                return 0;
            })
            if (periods.length > 0) {
                const newDeliveryTimeframe = { ...deliveryTimeframe, periods: sortedPeriods };

                onChange(newDeliveryTimeframe, editMode, editIndex);
            }
        }
    };

    const handleAddSubdivisionClick = () => {
        if (canAddMoreSubdivisions) {
            setRows([...rows, rows.length]);
        } else {
            notify(`Es können nur maximal 4 Unterteilungen angelegt werden.`);
        }
    };

    const handleAddPeriodButtonClick = (rowIndex: number) => {
        const earliestStarts = getEarliestStarts(getPeriodStartTimes(deliveryTimeframe, rows), editMinTime);
        const latestStarts = getLatestStarts(getPeriodStartTimes(deliveryTimeframe, rows), editMaxTime);
        setPeriodModalState(defaultPeriodModalState(rowIndex, earliestStarts[rowIndex], editMaxTime, latestStarts[rowIndex]));
        periodModal.show();
    };

    const handleEditPeriodButtonClick = (rowIndex: number) => {
        const startString = deliveryTimeframe.periods[rowIndex]?.start;
        const startTime = startString ? DateTime.fromISO(startString) ?? undefined : undefined;
        const endString = deliveryTimeframe.periods[rowIndex]?.end;
        const endTime = endString ? DateTime.fromISO(endString) ?? undefined : undefined;

        const startTimes = getPeriodStartTimes(deliveryTimeframe, rows);
        startTimes[rowIndex] = undefined;
        const earliestStarts = getEarliestStarts(startTimes, editMinTime);
        const latestStarts = getLatestStarts(startTimes, editMaxTime);
        setPeriodModalState({
            ...defaultPeriodModalState(rowIndex, earliestStarts[rowIndex], editMaxTime, latestStarts[rowIndex]),
            isEdit: true,
            startTime: startTime ?? earliestStarts[rowIndex],
            endTime: endTime ?? editMaxTime,
        });
        periodModal.show();
    };

    const handleOpenSlotModalAdd = (rowIndex: number, slotId: number) => {
        setSlotModalState(defaultSlotModalState(rowIndex, slotId));
        slotModal.show();
    };

    const handleOpenSlotModalEdit = (rowIndex: number, slotId: number) => {
        const period = deliveryTimeframe.periods.find(period => period.id === rowIndex);
        if (period) {
            const { slots } = period;
            const existingSlot = slots.find((slot: Slot) => slot.id === slotId);
            if (existingSlot) {
                const {tour, vehicle } = existingSlot;
                setSlotModalState({
                    ...defaultSlotModalState(rowIndex, slotId),
                    isEdit: true,
                    tour,
                    vehicle,
                });
                slotModal.show();
            }
        }
    };

    const handleSubmitSlot = () => {
        if (slotModalState) {
            if (slotModalState.tour && slotModalState.vehicle) {
            const slot: Slot = { id: slotModalState.slotId, tour: slotModalState.tour, vehicle: slotModalState.vehicle };
            const indexOfPeriodToChange = deliveryTimeframe.periods.findIndex((period: UnvalidatedPeriod) => period.id === slotModalState.rowIndex);
            const period: UnvalidatedPeriod = indexOfPeriodToChange >= 0
                ? deliveryTimeframe.periods[indexOfPeriodToChange]
                : {
                    id: slotModalState.rowIndex, slots: getById(deliveryTimeframe.periods, slotModalState.rowIndex)?.slots || [],
                };
            const newPeriod = { ...period, slots: [...period.slots, slot] };
            const newPeriods = indexOfPeriodToChange >= 0
                ? deliveryTimeframe.periods.map((period: UnvalidatedPeriod) => period.id === slotModalState.rowIndex ? newPeriod : period)
                : [...deliveryTimeframe.periods, newPeriod];
            setDeliveryTimeframe({ ...deliveryTimeframe, periods: newPeriods });
            }
            setSlotModalState(null);
            slotModal.hide();
        }
    }

    const handleDeleteSlot = () => {
        if (slotModalState) {
            setDeliveryTimeframe(timeframe => {
                const oldPeriod = timeframe.periods.find(period => period.id === slotModalState.rowIndex);
                if (oldPeriod) {
                    const newSlots = oldPeriod.slots.filter(slot => slot.id !== slotModalState.slotId);
                    const newPeriod: UnvalidatedPeriod = {...oldPeriod, slots: newSlots};
                    const newPeriods = timeframe.periods.map(period => period.id === slotModalState.rowIndex ? newPeriod : period)
                    return {...timeframe, periods: newPeriods};
                } else {
                    return timeframe;
                }
            })
            setSlotModalState(null);
            slotModal.hide();
        }
    }

    const handleSubmitPeriod = () => {
        if (periodModalState) {
            const slots = getById(deliveryTimeframe.periods, periodModalState.rowIndex)?.slots || [];
            const newPeriod: UnvalidatedPeriod = {
                id: periodModalState.rowIndex,
                start: dateTimeToISOString(periodModalState.startTime),
                end: dateTimeToISOString(periodModalState.endTime),
                slots,
            };

            const indexOfPeriodToChange = deliveryTimeframe.periods.findIndex((period: UnvalidatedPeriod) => period.id === periodModalState.rowIndex);
            const newPeriods = indexOfPeriodToChange >= 0
                ? deliveryTimeframe.periods.map((period: UnvalidatedPeriod) => period.id === periodModalState.rowIndex ? newPeriod : period)
                : [...deliveryTimeframe.periods, newPeriod];
            setDeliveryTimeframe({ ...deliveryTimeframe, periods: newPeriods });
            setPeriodModalState(null);
            periodModal.hide();
        }
    }

    const handleDeletePeriod = () => {
        if (periodModalState) {
            setDeliveryTimeframe(deliveryTimeframe => {
                return {
                    ...deliveryTimeframe,
                    periods: deliveryTimeframe.periods.filter(p => p.id !== periodModalState.rowIndex),
                }});
            setPeriodModalState(null);
            periodModal.show();
        }
    }

    /* Renders */
    const renderSlotButton = (rowIndex: number, slot: number) => {
        return (
            <AldiButton
                className="button-and-modal-tile add-slot-button button-tile"
                key={slot}
                onClick={() => handleOpenSlotModalAdd(rowIndex, slot)}>
                {["Tour und", "Fahrzeug", "auswählen"].map(line => (
                    <p className="add-slot-button-text">{line}</p>
                ))}
            </AldiButton>
        )
    }

    const renderSlotTile = (rowIndex: number, slot: number, plannedSlot: Slot) => {
        return (
            <div
                className="button-and-modal-tile modal-tile slot-tile"
                key={slot}
                onClick={() => handleOpenSlotModalEdit(rowIndex, slot)}>
                <p className="name">{plannedSlot.tour.name}</p>
                <p className="sub-line">Fahrzeug {plannedSlot.vehicle.id}</p>
            </div>
        )
    }

    const renderSlot = (rowIndex: number, period: UnvalidatedPeriod | undefined, slot: number) => {
        const plannedSlot = period?.slots.find((el: Slot) => el.id === slot);
        if (plannedSlot) {
            return renderSlotTile(rowIndex, slot, plannedSlot);
        } else {
            return renderSlotButton(rowIndex, slot);
        }
    }

    const renderTimeframeMatrix = (rowIndex: number) => {
        const period = deliveryTimeframe.periods.find((period: UnvalidatedPeriod) => period.id === rowIndex);
        if (period && period.start && period.end) {
            return (
                <div className="row-wrapper" key={rowIndex}>
                    <div className="button-and-modal-tile modal-tile timeframe-tile" onClick={() => handleEditPeriodButtonClick(rowIndex)}>
                        <p className="name">{deliveryTimeframe.name}</p>
                        <p className="sub-line">{`${getTimeStringFromDate(new Date(period.start))} - ${getTimeStringFromDate(new Date(period.end))}`}</p>
                    </div>
                    {slots.map((slot: number) => renderSlot(rowIndex, period, slot))}
                </div>
            )
        } else {
            return (
                <div className="row-wrapper" key={rowIndex}>
                    <AldiButton className="button-and-modal-tile add-timeframe-button button-tile" onClick={() => handleAddPeriodButtonClick(rowIndex)}>
                        <p className="add-timeframe-button-text">Zeitraum festlegen</p>
                    </AldiButton>
                    {slots.map((slot: number) => renderSlot(rowIndex, period, slot))}
                </div>
            )
        }
    }

    return (
        <div className="delivery-timetable-modal">
            <AldiGeneralModal
                title="Auslieferzeitfenster konfigurieren"
                subtitle="Bitte gib alle notwendigen Informationen an."
                width="60rem"
                textCancelButton="Abbrechen"
                onCancel={onCancel}
                enableDeleteButton={editMode === "edit"}
                textDeleteButton="Löschen"
                onDelete={deleteModal.show}
                textConfirmButton="Speichern"
                onConfirm={handleSubmit}
                confirming={isLoading}
                disabled={isLoading}
            >
                <p className="heading">
                    {"Name des Auslieferzeitfensters"}
                </p>
                <div className="input-row">
                    <div className="input-wrapper">
                        <AldiInput
                            name="name"
                            placeholder="Name"
                            onChange={value => handleChangeName(value)}
                            value={deliveryTimeframe?.name || ""}
                            disabled={isLoading}
                            error={true}
                        />
                    </div>
                    <div className="button-wrapper">
                        <AldiButton className="add-subdivision"
                            onClick={() => handleAddSubdivisionClick()}
                            kind="secondary"
                            disabled={isLoading || !canAddMoreSubdivisions}>
                            <p className="add-subdivision-text">
                                {"+ Unterteilung hinzufügen"}
                            </p>
                        </AldiButton>
                    </div>
                </div>
                {showNameValidation && <p className="validation">
                    {"Bitte gib einen Namen an"}
                </p>}

                <p className="heading">
                    {"Aufteilung des Auslieferzeitfensters"}
                </p>
                <div className="table-wrapper">
                    {rows.map((rowIndex: number) => renderTimeframeMatrix(rowIndex))}
                </div>
                {showPeriodValidation && <p className="validation">
                    {"Bitte lege mindestens einen Zeitraum fest"}
                </p>}
                {showPeriodWithoutTourValidation && <p className="validation">
                    {"Bitte wähle für jeden Zeitraum mindestens eine Tour und ein Fahrzeug aus"}
                </p>}
            </AldiGeneralModal>
        </div>
    )
}

function getPeriodStartTimes(timeframe: UnvalidatedDeliveryTimeframe, rows: number[]): (DateTime  | undefined)[] {
    return rows.map(id => {
        let period = timeframe.periods.find(p => p.id === id);
        if (period && period.start && period.end) {
            const startTime = DateTime.fromISO(period.start);
            return startTime ?? undefined;
        }
    });
}

function getEarliestStarts(startTimes: (DateTime | undefined)[], editMinTime: DateTime): DateTime[] {
    let earliestStarts = [];
    for (let i = 0; i < startTimes.length; ++i) {
        earliestStarts.push(editMinTime.plus({minutes: BUFFER_TIME_MINUTES * i}));
    }
    for (let i = 0; i < startTimes.length; ++i) {
        let start = startTimes[i];
        if (start) {
            const diffMinutes: number = (start.toMillis() - earliestStarts[i].toMillis()) / 1000 / 60;
            if (diffMinutes > 0) {
                for (let j = i; j < startTimes.length; ++j) {
                    earliestStarts[j] = earliestStarts[j].plus({ minutes: diffMinutes })
                }
            }
        }
    }
    return earliestStarts
}

function getLatestStarts(startTimes: (DateTime | undefined)[], editMaxTime: DateTime): DateTime[] {
    let latestStarts = [];
    for (let i = 0; i < startTimes.length; ++i) {
        latestStarts.push(editMaxTime.minus({minutes: BUFFER_TIME_MINUTES * i}));
    }
    latestStarts.reverse();
    for (let i = 0; i < startTimes.length; ++i) {
        let index = startTimes.length - 1 - i;
        let start = startTimes[index];
        if (start) {
            const diffMinutes: number = (start.toMillis() - latestStarts[index].toMillis()) / 1000 / 60;
            if (diffMinutes < 0) {
                for (let j = index; j > 0; --j) {
                    latestStarts[j] = latestStarts[j].plus({ minutes: diffMinutes })
                }
            }
        }
    }
    return latestStarts
}
