/*
 * Mini-Luxon
 *
 */

import { DurationSpec } from "./Duration";
import { Locale, Settings } from "./Settings";

/* January = 1, December = 12 */
export type MonthNumbers = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
export type DayNumbers = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14 | 15 | 16 | 17 | 18 | 19 | 20 | 21 | 22 | 23 | 24 | 25 | 26 | 27 | 28 | 29 | 30 | 31;
export type SecondNumbers = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14 | 15 | 16 | 17 | 18 | 19 | 20 | 21 | 22 | 23 | 24 | 25 | 26 | 27 | 28 | 29 | 30 | 31 | 32 | 33 | 34 | 35 | 36 | 37 | 38 | 39 | 40 | 41 | 42 | 43 | 44 | 45 | 46 | 47 | 48 | 49 | 50 | 51 | 52 | 53 | 54 | 55 | 56 | 57 | 58 | 59;
export type MinuteNumbers = SecondNumbers;
export type HourNumbers = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14 | 15 | 16 | 17 | 18 | 19 | 20 | 21 | 22 | 23;
export type WeekNumbers = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14 | 15 | 16 | 17 | 18 | 19 | 20 | 21 | 22 | 23 | 24 | 25 | 26 | 27 | 28 | 29 | 30 | 31 | 32 | 33 | 34 | 35 | 36 | 37 | 38 | 39 | 40 | 41 | 42 | 43 | 44 | 45 | 46 | 47 | 48 | 49 | 50 | 51 | 52 | 53;
/* Monday = 1, Sunday = 7 */
export type WeekdayNumbers = 1 | 2 | 3 | 4 | 5 | 6 | 7

type YMD = { year: number, month?: MonthNumbers, day?: DayNumbers };
type YW = { weekYear: number, weekNumber: WeekNumbers };

export type DateTimeSpec = YMD | YW;

export class DateTime {
    private readonly date: Date;
    private readonly locale: Locale;

    private constructor(date: Date) {
        this.date = date;
        this.locale = Settings.defaultLocale;
    }

    public get weekYear(): number {
        let year = this.year;
        let candidate1 = DateTime.firstDayOfWeekYear(year);
        let candidate2 = DateTime.firstDayOfWeekYear(year + 1);
        if (this.date < candidate1) {
            return year - 1;
        } else if (this.date < candidate2) {
            return year;
        } else {
            return year + 1;
        }
    };
    public get weekNumber(): WeekNumbers {
        let weekYear = this.weekYear;
        let firstDay = DateTime.firstDayOfWeekYear(weekYear);
        let i = 0;
        for (; i < 54; ++i) {
            if (firstDay > this.date) {
                break;
            }
            firstDay.setDate(firstDay.getDate() + 7);
        }
        return i as WeekNumbers;
    };
    public get weekday(): WeekdayNumbers { return this.date.getDay() === 0 ? 7 : this.date.getDay() as WeekdayNumbers }
    public get weekdayShort(): string { return Intl.DateTimeFormat(this.locale, { weekday: "short" }).format(this.date) }
    public get weekdayLong(): string { return Intl.DateTimeFormat(this.locale, { weekday: "long" }).format(this.date) }
    public get year(): number { return this.date.getFullYear() };
    public get month(): MonthNumbers { return this.date.getMonth() + 1 as MonthNumbers };
    public get day(): DayNumbers { return this.date.getDate() as DayNumbers };
    public get hour(): HourNumbers { return this.date.getHours() as HourNumbers };
    public get minute(): MinuteNumbers { return this.date.getMinutes() as MinuteNumbers };
    public get second(): SecondNumbers { return this.date.getSeconds() as SecondNumbers };
    public get millisecond(): number { return this.date.getMilliseconds() }
    public get monthShort(): string { return Intl.DateTimeFormat(this.locale, { month: "short" }).format(this.date) }
    public get monthLong(): string { return Intl.DateTimeFormat(this.locale, { month: "long" }).format(this.date) }

    /*
     * Constants
     */

    public static DATE_SHORT = { dateStyle: "short" };
    public static DATE_MEDIUM = { dateStyle: "medium" };

    /*
     * Contructors
     */

    public static now(): DateTime {
        return new DateTime(new Date());
    }

    public static fromMillis(milliseconds: number): DateTime {
        return DateTime.fromJSDate(new Date(milliseconds));
    }

    public static fromJSDate(date: Date): DateTime {
        return new DateTime(date);
    }

    public static fromISO(isoString: string): DateTime {
        return DateTime.fromJSDate(new Date(isoString))
    }

    public static fromObject(object: DateTimeSpec): DateTime {
        let hasType1: boolean = (object as YMD).year !== undefined;
        let hasType2: boolean = (object as YW).weekYear !== undefined;
        if (hasType1 && hasType2) {
            throw Error("illegal DateTime specification")
        } else if (hasType1) {
            let { year, month, day } = object as YMD;
            return new DateTime(new Date(year, ((month || 1) - 1), day || 1));
        } else { // hasType2
            let { weekYear, weekNumber } = object as YW;
            return new DateTime(DateTime.firstDayOfWeekYear(weekYear)).plus({ days: (weekNumber - 1) * 7 });
        }
    }

    /*
     * Public Methods
     */

    public plus({ years, months, weeks, days, hours, minutes, seconds, milliseconds }: DurationSpec): DateTime {
        let date = this.copy();
        if (years) {
            date.setFullYear(date.getFullYear() + years);
        }
        if (months) {
            date.setMonth(date.getMonth() + months);
        }
        if (weeks) {
            date.setDate(date.getDate() + weeks * 7);
        }
        if (days) {
            date.setDate(date.getDate() + days);
        }
        if (hours) {
            date.setHours(date.getHours() + hours);
        }
        if (minutes) {
            date.setMinutes(date.getMinutes() + minutes);
        }
        if (seconds) {
            date.setSeconds(date.getSeconds() + seconds);
        }
        if (milliseconds) {
            date.setMilliseconds(date.getMilliseconds() + milliseconds);
        }
        return new DateTime(date);
    }

    public minus({ years, months, weeks, days, hours, minutes, seconds, milliseconds }: DurationSpec): DateTime {
        return this.plus({
            years: years ? -years : undefined,
            months: months ? -months : undefined,
            weeks: weeks ? -weeks : undefined,
            days: days ? -days : undefined,
            hours: hours ? -hours : undefined,
            minutes: minutes ? -minutes : undefined,
            seconds: seconds ? -seconds : undefined,
            milliseconds: milliseconds ? -milliseconds : undefined,
        });
    }

    public startOf(interval: "month" | "week" | "day"): DateTime {
        switch (interval) {
            case "month":
                return this.minus({
                    days: this.date.getDate() - 1,
                    hours: this.date.getHours(),
                    minutes: this.date.getMinutes(),
                    seconds: this.date.getSeconds(),
                    milliseconds: this.date.getMilliseconds(),
                })
            case "week":
                let day = this.date.getDay();
                let isSunday = day === 0;
                let deltaDays = isSunday ? 6 : day - 1;
                return this.minus({
                    days: deltaDays,
                    hours: this.date.getHours(),
                    minutes: this.date.getMinutes(),
                    seconds: this.date.getSeconds(),
                    milliseconds: this.date.getMilliseconds(),
                })
            case "day":
                return this.minus({
                    hours: this.date.getHours(),
                    minutes: this.date.getMinutes(),
                    seconds: this.date.getSeconds(),
                    milliseconds: this.date.getMilliseconds(),
                })
            default:
                throw new Error("implementation error")
        }
    }

    public endOf(interval: "month" | "week" | "day"): DateTime {
        switch (interval) {
            case "month":
                return this.startOf("month").plus({ months: 1 }).minus({ milliseconds: 1 });
            case "week":
                return this.startOf("week").plus({ weeks: 1 }).minus({ milliseconds: 1 });
            case "day":
                return this.startOf("day").plus({ days: 1 }).minus({ milliseconds: 1 });
            default:
                throw new Error("implementation error")
        }
    }

    public toJSDate(): Date {
        return new Date(this.date);
    }

    public toUTCString(): string {
        return this.date.toUTCString();
    }

    public toLocaleString(options?: any): string {
        return Intl.DateTimeFormat(this.locale, options).format(this.date);
    }

    public toMillis(): number {
        return this.date.getTime()
    }

    public valueOf(): number {
        return this.toMillis()
    }

    /*
     * Private Methods
     */

    private copy(): Date {
        return new Date(this.date);
    }

    private static firstDayOfWeekYear(weekYear: number): Date {
        let janfourth = new Date(weekYear, 0, 4);
        let day = janfourth.getDay();
        let isSunday = day === 0;
        let firstWeek = new Date(janfourth);
        firstWeek.setDate(firstWeek.getDate() - (isSunday ? 6 : (day - 1)));
        return firstWeek;
    }
}
