import './AldiSelect.scss';
import { FormControl, InputLabel, Select, SelectChangeEvent, SelectProps } from '@mui/material';
import ResetButton from '../helpers/resetbutton/ResetButton';
import { ReactNode } from 'react';

type AldiSelectProps<ValueType> = {
    value: ValueType,
    resettable?: boolean,
    onChange?: (value: ValueType) => void,
    onReset?: () => void,
    renderValue?: (option: ValueType) => ReactNode,
} & Omit<SelectProps<ValueType>, "onChange" | "renderValue">;

function AldiSelect<ValueType>(props: AldiSelectProps<ValueType>) {

    const { resettable=false, children, onChange, onReset, value = "", ...selectProps } = props;

    const classes=["aldi-kwmc-select", resettable ? "resettable" : ""]

    function handleChange(event: SelectChangeEvent<ValueType>) {
        if (onChange) {
            onChange(event.target.value as ValueType)
        }
    }

    return (
        <div className={classes.join(" ")}>
            <FormControl fullWidth size="small">
                <InputLabel>{selectProps.label}</InputLabel>
                <Select {...selectProps}
                    value={value}
                    onChange={handleChange}>
                    {children}
                </Select>
            </FormControl>
            {resettable && <ResetButton onClick={onReset} />}
        </div>
    );
}

export default AldiSelect;
