import './FulfillmentActivitiesDataTable.scss';
import { Activity as ActivityInterface, Temperature } from '@kehrwasser/aldi-sued-dtm-openapi';
import { useEffect, useState } from 'react';

import { activityProvider } from '../../../../../shared/activities.service';
import Table from '../../../../elementsAldi/table/Table';
import TableHead from '../../../../elementsAldi/table/tableHead/TableHead';
import TableBody from '../../../../elementsAldi/table/tableBody/TableBody';
import TableRow from '../../../../elementsAldi/table/tableRow/TableRow';
import TableCell from '../../../../elementsAldi/table/tableCell/TableCell';
import usePromise, { PromiseStatus } from '../../../../../hooks/usePromise';
import AldiReloadButton from '../../../../elementsAldi/reloadbutton/AldiReloadButton';

interface FulfillmentActivitiesDataTableProps {
    temperature: 'am' | 'ch' | 'fr'
}

export default function FulfillmentActivitiesDataTable(props: FulfillmentActivitiesDataTableProps) {

    /* State Definitions */
    const [activities, setActivities] = useState<ActivityInterface[]>([])
    const promiseActivities = usePromise();

    /* Helpers */
    function loadActivities() {
        promiseActivities.setPromise(activityProvider.get().then(response => {
            setActivities(response.data.filter(activity => activity.temperature === props.temperature as Temperature));
        }).catch(error => {
            console.debug(error);
            return Promise.reject(error);
        }))
    }

    const percentage = (partialValue: number, totalValue: number) => {
        return Math.round((100 * partialValue) / totalValue);
    }

    /* Communication */
    useEffect(() => {
        loadActivities()
    }, [props.temperature])

    /* Renderers */
    const renderRow = (activity: ActivityInterface) => {
        const endTime = new Date(activity.dueTime)
        return (
            <TableRow key={activity.id}>
                <TableCell className='title-column-cell'>{activity.id}</TableCell>
                <TableCell>{activity.plan.toLocaleString('de-DE')}</TableCell>
                <TableCell>{activity.actual.toLocaleString('de-DE')}</TableCell>
                <TableCell>{percentage(activity.actual, activity.plan)}%</TableCell>
                <TableCell>{(activity.plan - activity.actual).toLocaleString('de-DE')}</TableCell>
                <TableCell>{endTime.toLocaleTimeString()}</TableCell>
            </TableRow>
        )
    }

    return (
        <div className="activities-data-table">
            <Table>
                <TableHead loading={promiseActivities.isLoading}>
                    <TableRow className="single-row-thead">
                        <TableCell>Aktivität</TableCell>
                        <TableCell>Soll</TableCell>
                        <TableCell>Ist</TableCell>
                        <TableCell>Fortschritt</TableCell>
                        <TableCell>Offen</TableCell>
                        <TableCell>Ende</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {promiseActivities.status === PromiseStatus.Failed && <TableRow>
                        <TableCell colSpan={6}>
                            <AldiReloadButton onClick={loadActivities} />
                        </TableCell>
                    </TableRow>}
                    {activities.map(activity => renderRow(activity))}
                </TableBody>
            </Table>
        </div>
    )
}
