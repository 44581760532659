import './Tile.scss';

const HeaderLayout = (props: {
    title?: string;
    onClick?: (event: React.MouseEvent<HTMLElement>) => void;
    variant?: string;
    className?: string;
    iconName?: string;
}) => {
    return (
        <div className={['tile', props.variant, props.className].join(' ')} onClick={e => props.onClick && props.onClick(e)}>
            <div className="title">{props.title}</div>
            <div className="content"></div>
            <i className={props.iconName + ' icon'}></i>
        </div>
    )
}

export default HeaderLayout;