import { AxiosResponse } from "axios";
import { Availability, Tour } from "@kehrwasser/aldi-sued-dtm-openapi";
import { apiService } from "./apiService";

const toursProvider = {

    api: apiService.tour,

    get(): Promise<AxiosResponse<Tour[], any>> {
        return this.api.getTours();
    },

    create(tour: Tour): Promise<AxiosResponse<Tour, any>> {
        return this.api.createTour(
            tour
        );
    },

    update(tour: Tour): Promise<AxiosResponse<Tour, any>> {
        return this.api.updateTour(
            tour.id,
            tour
        );
    },

    isTourNameAvailable(name: string): Promise<AxiosResponse<Availability, any>> {
        return this.api.isTourNameAvailable(name);
    },

};

export { toursProvider };
