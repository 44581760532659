import { AxiosResponse } from "axios";
import { CollectTimeframe, Week } from "@kehrwasser/aldi-sued-dtm-openapi";
import { WeekNumbers } from "./datetime/DateTime";
import { apiService } from "./apiService";


interface CollectTimeframeFilter {
    ids?: number[],
    cleveronIds?: number[],
    startAfter?: Date,
    startBefore?: Date,
}

const clickAndCollectProvider = {

    api: apiService.clickAndCollect,

    get({ ids, cleveronIds, startAfter, startBefore }: CollectTimeframeFilter): Promise<AxiosResponse<CollectTimeframe[], any>> {

        return this.api.getCollectTimeframes(ids, cleveronIds, startAfter?.toISOString(), startBefore?.toISOString());

    },

    delete(ids: number[]): Promise<AxiosResponse<void, any>> {

        return this.api.deleteCollectTimeframes(ids);

    },

    create(collectTimeframe: CollectTimeframe): Promise<AxiosResponse<CollectTimeframe, any>> {

        return this.api.createCollectTimeframe(collectTimeframe);
    },

    update(collectTimeframe: CollectTimeframe): Promise<AxiosResponse<CollectTimeframe, any>> {

        return this.api.updateCollectTimeframe(collectTimeframe);
    },

    getCollectWeek(weekYear: number, weekNumber: WeekNumbers): Promise<AxiosResponse<Week, any>> {

        return this.api.getCollectWeek(weekYear, weekNumber);

    },

    updateCollectWeek(weekYear: number, weekNumber: WeekNumbers, sourceWeekYear: number, sourceWeekNumber: WeekNumbers): Promise<AxiosResponse<Week, any>> {

        return this.api.patchCollectWeek(weekYear, weekNumber, sourceWeekYear, sourceWeekNumber);
    }

};

export { clickAndCollectProvider, type CollectTimeframe };
