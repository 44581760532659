import { AxiosResponse } from "axios";
import { Article } from "@kehrwasser/aldi-sued-dtm-openapi";
import { apiService } from "./apiService";

export interface ArticleFilter {
    q?: string;
    offset?: number;
    limit?: number;
}

export const articlesProvider = {

    api: apiService.article,

    get(input: string | ArticleFilter): Promise<AxiosResponse<Article[], any>> {

        let articleFilter: ArticleFilter;

        if (typeof input === 'string') {
            articleFilter = { id: input } as ArticleFilter;
        } else {
            articleFilter = input;
        }

        return this.api.getArticles(
            'picked',
            articleFilter.q,
            articleFilter.offset,
            articleFilter.limit
        );

    },

    update(articleId: string, article: Article): Promise<AxiosResponse<Article, any>> {

        return this.api.patchArticle(
            articleId,
            article
        );
    }

};
