import './TableBody.scss';

const TableBody = (props: {
    children?: React.ReactNode,
    className?: string
}) => {
    return (
        <tbody {...props} className={`${props.className} aldi-tbody`}>{props.children}</tbody>
    )
}

export default TableBody;