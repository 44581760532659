import './Location.scss';
import { useLocation } from "react-router-dom";
import { ReactComponent as IconClickAndCollect } from '../../../../assets/images/custom-icons/click-and-collect.svg';

interface PathInfo {
    title: string,
    icon: JSX.Element
}

const Location = (props: {
    user?: any;
    onChange?: (value: string) => void;
}) => {

    const location = useLocation();

    const paths = new Map<string, PathInfo>();
    paths.set('/dashboard', { title: 'Dashboard', icon: <IconClickAndCollect/> });
    paths.set('/fulfillment/activities', { title: 'Tages-Übersicht', icon: <IconClickAndCollect /> });
    paths.set('/fulfillment/orders', { title: 'Kundenbestellungen', icon: <IconClickAndCollect /> });
    paths.set('/fulfillment/cluster-overview', { title: 'Cluster-Übersicht', icon: <IconClickAndCollect /> });
    paths.set('/fulfillment/tasks', { title: 'Aufgaben-Übersicht', icon: <i className='ri-task-line icon' /> });
    paths.set('/configuration/replenishment', { title: 'Darkstore Konfiguration', icon: <IconClickAndCollect /> });
    paths.set('/configuration/inventory-tasks', { title: 'Darkstore Konfiguration', icon: <IconClickAndCollect /> });
    paths.set('/configuration/emergency-picking', { title: 'Darkstore Konfiguration', icon: <IconClickAndCollect /> });
    paths.set('/click-and-collect/cleverons', { title: 'Cleveron-Übersicht', icon: <IconClickAndCollect /> });
    paths.set('/click-and-collect/timetable-cleverons', { title: 'Abholzeitfenster-Übersicht', icon: <IconClickAndCollect /> });
    paths.set('/home-delivery/tour-planning', { title: 'Touren-Übersicht', icon: <IconClickAndCollect /> });
    paths.set('/home-delivery/timetable-home-delivery', { title: 'Auslieferzeitfenster-Übersicht', icon: <IconClickAndCollect /> });
    paths.set('/storage-places/locations', { title: 'Lagerplätze-Übersicht', icon: <IconClickAndCollect /> });

    return (
        <div className="location">
            {paths.get(location.pathname)?.title}
        </div>
    )
}

export default Location;
