import { useOutsideClick } from "../../../hooks/useOutsideClick";
import { DateTime } from "../../../shared/datetime/DateTime";
import { skipSunday, toDateTime, Week } from "../../../shared/helpers";
import { WeekRange } from "../../elements/weekrangepicker/types";
import AldiButton from "../button/AldiButton";
import TextfieldWithAdornments from "../textfieldWithAdornments/textfieldWithAdornments";
import WeekRangePicker from "../weekrangepicker/WeekRangePicker";
import "./WeekRangeInput.scss";

export type WeekRangeInputProps = {
    currentTime?: DateTime,
    value: WeekRange,
    focus?: boolean,
    onChange?: (value: WeekRange) => void,
    onFocusChange?: (focus: boolean) => void,
}

export default function WeekRangeInput({
    currentTime = DateTime.now(),
    value,
    focus = false,
    onChange,
    onFocusChange,
}: WeekRangeInputProps) {
    const startWeekDisplay: string = value.startWeek && toDateTime(value.startWeek).startOf("week").toLocaleString(DateTime.DATE_SHORT) || "";
    const startWeekAdornment: string = value.startWeek ? `KW ${value.startWeek.weekNumber}` : "";
    const endWeekDisplay: string = value.endWeek && toDateTime(value.endWeek).endOf("week").toLocaleString(DateTime.DATE_SHORT) || "";
    const endWeekAdornment: string = value.endWeek ? `KW ${value.endWeek.weekNumber}` : "";
    const insideRef = useOutsideClick(() => onFocusChange && onFocusChange(false));

    const handleClickResetWeek = (time: string) => {
        if (onChange) {
            if (time === "start") {
                onChange(getDefaultWeekRange(currentTime));
            } else if (time === "end") {
                onChange({ ...value, endWeek: value.startWeek });
            }
        }
    };

    const renderResetWeekButton = (time: string) => {
        return (<AldiButton className="reset-week-button" onClick={() => handleClickResetWeek(time)}>
            <i className="ri-close-circle-line icon-reset-week"></i>
        </AldiButton>)
    }

    return (
        <div ref={insideRef as any} className="weekrangeinput">
            <TextfieldWithAdornments
                data-testid="weekrangeinput-start-week-input"
                label="Startdatum"
                onFocus={() => onFocusChange && onFocusChange(true)}
                startAdornment={startWeekAdornment}
                value={startWeekDisplay}
                endAdornment={renderResetWeekButton("start")} />
            <TextfieldWithAdornments
                label="Enddatum"
                onFocus={() => onFocusChange && onFocusChange(true)}
                startAdornment={endWeekAdornment}
                value={endWeekDisplay}
                endAdornment={renderResetWeekButton("end")} />
            {
                focus &&
                <WeekRangePicker
                    data-testid="weekrangeinput-calendars"
                    className="weekrangeinput-picker"
                    value={value}
                    onChange={onChange} />
            }
        </div>

    );
}


export function getDefaultWeekRange(currentTime: DateTime) {
    const defaultWeek = getDefaultWeek(currentTime);
    return { startWeek: defaultWeek, endWeek: defaultWeek };
}


function getDefaultWeek(currentTime: DateTime): Week {
    let { weekYear, weekNumber } = skipSunday(currentTime);
    return { weekYear, weekNumber };
};
