import './AldiGeneralModal.scss';
import AldiButton from '../button/AldiButton';

type ButtonKind = 'primary' | 'secondary' | 'tertiary' | 'danger' | 'ghost';

type AldiGeneralModalProps = {
    confirming?: boolean,
    title?: string,
    subtitle?: string,
    enableDeleteButton?: boolean,
    disabled?: boolean,
    onCancel?: () => void,
    onConfirm?: () => void,
    onDelete?: () => void,
    textCancelButton?: string,
    textConfirmButton?: string,
    textDeleteButton?: string,
    submitKind?: ButtonKind,
    cancelKind?: ButtonKind,
    deleteKind?: ButtonKind,
    children?: React.ReactNode,
    width?: string | number,
}

function AldiGeneralModal(props: AldiGeneralModalProps) {
    const {
        confirming = false,
        title,
        subtitle,
        children,
        width,
        enableDeleteButton = false,
        onConfirm,
        onCancel,
        onDelete,
        textCancelButton = "Abbrechen",
        textConfirmButton = "Ok",
        textDeleteButton = "Löschen",
        disabled = false,
        submitKind = "primary",
        cancelKind = "secondary",
        deleteKind = "danger",
    } = props;

    return (
        <div className="aldi-general-modal-background">
            <div className="general-modal-wrapper" style={width ? { width: width } : undefined}>
                <h1 className="title">{title}</h1>
                {subtitle && <p className="subtitle">{subtitle}</p>}
                <div className="content-wrapper">
                    {children}
                </div>
                <div className="button-row">
                    {enableDeleteButton &&
                        <div className="button-wrapper">
                            <AldiButton kind={deleteKind} onClick={onDelete} disabled={disabled}>{textDeleteButton}</AldiButton>
                        </div>}
                    <div className="button-wrapper">
                        <AldiButton kind={cancelKind} onClick={onCancel} disabled={disabled} data-testid="close-modal">{textCancelButton}</AldiButton>
                    </div>
                    <div className="button-wrapper">
                        <AldiButton kind={submitKind} onClick={onConfirm} type="submit" loading={confirming} disabled={disabled} data-testid={"submit-modal"}>{textConfirmButton}</AldiButton>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default AldiGeneralModal;

