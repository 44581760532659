import React, { useState } from "react";

export const ModalContext = React.createContext({} as ModalContextType)

export type ModalContextType = {
    stack: [UniqueId, JSX.Element][],
    register: (id: UniqueId) => void,
    update: (id: UniqueId, modal: null | JSX.Element) => void,
    unregister: (id: UniqueId) => void,
    clear: () => void,
}

type UniqueId = string;


export function useModalContext(): ModalContextType {
    const [modals, setModals] = useState<Map<UniqueId, JSX.Element>>(new Map());
    const [modalStack, setModalStack] = useState<UniqueId[]>([]);
    const stack: [UniqueId, JSX.Element][] = modalStack.map(id => [id, modals.get(id)]).filter(modal => modal[1] !== undefined) as [UniqueId, JSX.Element][];

    const register = (id: UniqueId) => {
        setModalStack(stack => [...stack, id]);
    }

    const unregister = (id: UniqueId) => {
        setModalStack(stack => stack.filter(id0 => id0 !== id))
        setModals(modals0 => {
            let modals = new Map(modals0);
            modals.delete(id);
            return modals;
        })
    }

    const update = (id: UniqueId, modal: null | JSX.Element) => {
        setModals(modals0 => {
            let modals = new Map(modals0);
            if (modal) {
                modals.set(id, modal);
            } else if (modals.get(id)) {
                modals.delete(id);
            }
            return modals;
        })
    }

    const clear = () => {
        setModals(new Map());
        setModalStack([]);
    }

    return { stack, register, update, unregister, clear };
}
