import Table from '../../elements/table/Table';
import './Table.scss';

const AldiTable = (props: any) => {
    const {className, ...other} = props

    return (
        <Table {...other} className={`${className} aldi-table`}></Table>
    )
}

export default AldiTable;