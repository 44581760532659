import { Checkbox, CheckboxProps } from '@mui/material';
import './AldiCheckbox.scss';


export type AldiCheckboxProps = CheckboxProps;

export default function AldiCheckbox({disableRipple = true, ...props}: CheckboxProps) {
  return (
    <span className={'aldi-checkbox-wrapper'}>
        <Checkbox {...props} disableRipple={disableRipple} />
    </span>
  );
};
