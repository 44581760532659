import { useRef, useEffect } from "react";

export const useOutsideClick = (callback: any) => {
    const ref = useRef();

    useEffect(() => {
        const handleClick = (event: Event) => {
            if (ref.current && !(ref.current as any).contains(event.target)) {
                callback();
            }
        };

        document.addEventListener('click', handleClick, true);

        return () => {
            document.removeEventListener('click', handleClick, true);
        };
    }, [ref]);

    return ref;
};
