const getEnv = (name: string, defaultValue: string): string => {
    const env = (window as any).env;
    return env?.[name] || process.env[name] || defaultValue;
}

const getEnvInteger = (name: string, defaultValue: number): number => {
    return parseInt(getEnv(name, defaultValue.toString()))
}

const getEnvBoolean = (name: string, defaultValue: boolean): boolean => {
    const value = getEnv(name, defaultValue.toString());
    if (value === "true") {
        return true;
    } else if (value === "false") {
        return false
    } else {
        throw new Error(`invalid value for environment variable ${name} = ${value}`);
    }
}


export const config = {
    DELAY_DAYS_TO_EDIT_TIMETABLE: getEnvInteger("DELAY_DAYS_TO_EDIT_TIMETABLE", 1),
    TIMETABLE_EDIT_AVOIDANCE_ENABLED: getEnvBoolean("TIMETABLE_EDIT_AVOIDANCE_ENABLED", true),
    ERROR_TEXT_CONTENT_LOADING: getEnv("ERROR_TEXT_CONTENT_LOADING", "An error occurred loading this content"),
    MINIMUM_GAP_FOR_DELIVERY_TIMEFRAME_MINUTES: getEnvInteger("MINIMUM_GAP_FOR_DELIVERY_TIMEFRAME_MINUTES", 120),
}
