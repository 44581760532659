import './HeaderLayout.scss';
import { useNavigate } from "react-router-dom";
import Location from './location/Location';
import React, { useEffect, useState } from 'react';
import { useAuth } from '../../context/AuthProvider';
import { ModalContext } from '../../context/ModalProvider';
import { MenuItem } from '@mui/material';
import ALDIAccountMenu from '../../elementsAldi/accountMenu/accountMenu';
import AldiGeneralModal from '../../elementsAldi/generalmodal/AldiGeneralModal';
import { useModal } from '../../../hooks/useModal';

const HeaderLayout = (props: {
    onChange?: (value: string) => void;
}) => {
    let auth = useAuth();

    /* State Definitions */
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

    let fakeUser = auth.session === null ? null : {
        displayName: "Pseudo-Benutzer",
    };

    const navigate = useNavigate();
    const modalContext = React.useContext(ModalContext);
    const logoutModal = useModal();
    useEffect(() => {
        logoutModal.setModal(<AldiGeneralModal
            onCancel={logoutModal.hide}
            textConfirmButton="Logout"
            onConfirm={logout}>
            { `Möchten Sie sich wirklich vom Darkstore Task Management abmelden?`}
        </AldiGeneralModal>)
    }, [])

    function logout() {
        modalContext.clear();
        navigate('/logout');
    }

    const handleLogout = (event: React.MouseEvent) => {
        event.preventDefault();
        logoutModal.show(); 
    }

    const handleLogin = (event: React.MouseEvent) => {
        event.preventDefault();
        navigate('/login');
    }

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
      };
      const handleClose = () => {
        setAnchorEl(null);
      };
      const open = Boolean(anchorEl);

    return (
        <header className="main">

            <div className="header-secondary">
                <div className="back" onClick={() => navigate(-1)}>
                    <i className="ri-arrow-left-line"></i>
                </div>
                <Location />
                <div className="user">
                    <div className="user-name" onClick={handleClick}><i className="ri-account-circle-fill user-icon"></i></div>
                    {fakeUser ? (
                        <ALDIAccountMenu
                            anchorEl={anchorEl}
                            open={open}
                            handleClose={handleClose}>
                            <MenuItem disabled>{fakeUser.displayName}</MenuItem>
                            <MenuItem onClick={handleLogout}>{"Abmelden"}</MenuItem>
                        </ALDIAccountMenu>
                    ) : (
                        <ALDIAccountMenu
                            anchorEl = { anchorEl }
                            open = { open }
                            handleClose = { handleClose }>
                            <MenuItem onClick = { handleLogin }>Sign In</MenuItem>
                        </ALDIAccountMenu>
                    )}
                </div>
            </div>
        </header>
    )
}

export default HeaderLayout;
