import { useState } from 'react';
import KWWeekPicker from './weekpicker/KWWeekPicker';
import './KWWeekRangePicker.scss';
import { WeekRange } from './types';
import { DateTime } from '../../../shared/datetime/DateTime';
import { toDateTime, YearMonthDay } from '../../../shared/helpers';

type KWWeekRangePickerProps = {
    className?: string,
    defaultMonth?: Date | DateTime | YearMonthDay,
    value: WeekRange,
    onChange?: (value: WeekRange) => void,
    onClick?: () => void,
    style?: any,
}

function KWWeekRangePicker({
    className,
    defaultMonth,
    onChange,
    onClick,
    value,
    ...divProps
}: KWWeekRangePickerProps) {
    const defaultMonthDateTime = defaultMonth ? toDateTime(defaultMonth) : DateTime.now();

    const [month, setMonth] = useState<DateTime>(defaultMonthDateTime.startOf('month'));

    const handleChange = (value: WeekRange) => {
        onChange && onChange(value)
    }

    return (
        <div
            {...divProps}
            className={(className ? className + ' ' : '') + 'kw-week-range-picker'}
            onClick={onClick} 
            data-testid="weekrange-picker">
            <KWWeekPicker
                className='kw-weekpicker-left'
                month={month}
                value={value}
                showPreviousMonth
                onMonthChange={setMonth}
                onChange={handleChange} />
            <div className="kw-separator"></div>
            <KWWeekPicker
                className='kw-weekpicker-right'
                month={month.plus({ months: 1 })}
                value={value}
                showNextMonth
                onMonthChange={month => setMonth(month.minus({ months: 1 }))}
                onChange={handleChange} />
        </div>
    )
}

export default KWWeekRangePicker;
export { type KWWeekRangePickerProps };
