import { DateTime } from "./datetime/DateTime";

type Cookies = Map<string, string>;

function parseCookies(): Cookies {
    let allCookies = document.cookie;
    let list = allCookies.split("; ");
    let cookies: Cookies = new Map();
    for (var i = 0; i < list.length; ++i) {
        let keyValuePair = list[i].split("=");
        let key = keyValuePair[0];
        let value = keyValuePair[1];
        cookies.set(key, value);
    }
    return cookies;
}

function setSessionCookie(session: string) {
    let expirationDate = DateTime.now().plus({ days: 30 });
    document.cookie = `session=${session}; Expires=${expirationDate.toUTCString()}; SameSite=Strict; Secure`;
}

function clearSessionCookie() {
    let expirationDate = DateTime.now().minus({ days: 1 });
    document.cookie = `session=; Expires=${expirationDate.toUTCString()}; SameSite=Strict; Secure`;
}


export { parseCookies, setSessionCookie, clearSessionCookie, type Cookies }
