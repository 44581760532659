import './TableFoot.scss';

const TableFoot = (props: {
    children?: React.ReactNode
}) => {
    return (
        <tfoot>{props.children}</tfoot>
    )
}

export default TableFoot;