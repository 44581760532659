import './KWOverflowMenu.scss';
import { styled } from '@mui/material/styles';


import * as React from 'react';
import MenuUnstyled, { MenuUnstyledActions } from '@mui/base/MenuUnstyled';
import { buttonUnstyledClasses } from '@mui/base/ButtonUnstyled';
import { StyledListbox, StyledPopper } from '../StyledComponents';

const blue = {
    100: '#DAECFF',
    200: '#99CCF3',
    400: '#3399FF',
    500: '#007FFF',
    600: '#0072E5',
    900: '#003A75',
};

const grey = {
    100: '#E7EBF0',
    200: '#E0E3E7',
    300: '#CDD2D7',
    400: '#B2BAC2',
    500: '#A0AAB4',
    600: '#6F7E8C',
    700: '#3E5060',
    800: '#2D3843',
    900: '#1A2027',
};

const TriggerButton = styled('button')(
    ({ theme }) => `
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 2.5rem;
    width: 2.5rem;
    border: none;
    padding: 0px;
    gap: 10px;
    background: transparent;
    left: 164px;
    top: 4px;
    color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
    cursor: pointer;

  &:hover {
    background: ${theme.palette.mode === 'dark' ? '' : grey[100]};
    border-color: ${theme.palette.mode === 'dark' ? grey[700] : grey[400]};
    box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.17);
    background: #fff;
  }

  &.${buttonUnstyledClasses.focusVisible} {
    outline: 3px solid ${theme.palette.mode === 'dark' ? blue[600] : blue[100]};
  }
  `,
);

function KWOverflowMenu(props: any) {
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const isOpen = Boolean(anchorEl);
    const buttonRef = React.useRef<HTMLButtonElement>(null);
    const menuActions = React.useRef<MenuUnstyledActions>(null);
    const preventReopen = React.useRef(false);

    const handleButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        if (preventReopen.current) {
            event.preventDefault();
            preventReopen.current = false;
            return;
          }

        if (isOpen) {
            setAnchorEl(null);
        } else {
            setAnchorEl(event.currentTarget);
        }
    };

    const handleButtonMouseDown = () => {
        if (isOpen) {
          // Prevents the menu from reopening right after closing
          // when clicking the button.
          preventReopen.current = true;
        }
      };

    const close = () => {
        setAnchorEl(null);
        buttonRef.current!.focus();
    };

    return (
        <div {...props}>
            <TriggerButton
                type="button"
                onClick={handleButtonClick}
                onMouseDown={handleButtonMouseDown}
                ref={buttonRef}
                style={isOpen ? {
                    background: "#fff",
                    borderColor: grey[400],
                    boxShadow: '0px 2px 3px rgba(0, 0, 0, 0.17)'
                } : {}}
            >
                <i className="ri-more-2-line"></i>
            </TriggerButton>

            <MenuUnstyled
                actions={menuActions}
                open={isOpen}
                onClose={close}
                anchorEl={anchorEl}
                components={{ Root: StyledPopper, Listbox: StyledListbox }}
                componentsProps={{ listbox: { id: 'simple-menu' }, root: { placement: "bottom-end"} }}
            >
                {props.children}
            </MenuUnstyled>
        </div>
    );
}

export default KWOverflowMenu

