import { spawn } from 'child_process';
import './TableCell.scss';

enum Kind {
    Default = 'default',
    Header = 'header'
}

const TableCell = (props: any) => {
    return (
        <td {...props}>
            {props.children}
            {props.subtext && <span className="headline-subtext"><br />{props.subtext}</span>}
        </td>
    )
}

export default TableCell;