import * as React from 'react';
import Switch from '@mui/material/Switch';


export default function Toggle(props: any) {
  return (
    <div>
      <Switch 
        {...props} 
        sx={{ 
            '& .MuiSwitch-switchBase.Mui-checked': {
              color: "#3FC0F0",
              '&:hover': {
                backgroundColor: "#3FC0F020",
              },
            },
            '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
              backgroundColor: "#3FC0F0",
            }, 
        }} />
    </div>
  );
}