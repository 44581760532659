import Table from '../../../../elementsAldi/table/Table';
import TableHead from '../../../../elementsAldi/table/tableHead/TableHead';
import TableBody from '../../../../elementsAldi/table/tableBody/TableBody';
import TableRow from '../../../../elementsAldi/table/tableRow/TableRow';
import TableCell from '../../../../elementsAldi/table/tableCell/TableCell';
import { useEffect, useState } from "react";
import usePromise, { PromiseStatus } from "../../../../../hooks/usePromise";
import { Activity, TaskType, Temperature } from "@kehrwasser/aldi-sued-dtm-openapi";
import { activityProvider } from "../../../../../shared/activities.service";
import AldiReloadButton from "../../../../elementsAldi/reloadbutton/AldiReloadButton";
import { taskTypeLabel, temperatureLabel } from '../../tasks/FulfillmentTasksPage';


type OverviewData = {
    temperature: Temperature,
    picking: Activity[],
    qmpicking: Activity[],
    crossdock: Activity[],
}

export default function FulfillmentActivitiesOverview() {
    const promiseOverviewData = usePromise();
    const [overviewData, setOverviewData] = useState<OverviewData[]>([]);

    /* Effects */

    useEffect(() => {
        loadActivities();
    }, [])

    /* Helper */

    function loadActivities() {
        const promises = [
            activityProvider.get({temperature: Temperature.Am, taskType: TaskType.Picking}),
            activityProvider.get({temperature: Temperature.Am, taskType: TaskType.QmPicking}),
            activityProvider.get({temperature: Temperature.Am, taskType: TaskType.Crossdock}),
            activityProvider.get({temperature: Temperature.Ch, taskType: TaskType.Picking}),
            activityProvider.get({temperature: Temperature.Ch, taskType: TaskType.QmPicking}),
            activityProvider.get({temperature: Temperature.Ch, taskType: TaskType.Crossdock}),
            activityProvider.get({temperature: Temperature.Fr, taskType: TaskType.Picking}),
            activityProvider.get({temperature: Temperature.Fr, taskType: TaskType.QmPicking}),
            activityProvider.get({temperature: Temperature.Fr, taskType: TaskType.Crossdock}),
        ];
        promiseOverviewData.setPromise(Promise.all(promises)
            .then(response => {
                setOverviewData([
                    {
                        temperature: Temperature.Am,
                        picking: response[0].data,
                        qmpicking: response[1].data,
                        crossdock: response[2].data,
                    },
                    {
                        temperature: Temperature.Ch,
                        picking: response[3].data,
                        qmpicking: response[4].data,
                        crossdock: response[5].data,
                    },
                    {
                        temperature: Temperature.Fr,
                        picking: response[6].data,
                        qmpicking: response[7].data,
                        crossdock: response[8].data,
                    },
                ])
            })
            .catch(error => {
                console.debug(error);
                return Promise.reject(error);
            }))
    }

    /* Handler */

    /* Render */

    return (<>
        {promiseOverviewData.status === PromiseStatus.Failed && <AldiReloadButton onClick={loadActivities} />}

        {overviewData.map(overview => {
            return (
                <>
                    <h3>{temperatureLabel(overview.temperature)}</h3>
                    <div className="activities-data-table">
                        <Table>
                            <TableHead loading={promiseOverviewData.isLoading}>
                                <TableRow className="single-row-thead">
                                    <TableCell>Task-Typ</TableCell>
                                    <TableCell>Aktivität</TableCell>
                                    <TableCell>Soll</TableCell>
                                    <TableCell>Ist</TableCell>
                                    <TableCell>Fortschritt</TableCell>
                                    <TableCell>Offen</TableCell>
                                    <TableCell>Ende</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {[
                                    { taskType: TaskType.Picking, activities: overview.picking },
                                    { taskType: TaskType.QmPicking, activities: overview.qmpicking },
                                    { taskType: TaskType.Crossdock, activities: overview.crossdock },
                                ].map(({taskType, activities}) => {
                                    const activity: Activity = activities[0];
                                    const endTime = new Date(activity.dueTime)
                                    return (
                                        <TableRow>
                                            <TableCell>{taskTypeLabel(taskType)}</TableCell>
                                            <TableCell className='title-column-cell'>{activity.id}</TableCell>
                                            <TableCell>{activity.plan.toLocaleString('de-DE')}</TableCell>
                                            <TableCell>{activity.actual.toLocaleString('de-DE')}</TableCell>
                                            <TableCell>{Math.round((activity.actual / activity.plan) * 100)}%</TableCell>
                                            <TableCell>{(activity.plan - activity.actual).toLocaleString('de-DE')}</TableCell>
                                            <TableCell>{endTime.toLocaleTimeString()}</TableCell>
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </div>
                    </>
            );
        })}
        </>
    );
}
