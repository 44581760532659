import { DateTime } from "./DateTime";
import { DurationSpec } from "./Duration";

export class Interval {
    readonly start: DateTime;
    readonly end: DateTime;

    constructor(start: DateTime, end: DateTime) {
        this.start = start;
        this.end = end;
    }

    public contains(date: DateTime): boolean {
        return this.start <= date && date <= this.end;
    }

    public splitBy(duration: DurationSpec): Interval[] {
        let intervals: Interval[] = [];
        let start = this.start;
        let end = start.plus(duration)
        while (end <= this.end) {
            intervals.push(Interval.fromDateTimes(start, end));
            start = end;
            end = start.plus(duration);
        }
        if (start < this.end) {
            intervals.push(Interval.fromDateTimes(start, end));
        }
        return intervals;
    }

    public static fromDateTimes(start: DateTime, end: DateTime): Interval {
        return new Interval(start, end);
    }
}
