import './AldiButton.scss';

export type AldiButtonProps = any;

export default function AldiButton(props: AldiButtonProps) {
    const { kind = "", loading, className, ...rest } = props;

    const classes = loading
        ? ["aldi-button", kind, className, "loading-indicator"]
        : ["aldi-button", kind, className];

    return (
        <button {...rest} className={classes.join(" ")} />
    )
}
