import { useContext, useEffect, useId, useState } from "react";
import { ModalContext } from "../components/context/ModalProvider";

export function useModal() {
    const modalContext = useContext(ModalContext);
    const [modal, setModal] = useState<null | JSX.Element>(null);
    const [showModal, setShowModal] = useState<boolean>(false);
    const id: string = useId();

    useEffect(() => {
        modalContext.register(id);
        return () => modalContext.unregister(id);
    }, []);

    useEffect(() => {
        if (showModal) {
            modalContext.update(id, modal);
        } else {
            modalContext.update(id, null);
        }
    }, [modal, showModal]);

    const hide = () => {
        setShowModal(false);
    };
    const show = () => {
        setShowModal(true);
    };
    return { id, setModal, hide, show }
}
