import './TimetableCleveronsPage.scss';
import { Cleveron, Week as WeekApi } from '@kehrwasser/aldi-sued-dtm-openapi';
import { useEffect, useState } from 'react';
import Table from '../../../elementsAldi/table/Table';
import TableHead from '../../../elementsAldi/table/tableHead/TableHead';
import TableBody from '../../../elementsAldi/table/tableBody/TableBody';
import TableRow from '../../../elementsAldi/table/tableRow/TableRow';
import TableCell from '../../../elementsAldi/table/tableCell/TableCell';
import { CollectTimeframe, clickAndCollectProvider } from '../../../../shared/clickAndCollect.service';
import TimetableModal from './timetableModal/TimetableModal';
import { cleveronsProvider } from '../../../../shared/cleverons.service';
import { config } from '../../../../shared/configuration';
import { DateTime, WeekdayNumbers } from '../../../../shared/datetime/DateTime';
import { filterUndefined, notify, toDateTime } from '../../../../shared/helpers';
import { Interval } from '../../../../shared/datetime/Interval';
import { WeekRange } from '../../../elements/weekrangepicker/types';
import AldiButton from '../../../elementsAldi/button/AldiButton';
import { createForm, Form, validateForm } from './timetableModal/form';
import { weekdaysFor } from './timetable';
import usePromise, { PromiseStatus } from '../../../../hooks/usePromise';
import { AxiosResponse } from 'axios';
import WeekRangeInput, { getDefaultWeekRange } from '../../../elementsAldi/weekrangeinput/WeekRangeInput';
import AldiGeneralModal from '../../../elementsAldi/generalmodal/AldiGeneralModal';
import { useModal } from '../../../../hooks/useModal';
import AldiReloadButton from '../../../elementsAldi/reloadbutton/AldiReloadButton';

export default function TimetableCleveronsPage(props: { currentTime?: DateTime }) {
    const currentTime = props.currentTime ?? DateTime.now();

    /* State Definitions */
    const [collectTimeframes, setCollectTimeframes] = useState<CollectTimeframe[][]>([[], [], [], [], [], [], []])
    const promiseCollectTimeframes = usePromise();
    const [cleverons, setCleverons] = useState<Cleveron[]>([])
    const promiseCleverons = usePromise();
    /* weekrange picker state */
    const [weekRange, setWeekRange] = useState<WeekRange>(getDefaultWeekRange(currentTime));
    const [weekRangePickerFocus, setWeekRangePickerFocus] = useState<boolean>(false);
    // start date of currently selected week
    const startDate: DateTime = weekRange.startWeek
        ? DateTime.fromObject(weekRange.startWeek).startOf("week")
        : DateTime.fromObject(getDefaultWeekRange(currentTime).startWeek).startOf("week");
    // end date of currently selected week
    const endDate: DateTime = startDate.endOf("week");
    /* modal state */
    const [editIndex, setEditIndex] = useState<number|null>(null);
    const [form, setForm] = useState<Form|null>(null);
    /* loading state */
    const promiseFormSubmission = usePromise();
    const promiseApplyingWeek = usePromise();
    const promiseDeleteTimeframe = usePromise();
    const days = weekRange.startWeek ? weekdaysFor(toDateTime(weekRange.startWeek)) : weekdaysFor(currentTime);
    /* modals */
    const timetableModal = useModal();
    const applyWeekModal = useModal();
    const deleteModal = useModal();

    /* Effects */

    useEffect(() => {
        loadCleverons();
    }, [])

    useEffect(() => {
        loadDeliveryTimeframes();
    }, [weekRange.startWeek]);

    useEffect(() => {
        if (editIndex !== null) {
            setForm(synchronizeForm(editIndex));
        } else {
            setForm(null);
        }
    }, [editIndex, cleverons, collectTimeframes]);

    useEffect(() => {
        if (form === null) {
            timetableModal.setModal(null);
            return;
        }

        timetableModal.setModal(<TimetableModal
            form={form}
            onDelete={deleteModal.show}
            onClose={() => {
                closeTimetableModal();
                promiseFormSubmission.setPromise(null);
            }}
            onSubmit={handleSubmit}
            onFormChange={setForm}
            isLoading={promiseFormSubmission.isLoading}
            disabled={promiseFormSubmission.isLoading}
            />);
    }, [form]);

    useEffect(() => {
        if (form === null) {
            deleteModal.setModal(null);
            return;
        }

        deleteModal.setModal(<AldiGeneralModal
            key={deleteModal.id}
            disabled={promiseDeleteTimeframe.isLoading}
            confirming={promiseDeleteTimeframe.isLoading}
            onConfirm={() => {
                let dayIndex = toDateTime(form.day).weekday - 1;
                let timeframesForGivenDay: CollectTimeframe[] = collectTimeframes[dayIndex];
                promiseDeleteTimeframe.setPromise(clickAndCollectProvider
                    .delete(timeframesForGivenDay.map(timeframe => timeframe.id))
                    .then(() => {
                        setCollectTimeframes(collectTimeframes.map((timeframesPerDay, index) => {
                            if (index === dayIndex) {
                                return [];
                            } else {
                                return timeframesPerDay;
                            }
                        }));
                        notify('Das Abholzeitfenster wurde erfolgreich gelöscht.');
                        deleteModal.hide();
                        closeTimetableModal();
                    }).catch(error => {
                        console.debug(error);
                        notify(`Das Abholzeitfenster konnte nicht gelöscht werden.`)
                    }));
            }}
            textConfirmButton={"Löschen"}
            onCancel={deleteModal.hide}
            textCancelButton={"Abbrechen"}>

            <h2>{`Löschung Bestätigen`}</h2>
            <p>{`Möchten Sie dieses Abholzeitfenster wirklich löschen?`}</p>
        </AldiGeneralModal>);
    }, [form, promiseDeleteTimeframe.isLoading])

    useEffect(() => {
        if (weekRange.startWeek && weekRange.endWeek) {
            applyWeekModal.setModal(<AldiGeneralModal
                disabled={promiseApplyingWeek.isLoading}
                confirming={promiseApplyingWeek.isLoading}
                textConfirmButton="Anwenden"
                onConfirm={() => {
                    if (weekRange.startWeek && weekRange.endWeek) {
                        const sourceWeekYear = weekRange.startWeek.weekYear;
                        const sourceWeekNumber = weekRange.startWeek.weekNumber;
                        const promises: Promise<AxiosResponse<WeekApi, any>>[] = Interval.fromDateTimes(
                            DateTime.fromObject(weekRange.startWeek).plus({weeks: 1}),
                            DateTime.fromObject(weekRange.endWeek).endOf("week"),
                        ).splitBy({ weeks: 1 }).map(interval => interval.start).map(week =>
                            clickAndCollectProvider.updateCollectWeek(week.weekYear, week.weekNumber, sourceWeekYear, sourceWeekNumber));

                        promiseApplyingWeek.setPromise(Promise.all(promises)
                            .then((response: any) => {
                                notify("Alle Änderungen wurden erfolgreich angewendet.");
                                applyWeekModal.hide();
                            }).catch(error  => {
                                console.debug(error);
                                notify("Ein Fehler is aufgetreten.")
                            })
                        );
                    }
                }}
                textCancelButton="Abbrechen"
                onCancel={applyWeekModal.hide}>

                <h2>{`Abholzeitfenster auf weitere Wochen anwenden`}</h2>
                <p>{`Die Inhalte der angezeigten Woche werden auf die Kalenderwochen ${weekRange.startWeek.weekNumber} bis ${weekRange.endWeek.weekNumber} übertragen. Vorhandene Wochenkonfigurationen werden dabei überschrieben.`}</p>
                <p>{`Sollen die Änderungen angewendet werden?`}</p>
            </AldiGeneralModal>);
        } else {
            applyWeekModal.setModal(null);
        }
    }, [weekRange, promiseApplyingWeek.isLoading]);


    /* Helpers */
    function synchronizeForm(editIndex: number): Form {
        let isEditNew = collectTimeframes[editIndex].length > 0;
        let newForm = createForm(isEditNew, days[editIndex].toJSDate(), cleverons);
        if (isEditNew) {
            cleverons.map((cleveron, index) => {
                const timeframe = collectTimeframes[editIndex].find(tf => cleveron.id === tf.cleveron.id);
                if (timeframe) {
                    let startDate = DateTime.fromISO(timeframe.start);
                    let endDate = DateTime.fromISO(timeframe.end);
                    let nextDay = endDate.weekday !== startDate.weekday;
                    const formatTime = (date: DateTime) => `${date.hour.toString().padStart(2, '0')}:${date.minute.toString().padStart(2, '0')}`
                    newForm.fields[index].start = formatTime(startDate);
                    newForm.fields[index].end = formatTime(endDate);
                    newForm.fields[index].nextDay = nextDay;
                }
            })
        }
        return newForm;
    };

    function loadAll() {
        loadCleverons();
        loadDeliveryTimeframes();
    }

    function closeTimetableModal() {
        timetableModal.hide();
        setEditIndex(null);
    }

    function loadCleverons() {
        promiseCleverons.setPromise(cleveronsProvider.get({})
            .then(response => {
                setCleverons(response.data);
            })
            .catch(error => {
                console.debug(error);
                return Promise.reject(error);
            })
        );
    }

    function loadDeliveryTimeframes() {
        promiseCollectTimeframes.setPromise(clickAndCollectProvider.get({
            cleveronIds: cleverons.map(cleveron => cleveron.id),
        }).then(response => {
            let startDate = weekRange.startWeek ? toDateTime(weekRange.startWeek) : currentTime.startOf("week")

            // filter response data for entries starting or ending during the current week
            const collectTimeframesThisWeek =
                response.data.filter(timeframe => {
                    let interval = Interval.fromDateTimes(startDate, endDate);
                    return interval.contains(DateTime.fromISO(timeframe.start))
                        || interval.contains(DateTime.fromISO(timeframe.end))
                });

            // structure data to form one array of timeframes for each day of the week
            const collectTimeframesPerDayArray =
                weekDays(startDate).map((day: DateTime) =>
                    collectTimeframesThisWeek.filter((timeframe: CollectTimeframe) =>
                        DateTime.fromISO(timeframe.start).weekday === day.weekday))
            setCollectTimeframes(collectTimeframesPerDayArray);
        }).catch(error => {
            console.debug(error);
            Promise.reject(error);
        }));
    }

    const rowHeight = 70; // 3rem + 1px tablerow-height

    const weekDays = (day: DateTime): DateTime[] => {
        let startOfWeek = day.startOf("week");
        let endOfWeek = day.endOf("week");
        let week: Interval = Interval.fromDateTimes(startOfWeek, endOfWeek);
        let days: DateTime[] = week.splitBy({ days: 1 }).map(dayInterval => dayInterval.start);
        return days;
    }

    const getStartPositionTimeFrame = (startTime: DateTime) => {
        const hours = startTime.hour;
        const minutes = startTime.minute;
        return rowHeight * 1 / 4 * hours + rowHeight * 1 / 60 * 1 / 4 * minutes
    }

    const getTileHeightThisDay = (startTime: DateTime, endTime: DateTime) => {
        let difference;
        if (startTime.weekday === endTime.weekday) {
            /* normalize input */
            endTime = startTime.startOf("day").plus({ hours: endTime.hour, minutes: endTime.minute, seconds: endTime.second, milliseconds: endTime.millisecond })
            difference = endTime.toMillis() - startTime.toMillis();
        } else if (startTime < endTime) {
            difference = startTime.endOf("day").toMillis() - startTime.toMillis();
        } else {
            difference = 0;
        }
        return difference / (60 * 60 * 1000) * 1 / 4 * rowHeight;
    }

    const getTileHeightDayBefore = (startTime: DateTime, endTime: DateTime) => {
        let difference;
        if (startTime.weekday === endTime.weekday) {
            difference = 0;
        } else if (startTime < endTime) {
            difference = endTime.toMillis() - endTime.startOf("day").toMillis();
        } else {
            difference = 0;
        }
        return difference / (60 * 60 * 1000) * 1 / 4 * rowHeight;
    }

    const times = ["0:00", "4:00", "8:00", "12:00", "16:00", "20:00"];


    const getBackgroundColorCleveron = (index: number) => {
        switch (index) {
            case 0:
                return "#92D050";
            case 1:
                return "#FAB400";
            case 2:
                return "#222C78";
            default:
                return "#222C78";
        }
    }

    const isDayLocked = (date: DateTime) => {
        date = date.startOf("day");

        if (config.TIMETABLE_EDIT_AVOIDANCE_ENABLED) {
            return date <= currentTime.startOf("day").plus({ days: config.DELAY_DAYS_TO_EDIT_TIMETABLE })
        } else {
            return false;
        }
    };

    /* Handlers */
    const handleAddEventButtonClick = (dayIndex: number) => {
        const buttonDisabled = isDayLocked(days[dayIndex]);
        if (buttonDisabled) {
            notify(`Dieser Tag kann nicht mehr bearbeitet werden.`);
        } else {
            setEditIndex(dayIndex);
            timetableModal.show();
        }
    };

    const handleClickApplyTimeframes = () => {
        if (weekRange.startWeek && weekRange.endWeek) {
            applyWeekModal.show();
        } else {
            notify(`Es wurden keine Werte geändert.`);
        }
    };

    const handleClickEditTimeframe = (dayIndex: number) => {
        const buttonDisabled = isDayLocked(days[dayIndex]);
        if (buttonDisabled) {
            notify(`Dieser Tag kann nicht mehr bearbeitet werden.`);
        } else {
            setEditIndex(dayIndex);
            setWeekRangePickerFocus(false)
            timetableModal.show();
        }
    }

    const handleChangeOfWeekRangePicker = (value: WeekRange) => {
        setWeekRange(value)
        if (value.endWeek) {
            setWeekRangePickerFocus(false)
        }
    }

    const handleDisabledDayClick = () => {
        notify(`Dieser Tag kann nicht mehr bearbeitet werden.`);
    };

    function handleSubmit() {
        if (form === null) {
            return;
        }
        promiseFormSubmission.setPromise(new Promise(async resolve => {
        let weekday: WeekdayNumbers = toDateTime(form.day).weekday;
        const validation = validateForm(form);

        function parse(time: string): { hours: number, minutes: number } {
            let hours = parseInt(time.slice(0, 2));
            let minutes = parseInt(time.slice(3, 5));
            return { hours, minutes }
        }

        if (validation.isValid) {
            let newTimeframes: CollectTimeframe[] = filterUndefined(form.fields.map(({ cleveron, start, nextDay, end }, index) => {
                const fieldValidation = validation.fields[index];
                if (fieldValidation.start && fieldValidation.end) {
                    let day: DateTime = toDateTime(form.day).startOf("day");
                    let startDate: DateTime = day.plus(parse(start));
                    let endDate: DateTime = day.plus(parse(end)).plus({ days: nextDay ? 1 : 0 });
                    return {
                        id: 0,
                        start: startDate.toJSDate().toJSON(),
                        end: endDate.toJSDate().toJSON(),
                        cleveron,
                    }
                } else {
                    return undefined;
                }
            }));

            try {
                if (form.isEdit) {
                    await Promise.all(newTimeframes.map(timeframe => clickAndCollectProvider.update(timeframe)));
                } else {
                    await Promise.all(newTimeframes.map(timeframe => clickAndCollectProvider.create(timeframe)));
                }
                setCollectTimeframes(collectTimeframes.map((timeframesPerDay, index) => {
                    let dayIndex = weekday - 1;
                    if (index === dayIndex) {
                        return newTimeframes;
                    } else {
                        return timeframesPerDay;
                    }
                }));
                    closeTimetableModal();
            } catch (error) {
                console.debug(error);
                notify(`Die Daten für Abholzeitfenster ${weekday} konnten nicht gespeichert werden.`);
            }
        }
        resolve(null);
    }))};

    /* Renders */
    const renderRow = (time: string) => {
        return (
            <TableRow key={time}>
                <TableCell className="time-entry">{time}</TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
            </TableRow>
        );
    }

    const renderCleveronTimeFrameDayBefore = (dayIndex: number) => {
        return cleverons.map((cleveron: any, index: number) => {
            const timeframe = collectTimeframes[dayIndex-1].find((timeframe: CollectTimeframe) => cleveron.id === timeframe.cleveron.id);
            if (timeframe) {
                const startPosition = 0;
                const height = getTileHeightDayBefore(DateTime.fromISO(timeframe.start), DateTime.fromISO(timeframe.end));
                const backgroundColor = getBackgroundColorCleveron(index)
                return (
                    <div key={index} className="timeframe-cleveron-wrapper">
                        <div
                            className="timeframe-cleveron"
                            style={{ height: `${height}px`,
                                marginTop: `${startPosition}px`,
                                backgroundColor: backgroundColor }}>
                        </div>
                    </div>
                );
            } else {
                return (<div key={index} className="timeframe-cleveron-wrapper">
                    <div
                        className="timeframe-cleveron"
                        style={{ height: 0 }}>
                    </div>
                </div>);
            }
        })
    }

    const renderCleveronTimeFrame = (timeframesPerDay: CollectTimeframe[]) => {
        return cleverons.map((cleveron: any, index: number) => {
            const timeframe = timeframesPerDay.find((timeframe: CollectTimeframe) => cleveron.id === timeframe.cleveron.id);
            if (timeframe) {
                const startPosition = getStartPositionTimeFrame(DateTime.fromISO(timeframe.start));
                const height = getTileHeightThisDay(DateTime.fromISO(timeframe.start), DateTime.fromISO(timeframe.end));
                const backgroundColor = getBackgroundColorCleveron(index)
                return (
                    <div key={index} className="timeframe-cleveron-wrapper">
                        <div
                            className="timeframe-cleveron"
                            style={{ height: `${height}px`,
                                marginTop: `${startPosition}px`,
                                backgroundColor: backgroundColor }}>
                        </div>
                    </div>
                );
            } else {
                return (<div key={index} className="timeframe-cleveron-wrapper">
                    <div
                        className="timeframe-cleveron"
                        style={{ height: 0 }}>
                    </div>
                </div>);
            }
        })
    }

    const renderAddTimeFramesButton = (dayIndex: number) => {
        let maxTileHeightDayBefore: number;
        if (dayIndex > 0 && collectTimeframes[dayIndex - 1]) {
            let timeframes = collectTimeframes[dayIndex - 1];
            maxTileHeightDayBefore = timeframes.reduce((maxHeight: number, timeframe: CollectTimeframe) => {
                let height = getTileHeightDayBefore(DateTime.fromISO(timeframe.start), DateTime.fromISO(timeframe.end));
                return Math.max(height, maxHeight)
            }, 0);
        } else {
            maxTileHeightDayBefore = 0;
        }
        let height = maxTileHeightDayBefore === 0
                ? `calc(${6 * rowHeight}px)`
                : `calc(${6 * rowHeight}px - 8px - ${maxTileHeightDayBefore}px)`;

        return (
            <div className="add-timeframe-button-wrapper">
                <AldiButton className="add-timeframe-button" style={{ height: height }} onClick={() => handleAddEventButtonClick(dayIndex)}>
                    <p className="add-timeframe-button-text-title">{"Cleveron"}</p>
                    <p className="add-timeframe-button-text">{"Abholzeitfenster"}</p>
                    <p className="add-timeframe-button-text">{"hinzufügen"}</p>
                </AldiButton>
            </div>
        );
    }

    const renderTimeFramesCollect = () => {
        return collectTimeframes.map((timeframesPerDay: any, dayIndex: number) => {
            if (isDayLocked(days[dayIndex])) {
                return (
                    <div key={dayIndex} className="timeframe-day-wrapper">
                        <div className="button-wrapper">
                            <AldiButton className="disabled-button" style={{ height: `${6 * rowHeight}px` }} onClick={() => handleDisabledDayClick()}>
                                <p className="disabled-button-text">{"Bearbeitung nicht mehr möglich"}</p>
                                <i className="ri-forbid-line no-access-icon"></i>
                            </AldiButton>
                        </div>
                    </div>
                )
            }

            return (
                <div key={dayIndex} className="timeframe-day-wrapper">
                    {dayIndex > 0 &&
                        <div className="timeframes-wrapper day-before" onClick={() => handleClickEditTimeframe(dayIndex-1)}>
                            {renderCleveronTimeFrameDayBefore(dayIndex)}
                        </div>}
                    {timeframesPerDay.length > 0 && 
                        <div className="timeframes-wrapper-button" onClick={() => handleClickEditTimeframe(dayIndex)}>
                            {renderCleveronTimeFrame(timeframesPerDay)}
                        </div>}
                    {timeframesPerDay.length === 0 && dayIndex !== 6 && renderAddTimeFramesButton(dayIndex)}
                </div>
            )
        })
    }

    return (
        <div className="timeframe-page-wrapper">
            <div className="container-header-week-range-picker">
                <div className="header-row">
                    <div className="header-title">
                        {"Abholzeitfenster"}
                    </div>
                    <div className="placeholder"></div>
                    <div className="weekrange-row">
                        <WeekRangeInput
                            currentTime={currentTime}
                            value={weekRange}
                            focus={weekRangePickerFocus}
                            onChange={handleChangeOfWeekRangePicker}
                            onFocusChange={focus => setWeekRangePickerFocus(focus)} />
                        <AldiButton onClick={handleClickApplyTimeframes} kind="primary" className="set-weeks-button">
                            {"Für diesen Zeitraum anwenden"}
                        </AldiButton>
                    </div>
                </div>
            </div>
            {promiseCleverons.status === PromiseStatus.Failed || promiseCollectTimeframes.status === PromiseStatus.Failed
            ?
            <AldiReloadButton onClick={loadAll} />
                :
            <>
            <div className="cleveron-timeframe-table aldi-box aldi-general-table-wrapper">
                <Table className="table centered-entries">
                    <TableHead>
                        <TableRow>
                            <TableCell>Uhrzeit</TableCell>
                            <TableCell subtext={toDateTime(days[0]).toLocaleString({ year: 'numeric', month: '2-digit', day: '2-digit' }) || ""}>Montag</TableCell>
                            <TableCell subtext={toDateTime(days[1]).toLocaleString({ year: 'numeric', month: '2-digit', day: '2-digit' }) || ""}>Dienstag</TableCell>
                            <TableCell subtext={toDateTime(days[2]).toLocaleString({ year: 'numeric', month: '2-digit', day: '2-digit' }) || ""}>Mittwoch</TableCell>
                            <TableCell subtext={toDateTime(days[3]).toLocaleString({ year: 'numeric', month: '2-digit', day: '2-digit' }) || ""}>Donnerstag</TableCell>
                            <TableCell subtext={toDateTime(days[4]).toLocaleString({ year: 'numeric', month: '2-digit', day: '2-digit' }) || ""}>Freitag</TableCell>
                            <TableCell subtext={toDateTime(days[5]).toLocaleString({ year: 'numeric', month: '2-digit', day: '2-digit' }) || ""}>Samstag</TableCell>
                            <TableCell subtext={toDateTime(days[6]).toLocaleString({ year: 'numeric', month: '2-digit', day: '2-digit' }) || ""}>Sonntag</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {times.map(time => renderRow(time))}
                    </TableBody>
                </Table>
                <div className="timeframe-week-wrapper">
                    {renderTimeFramesCollect()}
                </div>
            </div>
            <div className="legend-wrapper">
                {cleverons.map((cleveron: Cleveron, index: number) =>
                    <div className="legend-cleveron" key={index}>
                        <div className="legend-cleveron-color-pad" style={{ backgroundColor: getBackgroundColorCleveron(index) }}></div>
                        <p className="legend-cleveron-label">Cleveron {cleveron.id}</p>
                    </div>
                )}
            </div>
            </>}
        </div>
    )
}
