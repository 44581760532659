import { ActivityApi, ArticleApi, CleveronApi, ClickAndCollectApi, ClusterApi, ConfigApi, Configuration, DeliveryApi, LocationApi, OrderApi, TaskApi, TimeframeApi, TourApi, VehicleApi } from "@kehrwasser/aldi-sued-dtm-openapi";

function makeConfiguration() {
    let configuration = new Configuration();
    const env = (window as any).env;
    configuration.basePath = env?.REACT_APP_API_BASEPATH
        || process.env.REACT_APP_API_BASEPATH
        || "http://127.0.0.1:4010";
    return configuration;
}

const configuration = makeConfiguration();

export const apiService = {
    activity: new ActivityApi(configuration),
    article: new ArticleApi(configuration),
    cleveron: new CleveronApi(configuration),
    clickAndCollect: new ClickAndCollectApi(configuration),
    cluster: new ClusterApi(configuration),
    config: new ConfigApi(configuration),
    delivery: new DeliveryApi(configuration),
    location: new LocationApi(configuration),
    order: new OrderApi(configuration),
    task: new TaskApi(configuration),
    timeframe: new TimeframeApi(configuration),
    tour: new TourApi(configuration),
    vehicle: new VehicleApi(configuration),
}
