import { Cleveron } from "@kehrwasser/aldi-sued-dtm-openapi";

export const VALID_TIME_FORMAT = /^(2[0-3]|[01]?[0-9]):([0-5]?[0-9])$/;

export type Form = {
    isEdit: boolean,
    day: Date,
    fields: TimeframeField[],
};

export type TimeframeField = {
    cleveron: Cleveron,
    start: string,
    nextDay: boolean,
    end: string,
};

export function createForm(isEdit: boolean, day: Date, cleverons: Cleveron[]): Form {
    return {
        isEdit,
        day,
        fields: cleverons.map(cleveron => { return { cleveron, start: "", nextDay: false, end: "" } }),
    };
}

export function cloneForm(form: Form): Form {
    let { isEdit, day, fields: cleveronTimes } = form;
    let newFields: TimeframeField[] = cleveronTimes.map(({ cleveron, start, nextDay, end }: TimeframeField) => { return { cleveron, start, nextDay, end } });
    return { isEdit, day, fields: newFields }
}

export type FormValidation = {
    isValid: boolean,
    fields: FieldValidation[],
}

type FieldValidation = { start: boolean, hideStart: boolean, end: boolean, hideEnd: boolean, endAfterStart: boolean, hideEndAfterStart: boolean, empty: boolean };

export function validateForm(form: Form): FormValidation {
    let validation = {
        isValid: false,
        fields: Array.from({ length: form.fields.length }, initialField)
    };
    return updateFormValidation(validation, form, { showAllErrors: false });
}

function initialField(): FieldValidation {
    return { start: false, hideStart: true, end: false, hideEnd: true, endAfterStart: false, hideEndAfterStart: true, empty: true };
}

export function updateFormValidation(validation: FormValidation, form: Form, options?: { showAllErrors: boolean }): FormValidation {
    let { showAllErrors } = options ?? { showAllErrors: false };
    let fields = form.fields.map((field: TimeframeField, index: number) => {
        let stale = validation.fields[index] || initialField();

        let start: boolean = field.start.match(VALID_TIME_FORMAT) !== null;
        let hideStart = start || field.start === ""
        let end: boolean = field.end.match(VALID_TIME_FORMAT) !== null;
        let hideEnd = end || field.end === ""
        let endAfterStart: boolean;
        if (field.nextDay) {
            endAfterStart = true;
        } else {
            if (start && end) {
                const startHours = parseInt(field.start.slice(0, -3));
                const endHours = parseInt(field.end.slice(0, -3));
                const startMinutes = parseInt(field.start.slice(-2));
                const endMinutes = parseInt(field.end.slice(-2));
                endAfterStart = endHours > startHours || (endHours === startHours && endMinutes > startMinutes)
            } else {
                endAfterStart = false;
            }
        }
        let hideEndAfterStart = stale.hideEndAfterStart || (start && end && endAfterStart);
        return ({
            start,
            hideStart: hideStart && !showAllErrors,
            end,
            hideEnd: hideEnd && !showAllErrors,
            endAfterStart,
            hideEndAfterStart: hideEndAfterStart && !showAllErrors,
            empty: field.start === "" && field.end === "",
        })
    });
    const atLeastOneValidField = fields.filter(v => v.start && v.end && v.endAfterStart).length > 0;
    const allFieldsValidOrEmpty = fields.filter(v => (v.start && v.end && v.endAfterStart) || v.empty).length === fields.length;
    const isValid = atLeastOneValidField && allFieldsValidOrEmpty;

    return { isValid, fields };
}
