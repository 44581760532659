import { AxiosResponse } from "axios";
import { OrderStatus, Article } from "@kehrwasser/aldi-sued-dtm-openapi";
import { apiService } from "./apiService";

export const ordersProvider = {

    api: apiService.order,

    update(orderId: string, status: OrderStatus): Promise<AxiosResponse<Article, any>> {

        return this.api.patchOrder(
            orderId,
            status);
    }

};
