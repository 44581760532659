import './PeriodModal.scss';
import { useState, useEffect } from 'react';
import AldiModal from '../../../../elementsAldi/modal/Modal';
import { DateTime } from '../../../../../shared/datetime/DateTime';
import TimeSlider from '../timeslider/TimeSlider';


type PeriodModalProps = {
    state: PeriodModalState,
    onChange?: (state: PeriodModalState) => void,
    onSubmit?: () => void,
    onDelete?: () => void,
    onCancel?: () => void,
}

export type PeriodModalState = {
    isEdit: boolean,
    rowIndex: number,
    startTime: DateTime,
    endTime: DateTime,
    minTime: DateTime,
    maxMinTime: DateTime,
    maxTime: DateTime,
};

export function defaultPeriodModalState(rowIndex: number, minTime: DateTime, maxTime: DateTime, maxMinTime?: DateTime): PeriodModalState {
    return {
        isEdit: false,
        rowIndex,
        startTime: minTime,
        endTime: maxTime,
        maxMinTime: maxMinTime ?? maxTime,
        minTime,
        maxTime,
    }
}

export default function PeriodModal(props: PeriodModalProps) {

    const { state, onSubmit, onCancel, onDelete, onChange } = props;

    /* Helpers */
    const getValidation = () => showValidationNoValueChoosable ? "Es gibt kein offenes Zeitfenster mehr für diesen Zeitraum." : "";

    /* State Definitions */
    const [showValidationNoValueChoosable, setShowValidationNoValueChoosable] = useState<boolean>(false)
    // const [timeValues, setTimeValues] = useState<[DateTime, DateTime]>([state.startTime, state.endTime])

    /* Communication */

    /* Handlers */
    const handleChange = ([startTime, endTime]: [DateTime, DateTime]) => {
        onChange && onChange({...state, startTime, endTime})
    };

    const handleSubmitPeriods = () => {
        onSubmit && onSubmit();
        setShowValidationNoValueChoosable(false);
    };

    const handleCancel = () => {
        setShowValidationNoValueChoosable(false);
        onCancel && onCancel();
    }

    const handleDeletePeriods = () => {
        onDelete && onDelete();
    }

    const handlePossibleValuesChange = (possibleValues: DateTime[]) => {
        setShowValidationNoValueChoosable(possibleValues.length <= 1);
    }

    return (
        <div className="period-modal">
            <AldiModal
                title="Zeitraum festlegen"
                subtitle="Bitte lege die Start- und Endzeit fest."
                textCancelButton="Verwerfen"
                onCancel={handleCancel}
                textSubmitButton="Speichern"
                onSubmit={handleSubmitPeriods}
                textDeleteButton="Löschen"
                onDelete={handleDeletePeriods}
                enableDeleteButton={state.isEdit}
                disabledSubmit={showValidationNoValueChoosable}>

                <p className="heading">Zeitraum</p>

                <TimeSlider
                    value={[state.startTime, state.endTime]}
                    minTime={state.minTime}
                    maxTime={state.maxTime}
                    maxMinTime={state.maxMinTime}
                    onPossibleValuesChanged={handlePossibleValuesChange}
                    onChange={handleChange} />

                <p className="validation">{getValidation()}</p>
            </AldiModal>
        </div>
    )
}
