import { Slider } from "@mui/material";
import { useEffect, useState } from "react";
import { DateTime } from "../../../../../shared/datetime/DateTime";
import { Interval } from "../../../../../shared/datetime/Interval";
import { getTimeStringFromDate } from "../../../../../shared/helpers";

export type TimeSliderProps = {
    minTime: DateTime,
    maxMinTime?: DateTime,
    maxTime: DateTime,
    minMaxTime?: DateTime,
    value: [DateTime, DateTime],
    allMarks?: boolean,
    onChange?: (value: [DateTime, DateTime]) => void,
    onPossibleValuesChanged?: (values: DateTime[]) => void,
}

const FIFTEEN_MINUTES_IN_MILLIS = 15 * 60 * 1000;

export default function TimeSlider(props: TimeSliderProps) {
    const { minTime, maxMinTime, minMaxTime, maxTime, value, onChange, onPossibleValuesChanged, allMarks = false } = props;

    // console.assert(startTime < endTime, "startTime should come before endTime");
    // console.assert(startTime <= value[0], "value should come after startTime");
    // console.assert(value[1] <= endTime, "value should come before endTime");

    const valueMillis: number[] = value.map(item => item.toMillis());
    const minimumMillis: number = minTime.toMillis();
    const maximumMillis: number = maxTime.toMillis();

    type Mark = { value: number, label: string };

    /* Hooks */
    const [possibleMillis, setPossibleMillis] = useState<number[]>([]);
    const [marks, setMarks] = useState<Mark[]>([]);
    const sparseMarks = marks.map((value: Mark, index: number) => index % 4 === 0 ? value : { ...value, label: "" });

    useEffect(() => {
        let possibleDateTimes: DateTime[] = Interval.fromDateTimes(minTime, maxTime).splitBy({ minutes: 15 }).map(interval => interval.start);
        let possibleValues: number[] = possibleDateTimes.map(time => time.toMillis());
        setPossibleMillis(possibleValues);
        onPossibleValuesChanged && onPossibleValuesChanged(possibleDateTimes);
    }, [minTime, maxTime])

    useEffect(() => {
        setMarks(possibleMillis.map((value: number) => {
            return { value, label: getTimeStringFromDate(new Date(value)) };
        }))
    }, [possibleMillis])


    /* Helper */

    const valueLabelFormat = (value: number): string | null => {
        return marks.find((mark: any) => mark.value === value)?.label ?? null;
    }

    const getAriaValueText = (millis: number): string => {
        return getTimeStringFromDate(new Date(millis));
    }


    return <div className="slider-wrapper">
        <Slider
            value={valueMillis}
            getAriaValueText={getAriaValueText}
            step={FIFTEEN_MINUTES_IN_MILLIS}
            valueLabelDisplay="auto"
            valueLabelFormat={valueLabelFormat}
            onChange={(_event: any, value: number | number[]) => {
                if (onChange) {
                    if (Array.isArray(value) && value.length >= 2) {
                        const newLowerBound = DateTime.fromMillis(value[0]);
                        const newUpperBound = DateTime.fromMillis(value[1]);
                        onChange([clampTime(newLowerBound, minTime, maxMinTime ?? maxTime),
                            clampTime(newUpperBound, minMaxTime ?? minTime, maxTime)]);
                    }
                }
            }}
            marks={allMarks ? marks : sparseMarks}
            min={minimumMillis}
            max={maximumMillis}
            sx={{
                width: "95%",
                '& .MuiSlider-markLabel': {
                    fontSize: "10px",
                }
            }}
        />
    </div>
}

function clampTime(date: DateTime, lowerBound: DateTime, upperBound: DateTime): DateTime {
    return date < lowerBound ? lowerBound : date > upperBound ? upperBound : date;
}
