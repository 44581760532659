import { DateTime, DayNumbers, MonthNumbers, WeekNumbers } from "./datetime/DateTime";

export type Week = {
    weekYear: number,
    weekNumber: WeekNumbers,
}

export type YearMonthDay = {
    year: number,
    month?: MonthNumbers,
    day?: DayNumbers,
}

export function toDateTime(date: Date | DateTime | Week | YearMonthDay): DateTime {
    if (date instanceof Date) {
        return DateTime.fromJSDate(date as Date);
    } else if (date instanceof DateTime) {
        return date as DateTime;
    } else {
        return DateTime.fromObject(date as Week | YearMonthDay);
    }
}

export function getTimeStringFromDate(date: Date): string {
    return toDateTime(date).toLocaleString({ hour: '2-digit', minute: '2-digit' });
};

export function getTimeStringFromDateTime(date: DateTime): string {
    return date.toLocaleString({ hour: '2-digit', minute: '2-digit' });
};

export function dateTimeToISOString(date: DateTime): string {
    return date.toJSDate().toISOString();
}

export function skipSunday(date: DateTime): DateTime {
    const isSunday = date.weekday === 7;
    if (isSunday) {
        return date.plus({ days: 1 }).startOf("day");
    } else {
        return date.startOf("day");
    }
}

export function groupBy<ValueType, ComparisonType>(
    list: ValueType[],
    compareBy: (value: ValueType) => ComparisonType) {
    return list.reduce((groups: ValueType[][], value: ValueType) => {
        if (groups.length === 0) {
            return [[value]]
        } else if (compareBy(groups[groups.length - 1][0]) === compareBy(value)) {
            groups[groups.length - 1].push(value);
            return groups;
        } else {
            groups.push([value]);
            return groups;
        }
    }, [])
}

type CallbackFunction = (message: string) => void;
const notificationSubscriber: CallbackFunction[] = []

export const notifications = {
    publish: (message: string) => {
        notificationSubscriber.forEach(callback => callback(message))
    },
    subscribe: (callback: CallbackFunction) => {
        notificationSubscriber.push(callback)
    }
}

export function notify(message: string) {
    notifications.publish(message)
}

export function modifyAtId<Id, A extends {id: Id}>(items: A[], id: Id, modification: (item: A) => A): A[] {
    return items.map(item => item.id === id ? modification(item) : item);
}

export function removeById<Id, A extends {id: Id}>(items: A[], id: Id): A[] {
    return items.filter(item => item.id !== id);
}

export function getById<Id, A extends {id: Id}>(items: A[], id: Id): A|undefined {
    return items.find(item => item.id === id);
}

export function removeByIndex<A>(items: A[], index: number): A[] {
    if (0 <= index && index < items.length) {
        return [...items.slice(0,index), ...items.slice(index+1)]
    } else {
        return items;
    }
}

export function cumulativeSum(numbers: number[]): number[] {
    let result = [];
    let sum = 0;
    for (let i = 0; i < numbers.length; ++i) {
        sum += numbers[i];
        result.push(sum);
    }

    return result;
}

export function filterUndefined<ItemType>(array: (ItemType|undefined)[]): ItemType[] {
    return array.filter(item => item !== undefined) as ItemType[]
}
