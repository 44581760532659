import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';

function TextfieldWithAdornments(props: any) {
    return (
        <FormControl sx={{ m: 1, width: '200px', margin: "0 16px",
        '& .MuiTypography-root': {
            fontSize: "16px",
            lineHeight: "unset"
        },
        '& .MuiInputBase-root': {
            padding: "0 10px 0 14px",
        }, }} size="small" variant="outlined">
          <InputLabel htmlFor="outlined-adornment-textfield">{props.label}</InputLabel>
          <OutlinedInput
            data-testid="input-with-adornments"
            id="outlined-adornment-textfield"
            type="text"
            value={props.value}
            onChange={props.handleChange}
            onFocus={props.onFocus}
            onBlur={props.onBlur}
            startAdornment= {<InputAdornment position="start">{props.startAdornment}</InputAdornment>}
            endAdornment={
              <InputAdornment position="end">
                  {props.endAdornment}
              </InputAdornment>
            }
            label={props.label}
          />
        </FormControl>
    )
}

export default TextfieldWithAdornments;