import { setSessionCookie, clearSessionCookie } from "./cookies";

/**
 * This represents some generic auth provider API, like Firebase.
 */
const fakeAuthProvider = {
    signin(_credentials: Credentials, callback: VoidFunction) {
        setSessionCookie("5ab7b7500f3dcb85cca0c395cc27dea");
        setTimeout(callback, 100); // fake async
    },
    signout(callback: VoidFunction) {
        clearSessionCookie();
        setTimeout(callback, 100);
    },
};

class Credentials { }


export { fakeAuthProvider, Credentials };
