import KWChipInput, { KWChipInputProps } from "../../elements/chipinput/KWChipInput";
import "./AldiChipInput.scss";


export function AldiChipInput(props: KWChipInputProps) {
    return <KWChipInput
        className={"aldi-chipinput"}
        resetText={<span className="ri-close-circle-line"></span>}
        {...props} />
}
