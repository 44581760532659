import React from "react"

interface Field {
    required?: string
}


class Validator {

    private validators = new Map()
    private errors = {} as any
    private options = {
        defaultRequiredMessage: 'Is required',
        defaultMinimumMessage: 'Value must be at least zero'
    }

    public register(parameters: {
        name: string,
        rule: string,
        message: string
    }) {

        const { name, rule, message } = parameters

        const globalValidators = this.validators

        if (!globalValidators.get(name)) globalValidators.set(name, {} as Field)

        const localValidators = globalValidators.get(name)
        localValidators[rule] = message

        globalValidators.set(name, localValidators)

    }

    public test(element: HTMLInputElement | HTMLSelectElement) {
        const validity = element.validity
        const name = element.name

        if (validity.valueMissing) {
            const validator = this.validators.get(name)
            let msg
            if (validator && validator.required) msg = validator.required
            else msg = this.options.defaultRequiredMessage
            this.errors = { ...this.errors, [name]: msg }
            return this.errors
        }

        if (validity.rangeUnderflow) {
            const validator = this.validators.get(name)
            let msg
            if (validator && validator.min) msg = validator.min
            else msg = this.options.defaultMinimumMessage
            this.errors = { ...this.errors, [name]: msg }
            return this.errors
        }

        // add more rules here...

        if (validity.valid) {
            delete { ...this.errors }[name]
            return this.errors
        }

        throw Error('')
    }

    validate(name: string) {
        return this.errors[name]
    }

}

export default Validator
