import './FulfillmentActivitiesPage.scss';
import { Activity as ActivityInterface, ActivityTimeseries, Temperature } from '@kehrwasser/aldi-sued-dtm-openapi';
import { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';

import { activityProvider } from '../../../../shared/activities.service';
import TableRow from '../../../elementsAldi/table/tableRow/TableRow';
import TableCell from '../../../elementsAldi/table/tableCell/TableCell';
import { cumulativeSum, notify } from '../../../../shared/helpers';
import React from 'react';
import { PromiseStatus, useAxios } from '../../../../hooks/usePromise';
import { AldiTab, AldiTabs } from '../../../elementsAldi/tabs/AldiTabs';
import FulfillmentActivitiesDataTable from './activitiesDataTable/FulfillmentActivitiesDataTable';
import AldiReloadButton from '../../../elementsAldi/reloadbutton/AldiReloadButton';
import FulfillmentActivitiesOverview from './overview/FulfillmentActivitiesOverview';

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            className="tabpanel"
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && children}
        </div>
    );
}

function useTimeseries(category: "am"|"fr"|"ch"): {actual: number[], plan: number[], status: PromiseStatus, reload: () => void} {
    const timeseriesPromise = useAxios<ActivityTimeseries>();
    const [actual, setActual] = useState<number[]>([]);
    const [plan, setPlan] = useState<number[]>([]);

    useEffect(() => {
        loadTimeseries();
    },[])

    function loadTimeseries() {
        timeseriesPromise.setRequest(activityProvider.getTimeseries(category));
    }

    useEffect(() => {
        let actualHourly = timeseriesPromise.result?.actualHourly;
        let plannedHourly = timeseriesPromise.result?.plannedHourly;
        if (actualHourly && plannedHourly) {
            setActual(cumulativeSum(actualHourly));
            setPlan(cumulativeSum(plannedHourly));
        } else {
            setActual([]);
            setPlan([]);
        }
    }, [timeseriesPromise.result]);

    return {actual, plan, status: timeseriesPromise.status, reload: loadTimeseries};
}


export default function FulfillmentActivitiesPage() {

    /* State Definitions */
    const timeseriesAm = useTimeseries("am");
    const timeseriesCh = useTimeseries("ch");
    const timeseriesFr = useTimeseries("fr");

    const [value, setValue] = React.useState(0);

    /* Helper */
    ChartJS.register(
        CategoryScale,
        LinearScale,
        PointElement,
        LineElement,
        Title,
        Tooltip,
        Legend
    );

    const labels = ['6:00', '7:00', '8:00', '9:00', '10:00', '11:00', '12:00', '13:00', '14:00'];

    const get6amTo2pm = (timeseries: number[]): number[] => timeseries.slice(5, 14);

    const makeProps = (opt: { title: string, timeseries: {actual: number[], plan: number[]} }) => ({
        options: {
            responsive: true,
            plugins: {
                title: {
                    display: true,
                    text: opt.title,
                }
            }
        },
        data: {
            labels,
            datasets: [
                {
                    label: 'Ist',
                    borderColor: 'rgb(53, 162, 235)',
                    backgroundColor: 'rgba(53, 162, 235, 0.5)',
                    data: get6amTo2pm(opt.timeseries.actual),
                },
                {
                    label: 'Soll',
                    borderColor: '#EAF0F4',
                    backgroundColor: '#EAF0F4',
                    data: get6amTo2pm(opt.timeseries.plan),
                },
            ],
        }
    });


    const propsAmbient = makeProps({
        title: "Fortschritt Picking",
        timeseries: timeseriesAm,
    });

    const propsChilled = makeProps({
        title: "Fortschritt Picking",
        timeseries: timeseriesCh,
    });

    const propsFrozen = makeProps({
        title: "Fortschritt Picking",
        timeseries: timeseriesFr,
    })

    const percentage = (partialValue: number, totalValue: number) => {
        return Math.round((100 * partialValue) / totalValue);
    }

    function reloadTimeseries() {
        timeseriesAm.reload();
        timeseriesCh.reload();
        timeseriesFr.reload();
    }

    /* Communication */

    /* Handlers */
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    }


    /* Renders */
    const renderRow = (activity: ActivityInterface) => {
        const endTime = new Date(activity.dueTime)
        return (
            <TableRow key={activity.id}>
                <TableCell className='title-column-cell'>{activity.id}</TableCell>
                <TableCell>{activity.plan.toLocaleString('de-DE')}</TableCell>
                <TableCell>{activity.actual.toLocaleString('de-DE')}</TableCell>
                <TableCell>{percentage(activity.actual, activity.plan)}%</TableCell>
                <TableCell>{(activity.plan - activity.actual).toLocaleString('de-DE') }</TableCell>
                <TableCell>{endTime.toLocaleTimeString()}</TableCell>
            </TableRow>
        );
    }

    function a11yProps(index: number) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    return (
        <div className='activity-fulfilment-overview'>
            <div className="container-header">
                <div className="header-title">
                    {"Tages-Übersicht"}
                </div>
            </div>
            <div className='activity-fulfilment'>

                <div className='tabbed-content'>
                    <AldiTabs value={value} onChange={handleChange}>
                        <AldiTab label="Übersicht" {...a11yProps(0)}/>
                        <AldiTab label="Ambient" {...a11yProps(1)}/>
                        <AldiTab label="Chilled" {...a11yProps(2)}/>
                        <AldiTab label="Frozen" {...a11yProps(3)}/>
                    </AldiTabs>
                    <TabPanel value={value} index={0}>
                        <FulfillmentActivitiesOverview />
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <FulfillmentActivitiesDataTable temperature={Temperature.Am} />

                        {timeseriesAm.status === PromiseStatus.Failed ? <AldiReloadButton onClick={reloadTimeseries} />
                          : <div className="graph-wrapper">
                                <Line {...propsAmbient} />
                            </div>}

                    </TabPanel>
                    <TabPanel value={value} index={2}>
                        <FulfillmentActivitiesDataTable temperature={Temperature.Ch} />
                        {timeseriesCh.status === PromiseStatus.Failed ? <AldiReloadButton onClick={reloadTimeseries} />
                          : <div className="graph-wrapper">
                                <Line {...propsChilled} />
                            </div>}
                    </TabPanel>
                    <TabPanel value={value} index={3}>
                        <FulfillmentActivitiesDataTable temperature={Temperature.Fr} />
                        {timeseriesFr.status === PromiseStatus.Failed ? <AldiReloadButton onClick={reloadTimeseries} />
                          : <div className="graph-wrapper">
                                <Line {...propsFrozen} />
                            </div>}
                    </TabPanel>
                </div>

            </div>
        </div>
    )
}
