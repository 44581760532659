const configPaginationProvider = {

        rowsPerPage: 10,
        page: 0,
        count: 0,
        rowsPerPageOptions: [10, 25, 50]

};

export { configPaginationProvider };
