import * as React from 'react';
import './Pagination.scss';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import TablePagination from '@mui/material/TablePagination';
import IconButton from '@mui/material/IconButton';

interface TablePaginationActionsProps {
    count: number;
    page: number;
    rowsPerPage: number;
    onPageChange: (
        event: React.MouseEvent<HTMLButtonElement>,
        newPage: number,
    ) => void;
}

function TablePaginationActions(props: TablePaginationActionsProps) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const maxPage = Math.max(0, Math.ceil(count / rowsPerPage) - 1);

    const handleFirstPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        onPageChange(event, 0);
    };

    const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        onPageChange(event, maxPage);
    };

    return (
        <Box sx={{ flexShrink: 0, ml: 2.5 }}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                {theme.direction === 'rtl' ? <i className="ri-skip-forward-mini-line"></i> : <i className="ri-skip-back-mini-line"></i>}
            </IconButton>
            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label="previous page"
            >
                {theme.direction === 'rtl' ? <i className="ri-arrow-right-s-line"></i> : <i className="ri-arrow-left-s-line"></i>}
            </IconButton>
            {page + 1}/{maxPage + 1}
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === 'rtl' ? <i className="ri-arrow-left-s-line"></i> : <i className="ri-arrow-right-s-line"></i>}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                {theme.direction === 'rtl' ? <i className="ri-skip-back-mini-line"></i> : <i className="ri-skip-forward-mini-line"></i>}
            </IconButton>
        </Box>
    );
}


export type PaginationProps = {
    count: number;
    page: number;
    rowsPerPage: number;
    onPageChange?: (page: number) => void;
    onRowsPerPageChange?: (rowsPerPage: number) => void;
    colSpan?: number,
};

export default function Pagination(props: PaginationProps) {

    const { onPageChange, onRowsPerPageChange, ...tablePaginationProps } = props;

    const handleChangePage = (
        _event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number,
    ) => {
        onPageChange && onPageChange(newPage)
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,) => {
        onRowsPerPageChange && onRowsPerPageChange(parseInt(event.target.value, 10));
        onPageChange && onPageChange(0);
    };

    return (
        <TablePagination
            {...tablePaginationProps}
            className="aldi-pagination"
            sx={{
                '& .MuiTablePagination-root': {
                    display: "flex"
                },
                'MuiTablePagination-selectLabel': {
                    display: "none"
                },
            }}
            rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            ActionsComponent={TablePaginationActions}
        />
    );
}


