import { AxiosResponse } from "axios";
import { Vehicle } from "@kehrwasser/aldi-sued-dtm-openapi";
import { apiService } from "./apiService";

const vehiclesProvider = {

    api: apiService.vehicle,

    get(): Promise<AxiosResponse<Vehicle[], any>> {

        return this.api.getVehicles();
    }

};

export { vehiclesProvider };
