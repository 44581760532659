import Tile from '../../elements/tile/Tile';
import './DashboardPage.scss';
import { useNavigate } from 'react-router-dom';


export default function DashboardPage() {

    const navigate = useNavigate();

    return (
        <div className='dashboard'>
            <div className="tiles ff">
                <header>
                    <h1>{"Tagesprozesse"}</h1>
                </header>

                <div className="tiles-body">
                    <Tile
                        title="Tages-Übersicht"
                        variant="double"
                        iconName="ri-24-hours-line"
                        onClick={() => navigate('/fulfillment/activities')}></Tile>
                    <Tile title="Kundenbestellungen"
                        iconName="ri-shopping-basket-2-line"
                        onClick={() => navigate('/fulfillment/orders')}></Tile>
                    <Tile
                        title="Cluster-Übersicht"
                        iconName="ri-store-2-fill"
                        onClick={() => navigate('/fulfillment/cluster-overview')}></Tile>
                    <Tile
                        title="Aufgaben-Übersicht"
                        iconName="ri-task-line"
                        onClick={() => navigate('/fulfillment/tasks')}></Tile>
                </div>
            </div>

            <div className="tiles click-and-collect">
                <header>
                    <h1>{"Click & Collect"}</h1>
                </header>

                <div className="tiles-body">
                    <Tile
                        title="Cleveron-Übersicht"
                        iconName="ri-database-line"
                        onClick={() => navigate('/click-and-collect/cleverons')}></Tile>
                    <Tile
                        title="Abholzeitfenster"
                        iconName="ri-shopping-bag-line"
                        onClick={() => navigate('/click-and-collect/timetable-cleverons')}></Tile>
                </div>
            </div>

            <div className="tiles home-delivery">
                <header>
                    <h1>{"Home Delivery"}</h1>
                </header>
                <div className="tiles-body">
                    <Tile
                        title="Touren-Übersicht"
                        iconName="ri-truck-line"
                        onClick={() => navigate('/home-delivery/tour-planning')}></Tile>
                    <Tile
                        title="Auslieferzeitfenster"
                        iconName="ri-truck-line"
                        onClick={() => navigate('/home-delivery/timetable-home-delivery')}></Tile>
                </div>
            </div>

            <div className="tiles storage">
                <header>
                    <h1>{"Lagerplätze"}</h1>
                </header>
                <div className="tiles-body">
                    <Tile
                        title="Übersicht"
                        iconName="ri-map-pin-2-line"
                        onClick={() => navigate('/storage-places/locations')}></Tile>
                </div>

            </div>

            <div className="tiles configuration">
                <header>
                    <h1>{"Konfiguration"}</h1>
                </header>
                <div className="tiles-body">
                    <Tile
                        title="Aufstockung"
                        iconName="ri-home-gear-fill"
                        onClick={() => navigate('/configuration/replenishment')}></Tile>
                    <Tile
                        title="Inventar-Aufgaben"
                        iconName="ri-home-gear-fill"
                        onClick={() => navigate('/configuration/inventory-tasks')}></Tile>
                    <Tile
                        title="Notfall-Picking"
                        iconName="ri-home-gear-fill"
                        onClick={() => navigate('/configuration/emergency-picking')}></Tile>
                </div>
            </div>
        </div>
    )
}
