import './LocationPage.scss';
import { Location, LocationArea, LocationHallway, LocationKind, LocationNumber, LocationStatus, LocationType } from '@kehrwasser/aldi-sued-dtm-openapi';
import { useEffect, useState } from 'react';
import Table from '../../../elementsAldi/table/Table';
import TableHead from '../../../elementsAldi/table/tableHead/TableHead';
import TableBody from '../../../elementsAldi/table/tableBody/TableBody';
import TableRow from '../../../elementsAldi/table/tableRow/TableRow';
import TableCell from '../../../elementsAldi/table/tableCell/TableCell';
import TableFoot from '../../../elementsAldi/table/tableFoot/TableFoot';
import { LocationFilter, locationsProvider } from '../../../../shared/locations.service';
import { configPaginationProvider } from '../../../../shared/configPagination.service';
import React from 'react';
import ALDIBadge from '../../../elementsAldi/badge/badge';
import LocationsModal from './modal/LocationsModal';
import AldiMultiSelect from '../../../elementsAldi/multiselect/AldiMultiSelect';
import AldiMenuItem from '../../../elementsAldi/menuitem/AldiMenuItem';
import Pagination, { PaginationProps } from '../../../elementsAldi/pagination/Pagination';
import useDebounce from '../../../../hooks/useDebounce';
import AldiFilterMenu from '../../../elementsAldi/filtermenu/AldiFilterMenu';
import { AldiChipInput } from '../../../elementsAldi/chipinput/AldiChipInput';
import AldiButton from '../../../elementsAldi/button/AldiButton';
import { useModal } from '../../../../hooks/useModal';
import usePromise, { PromiseStatus } from '../../../../hooks/usePromise';
import AldiReloadButton from '../../../elementsAldi/reloadbutton/AldiReloadButton';

export default function LocationPage() {

    /* State Definitions */
    const [locations, setLocations] = useState<any[]>([]);
    const promiseLocations = usePromise();
    const [count, setCount] = useState(configPaginationProvider.count);
    const [page, setPage] = useState(configPaginationProvider.page);
    const [rowsPerPage, setRowsPerPage] = useState(configPaginationProvider.rowsPerPage);
    const [searchQuery, setSearchQuery] = useState('');
    const [statusFilter, setStatusFilter] = useState<LocationStatus[]>([]);
    const [typeFilter, setTypeFilter] = useState<LocationType[]>([]);
    const [areaFilter, setAreaFilter] = useState<LocationArea[]>([]);
    const [locationKindFilter, setLocationKindFilter] = useState<LocationKind[]>([]);
    const [hallwayAndNumberFilter, setHallwayAndNumberFilter] = useState<[string, string][]>([]);
    const [locationToEdit, setLocationToEdit] = useState<Location | null>(null);
    const locationsModal = useModal();

    const filter = {
        offset: page * rowsPerPage,
        limit: rowsPerPage,
        q: searchQuery,
        status: statusFilter,
        type: typeFilter,
        area: areaFilter,
        locationKind: locationKindFilter,
        hallwayAndNumber: hallwayAndNumberFilter,
    } as LocationFilter;

    const debouncedFilter = useDebounce(filter, 600, Object.values(filter));


    /* Communication */
    useEffect(() => {
        loadLocations();
    }, Object.values(debouncedFilter));

    useEffect(() => {
        if (locationToEdit) {
            locationsModal.setModal(
                <LocationsModal
                    modal={locationsModal}
                    location={locationToEdit}
                    locations={locations}
                    onChange={setLocations}
                    onClose={() => setLocationToEdit(null)}
                />)
        } else {
            locationsModal.setModal(null)
        }
    }, [locations, locationToEdit, locationAreaLabel, locationTypeLabel])

    /* Helpers */

    function loadLocations() {
        promiseLocations.setPromise(locationsProvider.get(filter).then(response => {
            setLocations(response.data);
            setCount(parseInt(response.headers['x-total-count']));
        }).catch(error => {
            console.debug(error);
            return Promise.reject(error);
        }));
    }

    /* Handlers */
    const handleClickEditLocation = (location: Location) => {
        setLocationToEdit(location);
        locationsModal.show();
    };

    return (
        <div className='location-overview'>
            <FilterBar
                statusFilter={statusFilter}
                onStatusFilterChange={setStatusFilter}
                typeFilter={typeFilter}
                onTypeFilterChange={setTypeFilter}
                areaFilter={areaFilter}
                onAreaFilterChange={setAreaFilter}
                locationKindFilter={locationKindFilter}
                onLocationKindFilterChange={setLocationKindFilter}
                hallwayAndNumberFilter={hallwayAndNumberFilter}
                onHallwayAndNumberFilterChange={setHallwayAndNumberFilter}
                searchQuery={searchQuery}
                onSearchQueryChange={setSearchQuery} />
            <LocationTable
                locations={locations}
                loading={promiseLocations.status}
                onReloadClicked={loadLocations}
                onClickEditLocation={handleClickEditLocation}
                count={count}
                page={page}
                rowsPerPage={rowsPerPage}
                onPageChange={setPage}
                onRowsPerPageChange={setRowsPerPage} />
        </div>
    )
}


type FilterBarProps = {
    statusFilter: LocationStatus[],
    onStatusFilterChange: (statusFilter: LocationStatus[]) => void,
    typeFilter: LocationType[],
    onTypeFilterChange: (typeFilter: LocationType[]) => void,
    areaFilter: LocationArea[],
    onAreaFilterChange: (areaFilter: LocationArea[]) => void,
    locationKindFilter: LocationKind[],
    onLocationKindFilterChange: (locationKind: LocationKind[]) => void,
    hallwayAndNumberFilter: [string, string][],
    onHallwayAndNumberFilterChange: (hallwayAndNumber: [string, string][]) => void,
    searchQuery: string,
    onSearchQueryChange: (searchQuery: string) => void,
}

function FilterBar(props: FilterBarProps) {

    const {
        statusFilter,
        onStatusFilterChange,
        typeFilter,
        onTypeFilterChange,
        areaFilter,
        onAreaFilterChange,
        locationKindFilter,
        onLocationKindFilterChange,
        hallwayAndNumberFilter,
        onHallwayAndNumberFilterChange,
        searchQuery,
        onSearchQueryChange,
    } = props;

    const hallwaysAndNumbersTemp: Array<string>[] = []

    Object.values(LocationHallway).map(hallway => {
        Object.values(LocationNumber).map(number => {
            hallwaysAndNumbersTemp.push([hallway, number])
        })
    })

    const [hallwaysAndNumbers, setHallwaysAndNumbers] = React.useState(hallwaysAndNumbersTemp);

    /* Helper */

    const isDefaultValues = () =>
        statusFilter.length === 0
            && typeFilter.length === 0
            && areaFilter.length === 0
            && locationKindFilter.length === 0
            && hallwayAndNumberFilter.length === 0
            && searchQuery === "";


    /* Handler */

    const handleResetAll = () => {
        onStatusFilterChange([]);
        onTypeFilterChange([]);
        onAreaFilterChange([]);
        onLocationKindFilterChange([]);
        onHallwayAndNumberFilterChange([]);
        onSearchQueryChange("");
    }


    return (
        <div className="filters">
            <div className="header-title">
                Lagerplätze
            </div>
            <div className="placeholder"></div>

            <AldiFilterMenu selected={!isDefaultValues()} classNamePopover="filter-menu">
                <div className="aldi-box filter">
                    <AldiMultiSelect
                        resettable
                        value={statusFilter}
                        onChange={onStatusFilterChange}
                        onReset={() => onStatusFilterChange([])}
                        label="Status"
                        data-testid='status-filter'
                    >
                        {(Object.values(LocationStatus) as Array<LocationStatus>).map(status => (
                            <AldiMenuItem key={status} value={status}>{locationStatusLabel(status)}</AldiMenuItem>
                        ))}
                    </AldiMultiSelect>
                </div>
                <div className="aldi-box filter">
                    <AldiMultiSelect
                        resettable
                        value={typeFilter}
                        onChange={onTypeFilterChange}
                        onReset={() => onTypeFilterChange([])}
                        label="Typ"
                        data-testid='type-filter'
                    >
                        {(Object.values(LocationType) as Array<LocationType>).map(value => (
                            <AldiMenuItem key={value} value={value} data-teestid={value}>{locationTypeLabel(value)}</AldiMenuItem>
                        ))}
                    </AldiMultiSelect>
                </div>
                <div className="aldi-box filter">
                    <AldiMultiSelect
                        resettable
                        value={areaFilter}
                        onChange={onAreaFilterChange}
                        onReset={() => onAreaFilterChange([])}
                        label="Bereich"
                        data-testid='area-filter'
                    >
                        {(Object.values(LocationArea) as Array<LocationArea>).map(value => (
                            <AldiMenuItem key={value} value={value} data-teestid={value}>{locationAreaLabel(value)}</AldiMenuItem>
                        ))}
                    </AldiMultiSelect>
                </div>
                <div className="aldi-box filter">
                    <AldiMultiSelect
                        resettable
                        value={locationKindFilter}
                        onChange={onLocationKindFilterChange}
                        onReset={() => onLocationKindFilterChange([])}
                        label="Lagerplatz-Art"
                        data-testid='kind-filter'
                    >
                        {(Object.values(LocationKind) as Array<LocationKind>).map(value => (
                            <AldiMenuItem key={value} value={value} data-teestid={value}>{locationKindLabel(value)}</AldiMenuItem>
                        ))}
                    </AldiMultiSelect>
                </div>
                <div className="aldi-box filter">
                    <AldiMultiSelect
                        resettable
                        value={hallwayAndNumberFilter}
                        onChange={onHallwayAndNumberFilterChange}
                        onReset={() => onHallwayAndNumberFilterChange([])}
                        label="Gang/Nummer"
                        data-testid='hallway-number-filter'
                    >
                        {hallwaysAndNumbers.map(value => {
                            const [hallway, number] = value
                            return <AldiMenuItem key={value} data-testid={`hallway-number-filter-${hallway}-${number}`} value={value}>
                                {`${hallway} ${number}`}
                            </AldiMenuItem>
                        })}
                    </AldiMultiSelect>
                </div>
                <div className="aldi-box filter">
                    <AldiButton kind="tertiary" disabled={isDefaultValues()} onClick={handleResetAll}>
                        {"Filter zurücksetzen"}
                    </AldiButton>
                </div>
            </AldiFilterMenu>

            <div className="small-fixed-gap" />

            <div className="aldi-box filter">
                <AldiChipInput
                    placeholder="Name oder SKU"
                    onChange={values => onSearchQueryChange(values.join(" "))}
                    value={searchQuery.split(" ").filter(term => term !== "")}
                    resettable />
            </div>
        </div>
    )
}


type LocationTableProps = {
    locations: Location[],
    loading: PromiseStatus,
    onReloadClicked: () => void,
    onClickEditLocation: (location: Location) => void,
} & PaginationProps;

function LocationTable(props: LocationTableProps) {
    const { locations, loading, onReloadClicked, onClickEditLocation, ...paginationProps } = props;

    /* Helpers */

    function statusBadgeColor(status: LocationStatus): string {
        switch (status) {
            case LocationStatus.Occupied: return "blue";
            case LocationStatus.Free:  return "success";
            case LocationStatus.NotAvailable: return "grey";
        }
    }

    /* Renders */
    const renderSubtextOrButton = (location: any) => {
        if (location.type === "pick") {
            return <p className="table-cell-subtext">{location.articles[0]?.group || "N/A"}</p>
        } else {
            return <p className="table-cell-subtext-button">
                {"Alle Anzeigen"}
            </p>
        }
    }

    const renderRow = (location: any) => {
        return (
            <TableRow key={location.id} onClick={(event: React.MouseEvent<HTMLElement>) => onClickEditLocation(location)} className="table-row-clickable">
                <TableCell><ALDIBadge type={location.active ? "success" : "warning"}>{location.active ? "aktiv" : "inaktiv"}</ALDIBadge></TableCell>
                <TableCell><ALDIBadge type={statusBadgeColor(location.status)}>{locationStatusLabel(location.status)}</ALDIBadge></TableCell>
                <TableCell><ALDIBadge type={location.type === "pick" ? "orange" : "purple"}>{locationTypeLabel(location.type)}</ALDIBadge></TableCell>
                <TableCell>
                    <p className="table-cell-main-text-with-subtext">{locationAreaLabel(location.area)}</p>
                    <p className="table-cell-subtext">Trockensortiment</p>
                </TableCell>
                <TableCell><p className="table-cell-main-text">{location.articles[0]?.sku || "N/A"}</p></TableCell>
                <TableCell>
                    <p className="table-cell-main-text-with-subtext">{location.articles[0]?.name || "N/A"}</p>
                    {renderSubtextOrButton(location)}
                </TableCell>
                <TableCell><p className="table-cell-main-text">{locationKindLabel(location.locationKind)}</p></TableCell>
                <TableCell><p className="table-cell-main-text">{location.hallway}</p></TableCell>
                <TableCell><p className="table-cell-main-text">{location.number}</p></TableCell>
                <TableCell><p className="table-cell-main-text">0{location.level}</p></TableCell>
                <TableCell><p className="table-cell-main-text">{location.place}</p></TableCell>
                <TableCell><p className="table-cell-main-text">{location.capacity}</p></TableCell>
            </TableRow>
        );
    }


    return (
        <div className="aldi-box aldi-general-table-wrapper">
            <Table>
                <TableHead loading={loading === PromiseStatus.Pending}>
                    <TableRow className="single-row-thead">
                        <TableCell>Aktiv</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell>Typ</TableCell>
                        <TableCell>Bereich</TableCell>
                        <TableCell>SKU</TableCell>
                        <TableCell>Name</TableCell>
                        <TableCell>Lagerplatz-Art</TableCell>
                        <TableCell>Gang</TableCell>
                        <TableCell>Nummer</TableCell>
                        <TableCell>Ebene</TableCell>
                        <TableCell>Platz</TableCell>
                        <TableCell>Kapazität</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {loading === PromiseStatus.Failed && <TableRow>
                        <TableCell colSpan={12}>
                            <AldiReloadButton onClick={onReloadClicked}/>
                        </TableCell>
                    </TableRow>}
                    {locations.map((location) => renderRow(location))}
                </TableBody>
                <TableFoot>
                    <TableRow>
                        <Pagination {...paginationProps} />
                    </TableRow>
                </TableFoot>
            </Table>
        </div>
    )

}


/* Helpers */
export function locationStatusLabel(status: LocationStatus): string {
    switch (status) {
        case LocationStatus.Occupied:
            return 'Belegt';
        case LocationStatus.Free:
            return 'Frei';
        case LocationStatus.NotAvailable:
            return 'N/A';
    }
};

export function locationTypeLabel(type: LocationType): string {
    switch (type) {
        case LocationType.Pick:
            return 'Pick';
        case LocationType.Buffer:
            return 'Puffer';
    }
};

export function locationAreaLabel(area: LocationArea): string {
    switch (area) {
        case LocationArea.Ambient:
            return 'Ambient';
        case LocationArea.Chilled:
            return 'Chilled';
        case LocationArea.Frozen:
            return 'Frozen';
    }
};

export function locationKindLabel(locationKind: LocationKind): string {
    switch (locationKind) {
        case LocationKind.Palette:
            return 'Palette';
        case LocationKind.OgTable:
            return 'O&G-Tisch';
        case LocationKind.ShelfSmall:
            return 'Regal Klein';
    }
};
