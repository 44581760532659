import { ConfigParameter } from "@kehrwasser/aldi-sued-dtm-openapi";
import { AxiosResponse } from "axios";
import { apiService } from "./apiService";

const configParametersProvider = {

    api: apiService.config,

    get(configId: string): Promise<AxiosResponse<ConfigParameter[], any>> {

        return this.api.getConfigParameters(configId);

    },

    update(configId: string, configParameters: ConfigParameter[]): Promise<AxiosResponse<ConfigParameter[], any>> {

        return this.api.patchConfigParameters(configId, configParameters);

    }

};

export { configParametersProvider };
