import './LocationsModal.scss';
import { useState, useEffect, ReactElement } from 'react';
import { ArticlesForLocation, Location } from '@kehrwasser/aldi-sued-dtm-openapi';
import TextField from '@mui/material/TextField';
import Validator from '../../../../../shared/validation';
import ALDIBadge from '../../../../elementsAldi/badge/badge';
import Toggle from '../../../../elementsAldi/toggle/toggle';
import { locationsProvider } from '../../../../../shared/locations.service';
import { Autocomplete } from '@mui/material';
import AldiModal from '../../../../elementsAldi/modal/Modal';
import AldiButton from '../../../../elementsAldi/button/AldiButton';
import usePromise from '../../../../../hooks/usePromise';
import { locationAreaLabel, locationTypeLabel } from '../LocationPage';


export default function LocationsModal(props: {
    modal: { hide: () => void },
    locations: Location[],
    onChange?: (locations: Location[]) => void,
    onClose?: () => void,
    location: Location,
}) {

    const { modal, locations, onChange, onClose, location } = props

    const validator = new Validator()

    /* State Definitions */
    const [values, setValues] = useState<Location>(location)
    const [articles, setArticles] = useState<ArticlesForLocation[]>([]);
    const [article, setArticle] = useState<ArticlesForLocation | null>(null);
    const [searchQuery, setSearchQuery] = useState<string>("")
    const [invalidForm, setInvalidForm] = useState(false)
    const [formErrors, setFormErrors] = useState({} as any)
    const promiseArticles = usePromise();
    const promiseLocations = usePromise();

    const disabled = promiseLocations.isLoading;


    /* Helper */

    const close = () => {
        setInvalidForm(false);
        setFormErrors({});
        modal.hide();
        onClose && onClose();
    }

    /* Communication */
    useEffect(() => {
        if (location) {
            setValues(location);
        } else {
            setSearchQuery("");
            setArticle(null);
            setArticles([]);
        }
    }, [location]);

    useEffect(() => {
        if (searchQuery.length > 0) {
            promiseArticles.setPromise(locationsProvider.getArticles(location.id, searchQuery).then(response => {
                setArticles(response.data);
            }))
        }
    }, [searchQuery]);


    /* Handlers */
    const handleChangeSearchQuery = (_: any, value: string) => {
        setSearchQuery(value)
    };

    const handleClickAddArticle = () => {
        if (article) {
            const newValues = { ...values, articles: [...values.articles, article] };
            setValues(newValues);
            setArticle(null);
        } else {
            window.alert(`Es ist kein Artikel ausgewählt.`);
        }
    };

    const handleClickRemoveArticle = (articleId: string) => {
        const newArticles = values.articles.filter(article => article.id !== articleId);
        const newValues = { ...values, articles: newArticles };
        setValues(newValues);
    };

    const handleSubmit = () => {
        promiseLocations.setPromise(locationsProvider.update(values.id, values).then(response => {
            let newLocations = locations;
            const indexOfLocation = locations.findIndex((location: Location) => location.id === values.id)
            newLocations.splice(indexOfLocation, 1, response.data);
            onChange && onChange(newLocations);
            close()
        }).catch(error => {
            console.error(error);
            alert('The location could not be updated.');
        }))
    };

    const handleChangeToggle = (active: any) => {
        setValues({ ...values, active: !active })
    };

    /* Renders */
    return (
        <div className="location-modal">
            <AldiModal
                textCancelButton="Abbrechen"
                onCancel={close}
                width="fit-content"
                loading={disabled}
                disabled={disabled}
                title="Lagerplätze bearbeiten"
                textSubmitButton="Speichern"
                subtitle="Gib alle notwendigen Informationen an."
                onSubmit={handleSubmit}>

                <p className="heading">Location</p>
                <div className="location-data-row">
                    <div className="location-data-wrapper area-wrapper">
                        <div className="badge-wrapper">
                            <ALDIBadge type={location.type === "pick" ? "orange" : "purple"}>{locationTypeLabel(location.type)}</ALDIBadge>
                        </div>
                    </div>
                    <LocationData value={location.hallway} label="Gang" />
                    <LocationData value={location.number} label="Nummer" />
                    <LocationData value={location.level} label="Ebene" />
                    <LocationData value={location.place} label="Platz" />
                    <LocationData value={location.capacity} label="Kapazität" />
                </div>
                <p className="heading">Bereich</p>
                <div className="location-data-row">
                    <LocationData value={locationAreaLabel(location.area)} label="Temperaturzone" />
                    <LocationData value="Trockensortiment" label="Warenbereich" />
                </div>
                <p className="heading">Artikelname oder SKU</p>
                <div className="location-article-segment">
                    <div className="search-row">
                        <div className="search-input-wrapper">
                            <Autocomplete
                                // options={articles.map(article => `${article.name}, ${article.group}, ${article.sku}`)}
                                options={articles}
                                getOptionLabel={option => `${option.name}, ${option.group}, ${option.sku}`}
                                onInputChange={handleChangeSearchQuery}
                                value={article}
                                onChange={(_, newValue: ArticlesForLocation | null) => {
                                    setArticle(newValue);
                                }}
                                filterOptions={(x) => x}
                                sx={{ width: 300 }}
                                renderInput={(params) => <TextField {...params} size="small" label="Suchbegriff" />}
                                disabled={disabled}
                                noOptionsText="Keine Optionen"
                            />
                        </div>
                        <div className="button-wrapper">
                            <AldiButton kind="tertiary" className={promiseArticles.isLoading ? "loadingIndicator" : ""} onClick={handleClickAddArticle} loading={promiseArticles.isLoading} disabled={values.type === "pick" && values.articles.length > 0 || disabled} >Auswählen</AldiButton>
                        </div>
                    </div>
                    <div className="article-list">

                        {values.articles?.map(article => {
                            return (
                                <div key={article.id} className="article-wrapper">
                                    <p className="article-name">{`${article.name}, ${article.group}, ${article.sku}`}</p>
                                    <AldiButton onClick={() => handleClickRemoveArticle(article.id)} className="article-remove-button" disabled={disabled}><span className='ri-close-line remove-icon' /></AldiButton>
                                </div>
                            )
                        })}

                    </div>
                </div>
                <p className="heading">Status</p>
                <div className="location-data-row">
                    <LocationData className="status-wrapper"
                        value={values.active ? "Aktiv" : "Inaktiv"}
                        label={values.active ? "Lagerplatz kann verwendet werden" : "Lagerplatz kann nicht verwendet werden"}>
                        <Toggle disabled={disabled} checked={values.active} onChange={() => handleChangeToggle(values.active)} ></Toggle>
                    </LocationData>
                </div>
            </AldiModal>
        </div>
    )
}

function LocationData({ className, value, label, children }: { className?: string, value: string, label: string, children?: ReactElement }) {
    const classes = className
        ? ["location-data-wrapper", "area-wrapper", className]
        : ["location-data-wrapper", "area-wrapper"];

    return (
        <div className={classes.join(" ")}>
            <p className="value">{value}</p>
            <span className="label">{label}</span>
            {children}
        </div>
    );
}
